import { Stack, Typography } from "@mui/material";
import { AnalyticsContainer, DirectionGroup, Divider, MapControlContainer } from "components_new";
import React, { FC, Fragment, useState } from "react";

import { BarChart } from "components";

import { BarChartEmptyState } from "components/molecules/bar-chart/BarChartEmptyState";

import { Dimension, RoadVmtZoneDetails } from "types";

import { getLabelByCategories, getLabelByDimension } from "./ODAnalytics";

interface RoadVmtAnalyticsProps {
  zoneDetails: RoadVmtZoneDetails[];
  roadVmtDimensions: Dimension[] | undefined;
}

const RoadVmtAnalytics: FC<RoadVmtAnalyticsProps> = ({ zoneDetails, roadVmtDimensions }) => {
  const [expandedDimensions, setExpandedDimensions] = useState<string[]>(
    zoneDetails.flatMap((detail) =>
      detail.summary.breakdowns.map((breakdown) => `${detail.measure}_${breakdown.dimensions[0]}`),
    ),
  );

  const handleChangeExpandedDimensions = (dimensionId: string) => {
    setExpandedDimensions((prev) =>
      prev.includes(dimensionId) ? prev.filter((id) => id !== dimensionId) : [...prev, dimensionId],
    );
  };

  return (
    <Stack sx={{ height: "calc(100% - 62px)" }} divider={<Divider />}>
      {zoneDetails.map((detail) => (
        <Fragment key={`${detail.measure}_${detail.id}`}>
          <DirectionGroup>
            <Typography variant="subtitle2">{detail?.name || "Attributes"}</Typography>
          </DirectionGroup>
          <AnalyticsContainer heightOperand={40}>
            {detail?.summary?.breakdowns.map((breakdown, index) => {
              const dimension = breakdown.dimensions[0];
              const data = breakdown.rows.map((row) => ({
                name: getLabelByCategories(row.categories[0], dimension, roadVmtDimensions || []),
                value: row.value,
              }));

              const title = getLabelByDimension(dimension, roadVmtDimensions || []);

              return (
                <MapControlContainer
                  key={dimension}
                  title={title}
                  collapse
                  expanded={expandedDimensions.includes(`${detail.measure}_${dimension}`)}
                  onChange={() => handleChangeExpandedDimensions(`${detail.measure}_${dimension}`)}
                >
                  {data.length ? (
                    <BarChart key={index} data={data} layout="vertical" />
                  ) : (
                    <BarChartEmptyState title={title} message="No data available" />
                  )}
                </MapControlContainer>
              );
            })}
          </AnalyticsContainer>
        </Fragment>
      ))}
    </Stack>
  );
};

export { RoadVmtAnalytics };
