import { isEqual } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";

import { RoadClassSelector, SelectorRoadClasses } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { filtersActions } from "store/sections/filters";

export interface RoadClassFilterProps {
  loading: boolean;
  filterRoadSegmentsByRoadClasses: (roadClasses: number[]) => void;
}

export const RoadClassFilter: FC<RoadClassFilterProps> = ({ loading, filterRoadSegmentsByRoadClasses }) => {
  const dispatch = useAppDispatch();

  const [roadClasses, setRoadClasses] = useState<SelectorRoadClasses | null>(null);

  const savedRoadClasses = useAppSelector((state) => state.filters.roadClasses);

  const activeRoadClasses = useMemo(
    () =>
      Object.values(roadClasses?.items || {})
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => Number(value)),
    [roadClasses],
  );

  const previousActiveRoadClasses = usePrevious(activeRoadClasses);

  useEffect(() => {
    if (
      previousActiveRoadClasses &&
      !isEqual(previousActiveRoadClasses, activeRoadClasses) &&
      !isEqual(activeRoadClasses, savedRoadClasses)
    ) {
      dispatch(filtersActions.setRoadClasses(activeRoadClasses));
      filterRoadSegmentsByRoadClasses?.(activeRoadClasses);
    }
  }, [activeRoadClasses, previousActiveRoadClasses, filterRoadSegmentsByRoadClasses, savedRoadClasses, dispatch]);
  return (
    <RoadClassSelector
      loading={loading}
      roadClasses={roadClasses}
      savedRoadClasses={savedRoadClasses}
      setRoadClasses={setRoadClasses}
    />
  );
};
