import { useAuth0 } from "@auth0/auth0-react";
import { Box, MenuItem, Stack, styled } from "@mui/material";
import {
  Divider,
  FiltersAndLayersContainer,
  MapControlContainer,
  SliderControl,
  TextField,
  VisibilityIconButton,
} from "components_new";
import { Map } from "mapbox-gl";
import React, { ChangeEvent, FC, MutableRefObject, memo, useCallback, useEffect, useMemo, useState } from "react";

import { AreaName, FocusAreaDropdown, LeftSidebar } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { corridorActions } from "store/sections/corridor";
import { globalActions } from "store/sections/global";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { useChangeEdgesOpacity, useChangeHeatmapOpacity } from "./ControllerCallbacks";
import { Filters } from "./Filters";
import { RangeFilter } from "./RangeFilter";
import {
  CORRIDOR_EDGE_LAYER_ID,
  CORRIDOR_NODE_LAYER_ID,
  getHeatmapIntensityExpression,
  getHeatmapRadiusExpression,
  layerIdsEndings,
} from "./map-data/corridor/layers";

type MapLayerId = "heatmap" | "edges";

interface MapControlsPanelProps {
  mapController: MutableRefObject<null | any>;
  map: Map | null;
}

const AreaNameContainer = styled("div")`
  margin: 1rem 0;
  display: flex;
`;

function valueLabelFormat(value: number) {
  return `x ${Math.round(value * 100) / 100}`;
}

function calculateHeatmapIntensityFactor(value: number) {
  return value ** 2;
}

function calculateHeatmapRadiusFactor(value: number) {
  return value ** 2;
}

function calculateEdgeWidthFactor(value: number) {
  return value ** 3;
}

export const MapControlsPanel: FC<MapControlsPanelProps> = memo(({ mapController, map }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  const [expandedMapLayers, setExpandedMapLayers] = useState<MapLayerId[]>(["edges", "heatmap"]);
  const [heatmapVisible, setHeatmapVisible] = useState(true);
  const [corridorEdgesVisible, setCorridorEdgesVisible] = useState(true);
  const [corridorEdgeWidth, setCorridorEdgeWidth] = useState(1);
  const [heatmapIntensity, setHeatmapIntensity] = useState(1);
  const [heatmapRadius, setHeatmapRadius] = useState(1);
  const [heatmapOpacityFactor, setHeatmapOpacityFactor] = useState(1);
  const [edgesOpacityFactor, setEdgesOpacityFactor] = useState(1);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const permissions = useAppSelector((state) => state.license.permissions);

  const corridorMetadata = useAppSelector((state) => state.corridor.corridorMetadata);
  const corridorEdgeIds = useAppSelector((state) => state.corridor.corridorEdgeIds);
  const corridorEdgeCounts = useAppSelector((state) => state.corridor.corridorEdgeCounts);
  const corridorNodeIds = useAppSelector((state) => state.corridor.corridorNodeIds);
  const corridorNodeCounts = useAppSelector((state) => state.corridor.corridorNodeCounts);
  const corridorHeatmapConfiguration = useAppSelector((state) => state.corridor.corridorHeatmapConfiguration);
  const serviceOverlayLayers = useAppSelector((state) => state.corridor.serviceLayers);

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const changeEdgesOpacity = useChangeEdgesOpacity(map, mapController.current?.layerManager);
  const changeHeatmapOpacity = useChangeHeatmapOpacity(map, mapController.current?.layerManager);

  const filterLoading = useMemo(() => {
    return (
      corridorMetadata.state === DataState.LOADING ||
      corridorEdgeIds.state === DataState.LOADING ||
      corridorEdgeCounts.state === DataState.LOADING ||
      corridorNodeIds.state === DataState.LOADING ||
      corridorNodeCounts.state === DataState.LOADING ||
      corridorHeatmapConfiguration.state === DataState.LOADING ||
      serviceOverlayLayers.state === DataState.LOADING
    );
  }, [
    corridorMetadata.state,
    corridorEdgeIds.state,
    corridorEdgeCounts.state,
    corridorNodeIds.state,
    corridorNodeCounts.state,
    corridorHeatmapConfiguration.state,
    serviceOverlayLayers.state,
  ]);

  const selectedFocusArea = useMemo(
    () => focusAreas.data?.find((area) => area.id === selectedFocusAreaId) || null,
    [focusAreas.data, selectedFocusAreaId],
  );

  const isDataset = useMemo(() => Boolean(selectedFocusArea?.datasetId), [selectedFocusArea?.datasetId]);

  const handleToggleCorridorEdges = () => {
    if (mapController.current) {
      const areCorridorEdgesVisible = !corridorEdgesVisible;
      setCorridorEdgesVisible(areCorridorEdgesVisible);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        layerIdsEndings.forEach((ending) => {
          mapController.current.layerManager.updateLayerLayout(
            `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}${ending}`,
            "visibility",
            areCorridorEdgesVisible ? "visible" : "none",
          );
        });
      });

      addCustomGAEvent(
        "corridor",
        "edges",
        areCorridorEdgesVisible ? "visible" : "invisible",
        user,
        userOrganizationName,
      );
    }
  };

  const handleChangeCorridorEdgeWidth = useCallback(
    (_: any, value: number | number[]) => {
      setCorridorEdgeWidth(value as number);

      const widthFactor = calculateEdgeWidthFactor(value as number);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
          "line-width",
          ["*", ["number", ["feature-state", "volumeWeight"], 0], widthFactor],
        );

        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
          "line-offset",
          ["*", ["number", ["feature-state", "volumeOffset"], 0], widthFactor],
        );

        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_volume`,
          "line-width",
          ["*", ["number", ["feature-state", "volumeWeight"], 0], widthFactor],
        );

        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_volume`,
          "line-offset",
          ["*", ["number", ["feature-state", "volumeOffset"], 0], widthFactor],
        );
      });

      addCustomGAEvent("corridor", "edges", "change_width", user, userOrganizationName);
    },
    [user, userOrganizationName, mapController, corridorMetadata.data],
  );

  const handleToggleHeatMap = () => {
    if (mapController.current) {
      const isHeatmapVisible = !heatmapVisible;
      setHeatmapVisible(isHeatmapVisible);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerLayout(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "visibility",
          isHeatmapVisible ? "visible" : "none",
        );
      });

      addCustomGAEvent("corridor", "heatmap", isHeatmapVisible ? "visible" : "invisible", user, userOrganizationName);
    }
  };

  const handleChangeHeatmapIntensity = useCallback(
    (_: any, value: number | number[]) => {
      setHeatmapIntensity(value as number);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "heatmap-intensity",
          getHeatmapIntensityExpression(
            calculateHeatmapIntensityFactor(value as number),
            corridorHeatmapConfiguration.data,
          ),
        );
      });

      addCustomGAEvent("corridor", "heatmap", "change_intensity", user, userOrganizationName);
    },
    [mapController, corridorMetadata.data, corridorHeatmapConfiguration.data, user, userOrganizationName],
  );

  const handleChangeHeatmapRadius = useCallback(
    (_: any, value: number | number[]) => {
      setHeatmapRadius(value as number);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "heatmap-radius",
          getHeatmapRadiusExpression(calculateHeatmapRadiusFactor(value as number), corridorHeatmapConfiguration.data),
        );
      });

      addCustomGAEvent("corridor", "heatmap", "change_radius", user, userOrganizationName);
    },
    [mapController, corridorMetadata.data, corridorHeatmapConfiguration.data, user, userOrganizationName],
  );

  const handleChangeFocusArea = (focusAreaId: string) => {
    if (focusAreaId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId }));
      dispatch(corridorActions.clearServiceOverlayLayers());

      addCustomGAEvent("corridor", "aoi", "change", user, userOrganizationName);
    }
  };

  const handleChangeTimePeriod = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(globalActions.setTimePeriod(event.target.value));

    addCustomGAEvent("corridor", "time_period", "change", user, userOrganizationName);
  };

  useEffect(() => {
    setCorridorEdgeWidth(1);
    setHeatmapIntensity(1);
    setHeatmapRadius(1);
    setCorridorEdgesVisible(true);
    setHeatmapVisible(true);

    return () => {
      dispatch(corridorActions.clearServiceOverlayLayers());
    };
  }, [selectedFocusAreaId, dispatch]);

  useEffect(() => {
    if (corridorHeatmapConfiguration.state === DataState.AVAILABLE && mapController.current) {
      handleChangeCorridorEdgeWidth(undefined, corridorEdgeWidth);
      handleChangeHeatmapIntensity(undefined, heatmapIntensity);
      handleChangeHeatmapRadius(undefined, heatmapRadius);
    }
  }, [
    corridorHeatmapConfiguration,
    mapController,
    corridorEdgeWidth,
    heatmapIntensity,
    heatmapRadius,
    handleChangeCorridorEdgeWidth,
    handleChangeHeatmapIntensity,
    handleChangeHeatmapRadius,
  ]);

  useEffect(() => {
    return () => {
      dispatch(corridorActions.clearCorridorMetadata());
      dispatch(corridorActions.updateCurrentFilters(null));
    };
  }, [dispatch]);

  const handleChangeExpandedMapLayers = (layerId: MapLayerId) => {
    setExpandedMapLayers((prev) => (prev.includes(layerId) ? prev.filter((id) => id !== layerId) : [...prev, layerId]));
  };

  const handleChangeEdgesOpacity = (value: number) => {
    setEdgesOpacityFactor(value);
    changeEdgesOpacity(value, Object.keys(corridorMetadata.data?.corridorLevels || {}));
  };

  const handleChangeHeatmapOpacity = (value: number) => {
    setHeatmapOpacityFactor(value);
    changeHeatmapOpacity(value, Object.keys(corridorMetadata.data?.corridorLevels || {}));
  };

  return (
    <LeftSidebar>
      <Box
        sx={{
          height: "100%",
          display: "grid",
          rowGap: 2,
          gridTemplateRows: "auto minmax(100px, 1fr)",
        }}
      >
        <Stack spacing={2}>
          <div>
            <FocusAreaDropdown
              loading={focusAreas.state === DataState.LOADING}
              disabled={focusAreas.state === DataState.EMPTY || focusAreas.state === DataState.ERROR}
              options={
                focusAreas.data?.filter(
                  (area) =>
                    !area.datasetId &&
                    permissions.data?.licensedAreas.find(
                      (a) => a.licensedAreaId.toString() === area.licensedAreaId && a.dataDetail.corridorDetail,
                    ),
                ) || []
              }
              value={selectedFocusArea}
              onChange={handleChangeFocusArea}
            />
            {isDataset && (
              <AreaNameContainer>
                <AreaName>{selectedFocusArea?.region}</AreaName>
              </AreaNameContainer>
            )}
          </div>
          <TextField
            fullWidth
            select
            value={timePeriod || ""}
            label="Time Period"
            disabled={(selectedFocusArea?.timePeriods?.length ?? 1) <= 1}
            onChange={handleChangeTimePeriod}
          >
            {selectedFocusArea?.timePeriods?.map((timePeriod) => (
              <MenuItem key={timePeriod} value={timePeriod}>
                {timePeriod}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <FiltersAndLayersContainer>
          <Filters loading={filterLoading} />

          <MapControlContainer
            title="Corridor edges"
            primaryAction={<VisibilityIconButton visible={corridorEdgesVisible} onClick={handleToggleCorridorEdges} />}
            collapse
            expanded={expandedMapLayers.includes("edges")}
            onChange={() => handleChangeExpandedMapLayers("edges")}
          >
            <Box padding={1}>
              <RangeFilter label="Filter" mapController={mapController} map={map} />
              <Divider sx={{ marginY: 0.5 }} />
              <SliderControl
                label="Width"
                loading={filterLoading}
                value={corridorEdgeWidth}
                min={0}
                max={2}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                scale={calculateEdgeWidthFactor}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={handleChangeCorridorEdgeWidth}
              />
              <SliderControl
                label="Opacity"
                value={edgesOpacityFactor}
                min={0}
                max={1}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                onChange={(e, value) => handleChangeEdgesOpacity(value as number)}
              />
            </Box>
          </MapControlContainer>
          <MapControlContainer
            title="Heatmap"
            primaryAction={<VisibilityIconButton visible={heatmapVisible} onClick={handleToggleHeatMap} />}
            collapse
            expanded={expandedMapLayers.includes("heatmap")}
            onChange={() => handleChangeExpandedMapLayers("heatmap")}
          >
            <Box padding={1}>
              <SliderControl
                label="Intensity"
                loading={filterLoading}
                value={heatmapIntensity}
                min={0}
                max={2}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                scale={calculateHeatmapIntensityFactor}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={handleChangeHeatmapIntensity}
              />
              <SliderControl
                label="Radius"
                loading={filterLoading}
                value={heatmapRadius}
                min={0}
                max={2}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                scale={calculateHeatmapRadiusFactor}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={handleChangeHeatmapRadius}
              />
              <SliderControl
                label="Opacity"
                value={heatmapOpacityFactor}
                min={0}
                max={1}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                onChange={(e, value) => handleChangeHeatmapOpacity(value as number)}
              />
            </Box>
          </MapControlContainer>
        </FiltersAndLayersContainer>
      </Box>
    </LeftSidebar>
  );
});
