import { createSelector } from "@reduxjs/toolkit";
import { Api } from "api";
import {
  AOIScreenlineCountsRequest,
  AOIScreenlineDetailsRequest,
  CandidateScreenlineIntersectionsRequest,
  CandidateScreenlineSegment,
  ConvertFeaturesToScreenlinesRequest,
  ConvertFeaturesToScreenlinesResponse,
  CreateScreenlineRequest,
  IntersectionPoint,
  ReadScreenlineShapefileResponse,
  Screenline,
  ScreenlineCounts,
  ScreenlineDetails,
  ScreenlineGeometry,
  ScreenlineValidationRequest,
  ScreenlineValidationResponse,
  ScreenlineValidationSummary,
  ScreenlineValidationSummaryResponse,
  SegmentIntersection,
  UpdateScreenlineGeometryRequest,
} from "api/analytics/index.d";
import { Feature } from "geojson";
import { produce } from "immer";
import { isEqual } from "lodash";
import { Reducer } from "redux";
import { all, call, getContext, put, select, takeLatest } from "redux-saga/effects";

import { buildFilters } from "features/filters/utils";
import { normalizeLoadedScreenlines } from "features/screenline/utils";

import { RootState } from "store";

import { Action, ActionsUnion, createAction } from "store/actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "store/interfaces";

import { reportAboutErrorState } from "utils/reports";

import { GlobalActionType, ScreenlinesActionType } from "./actionTypes";

export interface ScreenlinesState {
  selectedScreenlineId: string | null;
  selectedIntersectionId: string | null;
  isScreelineEditorOpen: boolean;
  isDrawMode: boolean;
  isSaveScreenlinesDialogOpen: boolean;
  isLoadScreenlinesDialogOpen: boolean;
  isImportScreenlinesDialogOpen: boolean;
  loading: boolean;
  screenlines: Screenline[];
  draftScreenline: Screenline | null;
  draftFeature: Feature | null;
  error: ResponseError | null;
  maxCount: number | null;
  showScreenlines: boolean;
  fetchScreenlinesCounts: boolean;
  screenlineCounts: LoadingErrorData<{ screenlineCounts: ScreenlineCounts[]; timePeriod: string }>;
  screenlineDetails: LoadingErrorData<ScreenlineDetails>;
  screenlineValidation: LoadingErrorData<ScreenlineValidationResponse>;
  uploadedScreenlines: LoadingErrorData<ReadScreenlineShapefileResponse>;
  candidateScreenlineIntersections: LoadingErrorData<CandidateScreenlineSegment[]>;
  screenlinesValidationSummary: LoadingErrorData<
    {
      screenlineId: string;
      summary: ScreenlineValidationSummary;
    }[]
  >;
}

const initialState: ScreenlinesState = {
  selectedScreenlineId: null,
  selectedIntersectionId: null,
  isScreelineEditorOpen: false,
  isDrawMode: false,
  isSaveScreenlinesDialogOpen: false,
  isLoadScreenlinesDialogOpen: false,
  isImportScreenlinesDialogOpen: false,
  loading: false,
  screenlines: [],
  draftScreenline: null,
  draftFeature: null,
  error: null,
  maxCount: null,
  showScreenlines: true,
  fetchScreenlinesCounts: true,
  screenlineCounts: {
    state: DataState.EMPTY,
    error: null,
    data: null,
  },
  screenlineDetails: {
    state: DataState.EMPTY,
    error: null,
    data: null,
  },
  screenlineValidation: {
    state: DataState.EMPTY,
    error: null,
    data: null,
  },
  uploadedScreenlines: {
    state: DataState.EMPTY,
    error: null,
    data: null,
  },
  candidateScreenlineIntersections: {
    state: DataState.EMPTY,
    error: null,
    data: null,
  },
  screenlinesValidationSummary: {
    state: DataState.EMPTY,
    error: null,
    data: null,
  },
};

export type ScreenlinesAction = ActionsUnion<typeof screenlinesActions>;

export const screenlinesActions = {
  setSelectedScreenlineId: (screenlineId: string | null) =>
    createAction(ScreenlinesActionType.SET_SELECTED_SCREENLINE_ID, screenlineId),
  setSelectedIntersectionId: (segmentId: string | null) =>
    createAction(ScreenlinesActionType.SET_SELECTED_INTERSECTION_ID, segmentId),
  setScreelineEditorOpen: (isOpen: boolean) => createAction(ScreenlinesActionType.SET_SCREENLINE_EDITOR_OPEN, isOpen),

  setDrawMode: (isDrawMode: boolean) => createAction(ScreenlinesActionType.SET_DRAW_MODE, isDrawMode),

  setIsSaveScreenlineDialogOpen: (isOpen: boolean) =>
    createAction(ScreenlinesActionType.SET_SAVE_SCREENLINE_DIALOG_OPEN, isOpen),

  setIsLoadScreenlinesDialogOpen: (isOpen: boolean) =>
    createAction(ScreenlinesActionType.SET_LOAD_SCREENLINE_DIALOG_OPEN, isOpen),

  setIsImportScreenlinesDialogOpen: (isOpen: boolean) =>
    createAction(ScreenlinesActionType.SET_IMPORT_SCREENLINE_DIALOG_OPEN, isOpen),

  createScreenline: (config: CreateScreenlineRequest) => createAction(ScreenlinesActionType.CREATE_SCREENLINE, config),
  createScreenlineSucceeded: (screenline: Screenline) =>
    createAction(ScreenlinesActionType.CREATE_SCREENLINE_SUCCEEDED, screenline),
  createScreenlineFailed: (error: ResponseError) => createAction(ScreenlinesActionType.CREATE_SCREENLINE_FAILED, error),
  setScreenlines: (screenlines: Screenline[]) => createAction(ScreenlinesActionType.SET_SCREENLINES, screenlines),

  setDraftScreenline: (screenline: Screenline | null) =>
    createAction(ScreenlinesActionType.SET_DRAFT_SCREENLINE, screenline),

  editScreenline: (screenlineId: string, screenline: Screenline, fetchCounts?: boolean) =>
    createAction(ScreenlinesActionType.EDIT_SCREENLINE, { screenlineId, screenline, fetchCounts }),

  updateScreenlineGeometry: (config: UpdateScreenlineGeometryRequest) =>
    createAction(ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY, config),
  updateScreenlineGeometrySucceeded: (screenline: Screenline) =>
    createAction(ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY_SUCCEEDED, screenline),
  updateScreenlineGeometryFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY_FAILED, error),

  setShowScreenlines: (showScreenlines: boolean) =>
    createAction(ScreenlinesActionType.SET_SHOW_SCREENLINES, showScreenlines),

  fetchScreenlineCounts: (
    screenlines?: Screenline[],
    partialConfig?: Omit<AOIScreenlineCountsRequest, "screenlines">,
  ) => createAction(ScreenlinesActionType.FETCH_SCREENLINE_COUNTS, { partialConfig, screenlines }),
  fetchScreenlineCountsSucceeded: (screenlineCounts: ScreenlineCounts[], timePeriod: string) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_COUNTS_SUCCEEDED, { screenlineCounts, timePeriod }),
  fetchScreenlineCountsFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_COUNTS_FAILED, error),
  resetScreenlineCounts: () => createAction(ScreenlinesActionType.RESET_SCREENLINE_COUNTS),
  setFetchScreenlineCounts: (fetchScreenlineCounts: boolean) =>
    createAction(ScreenlinesActionType.SET_FETCH_SCREENLINE_COUNTS, fetchScreenlineCounts),

  fetchScreenlineDetails: (config: AOIScreenlineDetailsRequest) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_DETAILS, config),
  fetchScreenlineDetailsSucceeded: (screenlineDetails: ScreenlineDetails) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_DETAILS_SUCCEEDED, screenlineDetails),
  fetchScreenlineDetailsFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_DETAILS_FAILED, error),

  fetchScreenlineValidation: (
    geometry: ScreenlineGeometry,
    segmentIntersections: SegmentIntersection[],
    timePeriod: string,
    roadClasses?: number[],
  ) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION, {
      geometry,
      segmentIntersections,
      timePeriod,
      roadClasses,
    }),
  fetchScreenlineValidationSucceeded: (screenlineValidation: ScreenlineValidationResponse) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION_SUCCEEDED, screenlineValidation),
  fetchScreenlineValidationFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION_FAILED, error),

  deleteScreenline: (id: string) => createAction(ScreenlinesActionType.DELETE_SCREENLINE, id),

  addIntersections: (intersections: SegmentIntersection[]) =>
    createAction(ScreenlinesActionType.ADD_INTERSECTIONS, intersections),

  deleteIntersections: (intersectionsIds: number[]) =>
    createAction(ScreenlinesActionType.DELETE_INTERSECTIONS, intersectionsIds),

  setDraftFeature: (feature: Feature | null) => createAction(ScreenlinesActionType.SET_DRAFT_FEATURE, feature),

  readScreenlineShapefile: (zippedShapefile: Blob) =>
    createAction(ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE, zippedShapefile),
  readScreenlineShapefileSucceeded: (response: ReadScreenlineShapefileResponse) =>
    createAction(ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE_SUCCEEDED, response),
  readScreenlineShapefileFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE_FAILED, error),

  convertFeaturesToScreenlines: (request: ConvertFeaturesToScreenlinesRequest) =>
    createAction(ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES, request),
  convertFeaturesToScreenlinesSucceeded: (response: ConvertFeaturesToScreenlinesResponse) =>
    createAction(ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES_SUCCEEDED, response),
  convertFeaturesToScreenlinesFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES_FAILED, error),

  fetchCandidateScreenlineIntersections: (
    config: CandidateScreenlineIntersectionsRequest,
    existingScreenline: Screenline | null,
  ) => createAction(ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS, { config, existingScreenline }),
  fetchCandidateScreenlineIntersectionsSucceeded: (segments: CandidateScreenlineSegment[]) =>
    createAction(ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS_SUCCEEDED, segments),
  fetchCandidateScreenlineIntersectionsFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS_FAILED, error),

  fetchScreenlinesValidationSummary: (timePeriod: string, screenlines?: Screenline[]) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY, { timePeriod, screenlines }),
  fetchScreenlinesValidationSummarySucceeded: (screenlinesValidationSummary: ScreenlineValidationSummaryResponse) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY_SUCCEEDED, screenlinesValidationSummary),
  fetchScreenlinesValidationSummaryFailed: (error: ResponseError) =>
    createAction(ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY_FAILED, error),

  resetToDefault: () => createAction(ScreenlinesActionType.RESET_TO_DEFAULT),
};

const screenlinesReducer: Reducer<ScreenlinesState, ScreenlinesAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ScreenlinesActionType.SET_SELECTED_SCREENLINE_ID: {
        if (action.payload === null) draft.screenlineValidation = initialState.screenlineValidation;
        draft.selectedScreenlineId = action.payload;
        return;
      }
      case ScreenlinesActionType.SET_SELECTED_INTERSECTION_ID: {
        draft.selectedIntersectionId = action.payload;
        return;
      }
      case ScreenlinesActionType.SET_SCREENLINE_EDITOR_OPEN: {
        if (!action.payload && draft.isDrawMode) draft.isDrawMode = false;
        if (!action.payload && draft.draftFeature) draft.draftFeature = null;
        if (!action.payload && draft.draftScreenline) draft.draftScreenline = null;
        if (!action.payload && draft.selectedIntersectionId) draft.selectedIntersectionId = null;
        if (!action.payload && draft.candidateScreenlineIntersections.state !== DataState.EMPTY)
          draft.candidateScreenlineIntersections = initialState.candidateScreenlineIntersections;
        draft.isScreelineEditorOpen = action.payload;
        return;
      }

      case ScreenlinesActionType.SET_DRAW_MODE: {
        if (!action.payload) {
          draft.draftFeature = null;
          if (draft.draftScreenline) draft.draftScreenline = null;
          if (draft.candidateScreenlineIntersections.state !== DataState.EMPTY)
            draft.candidateScreenlineIntersections = initialState.candidateScreenlineIntersections;
        }

        draft.isDrawMode = action.payload;
        return;
      }
      case ScreenlinesActionType.SET_SAVE_SCREENLINE_DIALOG_OPEN: {
        draft.isSaveScreenlinesDialogOpen = action.payload;
        return;
      }
      case ScreenlinesActionType.SET_LOAD_SCREENLINE_DIALOG_OPEN: {
        draft.isLoadScreenlinesDialogOpen = action.payload;
        return;
      }
      case ScreenlinesActionType.SET_IMPORT_SCREENLINE_DIALOG_OPEN: {
        draft.isImportScreenlinesDialogOpen = action.payload;

        if (!action.payload) {
          draft.uploadedScreenlines = initialState.uploadedScreenlines;
        }

        return;
      }
      case ScreenlinesActionType.CREATE_SCREENLINE: {
        draft.loading = true;
        draft.error = null;
        return draft;
      }
      case ScreenlinesActionType.CREATE_SCREENLINE_SUCCEEDED: {
        draft.loading = false;
        draft.screenlines.push(action.payload);
        draft.draftFeature = null;
        draft.isDrawMode = false;
        draft.candidateScreenlineIntersections = initialState.candidateScreenlineIntersections;
        draft.fetchScreenlinesCounts = true;
        return draft;
      }
      case ScreenlinesActionType.CREATE_SCREENLINE_FAILED: {
        draft.loading = false;
        draft.error = action.payload;
        return draft;
      }
      case ScreenlinesActionType.SET_SCREENLINES: {
        draft.screenlines = action.payload;
        return draft;
      }
      case ScreenlinesActionType.SET_DRAFT_SCREENLINE: {
        draft.draftScreenline = action.payload;
        return draft;
      }
      case ScreenlinesActionType.EDIT_SCREENLINE: {
        const { screenlineId, screenline, fetchCounts } = action.payload;

        const screenlineIndex = draft.screenlines.findIndex((s) => s.id === screenlineId);
        if (screenlineIndex !== -1) {
          draft.screenlines[screenlineIndex] = screenline;
        }

        const screenlineValidationSummaryIndex =
          draft.screenlinesValidationSummary.data?.findIndex((sv) => sv.screenlineId === screenlineId) ?? -1;
        if (screenlineValidationSummaryIndex !== -1 && draft.screenlinesValidationSummary.data) {
          draft.screenlinesValidationSummary.data[screenlineValidationSummaryIndex].screenlineId = screenline.id;
        }

        const countIndex = draft.screenlineCounts.data?.screenlineCounts.findIndex((c) => c.id === screenlineId) ?? -1;
        if (countIndex !== -1 && draft.screenlineCounts.data) {
          draft.screenlineCounts.data.screenlineCounts[countIndex].weightedIntersectingSegments = undefined;
          draft.screenlineCounts.data.screenlineCounts[countIndex].id = screenline.id;
        }

        if (fetchCounts) draft.fetchScreenlinesCounts = true;
        draft.draftScreenline = null;
        return draft;
      }
      case ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY: {
        draft.loading = true;
        draft.error = null;
        return draft;
      }
      case ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY_SUCCEEDED: {
        const screenlineIndex = draft.screenlines.findIndex((s) => s.id === action.payload.id);
        if (screenlineIndex !== -1) {
          draft.screenlines[screenlineIndex] = action.payload;
        }

        const countIndex =
          draft.screenlineCounts.data?.screenlineCounts.findIndex((c) => c.id === action.payload.id) ?? -1;
        if (countIndex !== -1 && draft.screenlineCounts.data) {
          draft.screenlineCounts.data.screenlineCounts[countIndex].weightedIntersectingSegments = undefined;
        }

        draft.fetchScreenlinesCounts = true;

        draft.loading = false;
        draft.draftFeature = null;
        draft.draftScreenline = null;
        draft.isDrawMode = false;
        draft.candidateScreenlineIntersections = initialState.candidateScreenlineIntersections;

        return draft;
      }
      case ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY_FAILED: {
        draft.loading = false;
        draft.error = action.payload;
        return draft;
      }
      case ScreenlinesActionType.SET_SHOW_SCREENLINES: {
        draft.showScreenlines = action.payload;
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_COUNTS: {
        if (draft.screenlines.length === 0) return;
        draft.screenlineCounts.state = DataState.LOADING;
        draft.screenlineCounts.error = null;
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_COUNTS_SUCCEEDED: {
        const maxCount = action.payload.screenlineCounts.reduce((max, count) => {
          const c = Math.max(count.toLeft, count.toRight);
          return max < c ? c : max;
        }, 0);
        draft.screenlineCounts.state = DataState.AVAILABLE;
        draft.screenlineCounts.data = action.payload;
        draft.maxCount = maxCount;
        draft.fetchScreenlinesCounts = false;
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_COUNTS_FAILED: {
        draft.screenlineCounts.state = DataState.ERROR;
        draft.screenlineCounts.error = action.payload;
        return draft;
      }
      case ScreenlinesActionType.RESET_SCREENLINE_COUNTS: {
        draft.screenlineCounts = initialState.screenlineCounts;
        return draft;
      }
      case ScreenlinesActionType.SET_FETCH_SCREENLINE_COUNTS: {
        draft.fetchScreenlinesCounts = action.payload;
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_DETAILS: {
        draft.screenlineDetails.state = DataState.LOADING;
        draft.screenlineDetails.data = null;
        draft.screenlineDetails.error = null;
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_DETAILS_SUCCEEDED: {
        draft.screenlineDetails.state = DataState.AVAILABLE;
        draft.screenlineDetails.data = action.payload;
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_DETAILS_FAILED: {
        draft.screenlineDetails.state = DataState.ERROR;
        draft.screenlineDetails.error = action.payload;
        return draft;
      }
      case ScreenlinesActionType.DELETE_SCREENLINE: {
        //If deleted screenline is selected, clear validation and deselect
        if (draft.selectedScreenlineId === action.payload) {
          draft.selectedScreenlineId = null;
          draft.screenlineValidation = initialState.screenlineValidation;
        }
        //Remove deleted screenline from screenlines
        draft.screenlines = draft.screenlines.filter((screenline) => screenline.id !== action.payload);
        //Remove deleted screenline summary from screenlinesValidationSummary
        if (draft.screenlinesValidationSummary.data) {
          draft.screenlinesValidationSummary.data = draft.screenlinesValidationSummary.data?.filter(
            (s) => s.screenlineId !== action.payload,
          );
        }
        //Remove deleted screenline counts from screenlineCounts
        if (draft.screenlineCounts.data) {
          draft.screenlineCounts.data.screenlineCounts =
            draft.screenlineCounts.data.screenlineCounts.filter((screenline) => screenline.id !== action.payload) || [];
        }

        return;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION: {
        draft.screenlineValidation.state = DataState.LOADING;
        draft.screenlineValidation.data = null;
        draft.screenlineValidation.error = null;
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION_SUCCEEDED: {
        if (draft.selectedScreenlineId === null) return;
        const newScreenlineSummary = { screenlineId: draft.selectedScreenlineId, summary: action.payload.summary };

        if (!draft.screenlinesValidationSummary.data?.length) {
          draft.screenlinesValidationSummary.state = DataState.AVAILABLE;
          draft.screenlinesValidationSummary.data = [newScreenlineSummary];
        } else {
          const newScreenlineSummaries = draft.screenlinesValidationSummary.data.map((s) =>
            s.screenlineId === newScreenlineSummary.screenlineId ? newScreenlineSummary : s,
          );
          draft.screenlinesValidationSummary.data = newScreenlineSummaries;
        }

        draft.screenlineValidation = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION_FAILED: {
        draft.screenlineValidation.state = DataState.ERROR;
        draft.screenlineValidation.error = action.payload;
        return draft;
      }
      case ScreenlinesActionType.ADD_INTERSECTIONS: {
        if (draft.draftScreenline) {
          const newScreenlineIntersections = [...draft.draftScreenline.segmentIntersections, ...action.payload];

          draft.draftScreenline.segmentIntersections = newScreenlineIntersections.sort(
            (a, b) => a!.intersection.fraction - b!.intersection.fraction,
          );
        }
        return draft;
      }
      case ScreenlinesActionType.DELETE_INTERSECTIONS: {
        if (draft.draftScreenline) {
          draft.draftScreenline.segmentIntersections = draft.draftScreenline.segmentIntersections.filter(
            (_, i) => !action.payload.includes(i),
          );
        }
        return draft;
      }
      case ScreenlinesActionType.SET_DRAFT_FEATURE: {
        draft.draftFeature = action.payload;
        return draft;
      }
      case ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS: {
        draft.candidateScreenlineIntersections = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return draft;
      }
      case ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS_SUCCEEDED: {
        draft.candidateScreenlineIntersections.state = DataState.AVAILABLE;
        draft.candidateScreenlineIntersections.data = action.payload;
        return draft;
      }
      case ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS_FAILED: {
        draft.candidateScreenlineIntersections.state = DataState.ERROR;
        draft.candidateScreenlineIntersections.error = action.payload;
        return draft;
      }
      case ScreenlinesActionType.RESET_TO_DEFAULT: {
        return initialState;
      }
      case ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE: {
        draft.uploadedScreenlines = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return draft;
      }
      case ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE_SUCCEEDED: {
        draft.uploadedScreenlines = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };
        return draft;
      }
      case ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE_FAILED: {
        draft.uploadedScreenlines = {
          state: DataState.ERROR,
          data: null,
          error: action.payload,
        };
        return draft;
      }
      case ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES: {
        draft.uploadedScreenlines.state = DataState.LOADING;

        return draft;
      }
      case ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES_SUCCEEDED: {
        draft.uploadedScreenlines = initialState.uploadedScreenlines;
        draft.screenlines = action.payload.screenlines;
        draft.isImportScreenlinesDialogOpen = false;

        return draft;
      }
      case ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES_FAILED: {
        draft.uploadedScreenlines.state = DataState.ERROR;
        draft.uploadedScreenlines.error = action.payload;

        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY: {
        if (!action.payload.screenlines?.length && !draft.screenlines.length) return;

        draft.screenlinesValidationSummary = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY_SUCCEEDED: {
        draft.screenlinesValidationSummary = {
          state: DataState.AVAILABLE,
          data: action.payload.results,
          error: null,
        };
        return draft;
      }
      case ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY_FAILED: {
        draft.screenlinesValidationSummary = {
          state: DataState.ERROR,
          data: null,
          error: action.payload,
        };
        return draft;
      }

      default:
        return state;
    }
  });

export default screenlinesReducer;

function* createScreenline(action: Action<string, CreateScreenlineRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { createScreenline },
    } = api as Api;

    const { screenline }: any = yield call(createScreenline, action.payload);

    yield put({
      type: ScreenlinesActionType.CREATE_SCREENLINE_SUCCEEDED,
      payload: { ...screenline, visible: true },
    });
  } catch (e: any) {
    reportAboutErrorState(e, ScreenlinesActionType.CREATE_SCREENLINE_FAILED);

    yield put({
      type: GlobalActionType.SET_TOAST_MESSAGE,
      payload: {
        content: e.body?.what || "Failed to create screenline",
        severity: "error",
      },
    });

    yield put({
      type: ScreenlinesActionType.CREATE_SCREENLINE_FAILED,
      payload: e,
    });
  }
}

function* updateScreenlineGeometry(action: Action<string, UpdateScreenlineGeometryRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { updateScreenlineGeometry },
    } = api as Api;

    const { screenline }: any = yield call(updateScreenlineGeometry, action.payload);

    yield put({
      type: ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY_SUCCEEDED,
      payload: { ...screenline, visible: screenline.visible ?? true },
    });
  } catch (e: any) {
    reportAboutErrorState(e, ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY_FAILED);

    yield put({
      type: GlobalActionType.SET_TOAST_MESSAGE,
      payload: {
        content: e.body?.what || "Failed to update screenline",
        severity: "error",
      },
    });

    yield put({
      type: ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY_FAILED,
      payload: e,
    });
  }
}

function* getScreenlineCounts(
  action: Action<
    string,
    { screenlines?: Screenline[]; partialConfig?: Omit<AOIScreenlineCountsRequest, "screenlines"> }
  >,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchAOIScreenlineCounts },
    } = api as Api;

    const previousScreenlineCountsData: any = yield select(
      (state: RootState) => state.screenlines.screenlineCounts.data,
    );
    const timePeriod: any = yield select((state: RootState) => state.global.timePeriod);
    const measure: any = yield select((state: RootState) => state.filters.measure);
    const roadClasses: any = yield select((state: RootState) => state.filters.roadClasses);
    const filter: any = yield select((state: RootState) => state.filters.roadFilters);
    const previousScreenlines: any = yield select((state: RootState) => state.screenlines.screenlines);
    const payloadScreenlines = action.payload.screenlines || previousScreenlines;
    const payloadConfig = action.payload.partialConfig || {
      timePeriod,
      measure,
      roadClasses,
      filter: buildFilters(filter),
      includeSegments: true,
    };

    if (previousScreenlines.length === 0) return;

    const config = {
      ...payloadConfig,
      screenlines: payloadScreenlines.map((s: Screenline) => ({
        ...s,
        weightedIntersectingSegments:
          previousScreenlineCountsData?.timePeriod === payloadConfig.timePeriod
            ? previousScreenlineCountsData?.screenlineCounts.find((c: any) => c.id === s.id)
                ?.weightedIntersectingSegments
            : null,
      })),
    };

    const { screenlines }: any = yield call(fetchAOIScreenlineCounts, config);

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINE_COUNTS_SUCCEEDED,
      payload: { screenlineCounts: screenlines, timePeriod: config.timePeriod },
    });
  } catch (e) {
    console.error(e);
    reportAboutErrorState(e, ScreenlinesActionType.FETCH_SCREENLINE_COUNTS_FAILED);

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINE_COUNTS_FAILED,
      payload: e,
    });
  }
}

function* getScreenlineDetails(action: Action<string, AOIScreenlineDetailsRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchAOIScreenlineDetails },
    } = api as Api;
    const previousScreenlineCountsData: any = yield select(
      (state: RootState) => state.screenlines.screenlineCounts.data,
    );

    const screenline = action.payload.screenline;
    const config = {
      ...action.payload,
      screenline: {
        ...screenline,
        weightedIntersectingSegments:
          previousScreenlineCountsData?.timePeriod === action.payload.timePeriod
            ? previousScreenlineCountsData?.screenlineCounts.find((c: any) => c.id === screenline.id)
                ?.weightedIntersectingSegments
            : null,
      },
    };

    const { screenlineDetails }: any = yield call(fetchAOIScreenlineDetails, config);

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINE_DETAILS_SUCCEEDED,
      payload: screenlineDetails,
    });
  } catch (e) {
    reportAboutErrorState(e, ScreenlinesActionType.FETCH_SCREENLINE_DETAILS_FAILED);

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINE_DETAILS_FAILED,
      payload: e,
    });
  }
}

function* getScreenlineValidation(
  action: Action<
    string,
    {
      geometry: ScreenlineGeometry;
      segmentIntersections: SegmentIntersection[];
      timePeriod: string;
      roadClasses?: number[];
    }
  >,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { validateScreenline },
    } = api as Api;

    const selectedScreenline: any = yield select(selectSelectedScreenline);

    const config: ScreenlineValidationRequest = {
      screenline: {
        ...selectedScreenline,
        geometry: action.payload.geometry,
        segmentIntersections: action.payload.segmentIntersections,
      },
      timePeriod: action.payload.timePeriod,
      roadClasses: action.payload.roadClasses,
    };

    const screenlineValidation: any = yield call(validateScreenline, config);

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION_SUCCEEDED,
      payload: screenlineValidation,
    });
  } catch (e) {
    reportAboutErrorState(e, ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION_FAILED);

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION_FAILED,
      payload: e,
    });
  }
}

function* uploadScreenlines(action: Action<string, Blob>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { readScreenlineShapefile },
    } = api as Api;
    const uploadedScreenlines: any = yield call(readScreenlineShapefile, action.payload);

    yield put({
      type: ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE_SUCCEEDED,
      payload: uploadedScreenlines,
    });
  } catch (e) {
    reportAboutErrorState(e, ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE_FAILED);

    yield put({
      type: ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE_FAILED,
      payload: e,
    });
  }
}

function* convertFeaturesToScreenlines(action: Action<string, ConvertFeaturesToScreenlinesRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { convertFeaturesToScreenlines },
    } = api as Api;
    const response: any = yield call(convertFeaturesToScreenlines, action.payload);

    let newScreenlines = normalizeLoadedScreenlines(response.screenlines);

    const { existingIds, timePeriod } = action.payload;
    const appendToScreenlines = existingIds && existingIds.length > 0;
    if (appendToScreenlines) {
      const existingScreenlines: any = yield select((state: RootState) => state.screenlines.screenlines);
      newScreenlines = [...existingScreenlines, ...newScreenlines];
    } else {
      yield put({
        type: ScreenlinesActionType.RESET_SCREENLINE_COUNTS,
      });
    }

    yield put({
      type: ScreenlinesActionType.SET_FETCH_SCREENLINE_COUNTS,
      payload: true,
    });

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY,
      payload: { timePeriod, screenlines: newScreenlines },
    });

    yield put({
      type: ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES_SUCCEEDED,
      payload: { validationMessages: response.validationMessages, screenlines: newScreenlines },
    });
  } catch (e) {
    reportAboutErrorState(e, ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES_FAILED);

    yield put({
      type: ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES_FAILED,
      payload: e,
    });
  }
}

function* getCandidateScreenlineIntersections(
  action: Action<string, { config: CandidateScreenlineIntersectionsRequest; existingScreenline: Screenline | null }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { candidateScreenlineIntersections, updateScreenlineGeometry },
    } = api as Api;
    const { config, existingScreenline } = action.payload;

    let newSegments: CandidateScreenlineSegment[] = [];

    if (existingScreenline) {
      const { screenline, segments }: any = yield call(updateScreenlineGeometry, {
        screenline: existingScreenline,
        updatedGeometry: config.geometry,
        timePeriod: config.timePeriod,
        roadClasses: config.roadClasses,
        includeSegments: true,
      });

      const groupIntersectionsBySegmentId = (segmentIntersections: SegmentIntersection[]) =>
        segmentIntersections.reduce((obj: Record<string, IntersectionPoint[]>, si) => {
          if (!obj[si.segmentId]) obj[si.segmentId] = [si.intersection];
          else obj[si.segmentId].push(si.intersection);
          return obj;
        }, {});

      const newIntersections = groupIntersectionsBySegmentId(screenline.segmentIntersections);

      segments.forEach((s: any) => {
        const intersections: any[] = newIntersections[s.id];

        if (intersections.length) {
          newSegments.push({
            ...s,
            intersections,
          });
        }
      });
    } else {
      const { segments }: any = yield call(candidateScreenlineIntersections, config);
      newSegments = segments;
    }
    yield put({
      type: ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS_SUCCEEDED,
      payload: newSegments,
    });
  } catch (e) {
    console.error(e);
    reportAboutErrorState(e, ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS_FAILED);

    yield put({
      type: ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS_FAILED,
      payload: e,
    });
  }
}

function* screenlinesValidationSummary(
  action: Action<string, { timePeriod: string; screenlines?: Screenline[] }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { validateScreenlines },
    } = api as Api;
    const { timePeriod, screenlines } = action.payload;
    const existingScreenlines: any = yield select((state: RootState) => state.screenlines.screenlines);

    if (!screenlines?.length && !existingScreenlines.length) return;

    const screenlinesValidationSummaryResponse = yield call(validateScreenlines, {
      screenlines: screenlines || existingScreenlines,
      timePeriod,
    });

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY_SUCCEEDED,
      payload: screenlinesValidationSummaryResponse,
    });
  } catch (e) {
    reportAboutErrorState(e, ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY_FAILED);

    yield put({
      type: ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY_FAILED,
      payload: e,
    });
  }
}

export function* screenlinesSaga() {
  yield all([
    takeLatest(ScreenlinesActionType.CREATE_SCREENLINE, createScreenline),
    takeLatest(ScreenlinesActionType.UPDATE_SCREENLINE_GEOMETRY, updateScreenlineGeometry),
    takeLatest(ScreenlinesActionType.FETCH_SCREENLINE_COUNTS, getScreenlineCounts),
    takeLatest(ScreenlinesActionType.FETCH_SCREENLINE_DETAILS, getScreenlineDetails),
    takeLatest(ScreenlinesActionType.FETCH_SCREENLINE_VALIDATION, getScreenlineValidation),
    takeLatest(ScreenlinesActionType.READ_SCREENLINE_SHAPEFILE, uploadScreenlines),
    takeLatest(ScreenlinesActionType.CONVERT_FEATURES_TO_SCREENLINES, convertFeaturesToScreenlines),
    takeLatest(ScreenlinesActionType.FETCH_CANDIDATE_SCREENLINE_INTERSECTIONS, getCandidateScreenlineIntersections),
    takeLatest(ScreenlinesActionType.FETCH_SCREENLINES_VALIDATION_SUMMARY, screenlinesValidationSummary),
  ]);
}

export const selectSelectedScreenline = createSelector([(state: RootState) => state.screenlines], (screenlines) => {
  return screenlines.screenlines.find((s) => s.id === screenlines.selectedScreenlineId) || null;
});

export const selectVisibleScreenlines = createSelector(
  [(state: RootState) => state.screenlines.screenlines],
  (screenlines) => {
    return screenlines.filter((s) => s.visible == null || s.visible);
  },
);

export const selectScreenlineCountsObj = createSelector(
  [(state: RootState) => state.screenlines.screenlineCounts.data],
  (screenlineCountsData) => {
    return (
      screenlineCountsData?.screenlineCounts.reduce((obj: { [key: string]: ScreenlineCounts }, s) => {
        obj[s.id] = s;
        return obj;
      }, {}) || null
    );
  },
);

export const selectCandidateIntersections = createSelector(
  [(state: RootState) => state.screenlines.screenlineValidation.data],
  (screenlineValidation) =>
    (screenlineValidation?.candidateSegments
      .map(({ segmentIntersections }) => segmentIntersections)
      .filter((intersection) => intersection !== undefined)
      .flat() || []) as SegmentIntersection[],
);

export const selectSegmentIntersections = createSelector([selectSelectedScreenline], (screenline) => {
  return screenline?.segmentIntersections || [];
});

export const selectScreenlinesLoading = createSelector(
  [
    (state: RootState) => state.screenlines.loading,
    (state: RootState) => state.screenlines.screenlineCounts.state,
    (state: RootState) => state.screenlines.screenlineDetails.state,
    (state: RootState) => state.screenlines.screenlineValidation.state,
    (state: RootState) => state.screenlines.candidateScreenlineIntersections.state,
  ],
  (loading, countsState, detailsState, validationState, candidateIntersectionsState) =>
    loading ||
    countsState === DataState.LOADING ||
    detailsState === DataState.LOADING ||
    validationState === DataState.LOADING ||
    candidateIntersectionsState === DataState.LOADING,
);

export const selectArePendingChanges = createSelector(
  [
    selectSelectedScreenline,
    (state: RootState) => state.screenlines.draftScreenline,
    (state: RootState) => state.screenlines.draftFeature,
  ],
  (selectedScreenline, draftScreenline, draftFeature) =>
    Boolean(draftScreenline) &&
    !isEqual(selectedScreenline, { ...draftScreenline, geometry: draftFeature?.geometry || draftScreenline?.geometry }),
);

export const selectAreUnsavedChanges = createSelector(
  [
    (state: RootState) => state.datasetFolders.loadedConfigDocument.data?.payload,
    (state: RootState) => state.screenlines.screenlines,
  ],
  (configDocPayload, screenlines) => {
    return (
      configDocPayload &&
      !isEqual(
        configDocPayload.map((screenline: Screenline) => ({
          ...screenline,
          segmentIntersections: [...screenline.segmentIntersections].sort(
            (a, b) => b.intersection.fraction - a.intersection.fraction,
          ),
        })),
        screenlines.map((screenline) => ({
          ...screenline,
          segmentIntersections: [...screenline.segmentIntersections].sort(
            (a, b) => b.intersection.fraction - a.intersection.fraction,
          ),
        })),
      )
    );
  },
);

export const selectScreenlineShapefileValidFeatures = createSelector(
  [(state: RootState) => state.screenlines.uploadedScreenlines.data?.preparedFeatures],
  (preparedFeatures) => preparedFeatures?.filter((f) => f.properties?.__valid) || [],
);

export const selectIdFieldCandidates = (state: RootState) =>
  state.screenlines.uploadedScreenlines.data?.idFieldCandidates;
export const selectNameFieldCandidates = (state: RootState) =>
  state.screenlines.uploadedScreenlines.data?.nameFieldCandidates;
export const selectDescriptionFieldCandidates = (state: RootState) =>
  state.screenlines.uploadedScreenlines.data?.descriptionFieldCandidates;

export const selectAreSuitableFieldCandidates = createSelector(
  [selectIdFieldCandidates, selectNameFieldCandidates, selectDescriptionFieldCandidates],
  (idFieldCandidates, nameFieldCandidates, descriptionFieldCandidates) =>
    idFieldCandidates?.length && nameFieldCandidates?.length && descriptionFieldCandidates?.length,
);

export const selectScreenlinesValidationSummaryMaxSeverity = createSelector(
  [(state: RootState) => state.screenlines.screenlinesValidationSummary.data],
  (summaryData) => {
    const summaryMaxSeverities = summaryData?.map((s) => s.summary.maximumMessageSeverity) || [];
    if (summaryMaxSeverities.filter((severity) => severity === "error").length) return "error";
    if (summaryMaxSeverities.filter((severity) => severity === "warning").length) return "warning";
    if (summaryMaxSeverities.filter((severity) => severity === "info").length) return "info";
    return null;
  },
);
