import { Check, Clear, Edit } from "@mui/icons-material";
import { Box, Stack, SxProps } from "@mui/material";
import { Button, IconButton } from "components_new";
import { FC } from "react";

export interface EditConfirmCancelButtonProps {
  editMode: boolean;
  confirmDisabled?: boolean;
  editDisabled?: boolean;
  handleSetEditMode: (editMode: boolean) => void;
  handleApply: () => void;
  handleCancel: () => void;
  sx?: SxProps;
}

export const EditConfirmCancelButton: FC<EditConfirmCancelButtonProps> = ({
  editMode,
  confirmDisabled,
  editDisabled,
  sx,
  handleApply,
  handleCancel,
  handleSetEditMode,
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      ...sx,
    }}
  >
    {editMode ? (
      <Stack direction={"row"} spacing={1} paddingX={1}>
        <IconButton color="success" disabled={confirmDisabled} onClick={handleApply}>
          <Check fontSize="small" />
        </IconButton>
        <IconButton color="error" onClick={handleCancel}>
          <Clear fontSize="small" />
        </IconButton>
      </Stack>
    ) : (
      <Button
        variant="text"
        color="secondary"
        sx={{
          padding: 0,
          margin: 0,
          "& .buttonLabel": { fontSize: 11, fontWeight: 500 },
        }}
        startIcon={<Edit sx={{ fontSize: "11px !important" }} />}
        onClick={() => handleSetEditMode(true)}
        disabled={editDisabled}
      >
        Edit
      </Button>
    )}
  </Box>
);
