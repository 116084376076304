import { DirectionsCar } from "@mui/icons-material";
import React, { ChangeEvent, Dispatch, FC, ReactNode, SetStateAction, useEffect } from "react";

import { CheckboxDropdown, CheckboxDropdownItem } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

export interface RoadClass extends CheckboxDropdownItem {
  value: string;
}

export type RoadClassesItems = {
  [key: string]: RoadClass;
};

export interface SelectorRoadClasses extends CheckboxDropdownItem {
  items: RoadClassesItems;
  icon: ReactNode;
}

export interface RoadClassSelectorProps {
  roadClasses: SelectorRoadClasses | null;
  savedRoadClasses: number[] | null;
  setRoadClasses: Dispatch<SetStateAction<SelectorRoadClasses | null>>;
  loading?: boolean;
}

export const RoadClassSelector: FC<RoadClassSelectorProps> = ({
  roadClasses,
  savedRoadClasses,
  setRoadClasses,
  loading,
}) => {
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);

  const handleMultiSelectAll = (isChecked: boolean) => () => {
    if (roadClasses) {
      setRoadClasses({
        ...roadClasses,
        isChecked,
        items: Object.entries(roadClasses.items).reduce((newItems, [itemKey, itemValue]) => {
          newItems[itemKey] = {
            ...itemValue,
            isChecked: isChecked,
          };
          return newItems;
        }, {} as any),
      });
    }
  };

  const handleMultiSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const namesArr = event.target.name.split("-");
    const itemName = namesArr[1];

    if (roadClasses) {
      setRoadClasses({
        ...roadClasses,
        isChecked: Object.values(roadClasses.items).every((item) =>
          item.value === roadClasses.items[itemName].value ? !roadClasses.items[itemName].isChecked : item.isChecked,
        ),
        items: {
          ...roadClasses.items,
          [itemName]: {
            ...roadClasses.items[itemName],
            isChecked: !roadClasses.items[itemName].isChecked,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE && !Object.keys(roadClasses?.items || {}).length) {
      const datasetRoadClasses = roadsMetadata.data?.roadClasses
        .map((roadClass) => ({
          value: roadClass.id.toString(),
          label: roadClass.label,
          isChecked: Boolean(savedRoadClasses?.includes(roadClass.id)),
        }))
        .reduce((map, roadClass) => {
          return {
            ...map,
            [roadClass.value]: roadClass,
          };
        }, {});

      setRoadClasses({
        isChecked: savedRoadClasses?.length === roadsMetadata.data?.roadClasses.length ? true : false,
        label: "Road Classes",
        icon: "drive-time",
        items: datasetRoadClasses,
      });
    }
  }, [roadsMetadata.state, roadsMetadata.data?.roadClasses, savedRoadClasses, roadClasses?.items, setRoadClasses]);

  return (
    <CheckboxDropdown
      isGroupChecked={roadClasses?.isChecked || false}
      groupLabel={roadClasses?.label || ""}
      groupName={roadClasses?.label || ""}
      groupIcon={<DirectionsCar fontSize="small" color="secondary" />}
      items={roadClasses?.items || {}}
      placement="top"
      error={false}
      disabled={loading}
      onChange={handleMultiSelectChange}
      selectAll={handleMultiSelectAll(true)}
      clearAll={handleMultiSelectAll(false)}
    />
  );
};
