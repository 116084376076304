import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import {
  CandidateIntersectionSegmentProperties,
  CandidateScreenlineSegment,
  ResolvedIntersectionSegmentProperties,
  Screenline,
  ScreenlineCounts,
  ScreenlineGeometry,
  SegmentIntersection,
  ValidatedSegmentIntersection,
} from "api/analytics/index.d";
import { Feature } from "geojson";
import { GeoJSONSourceOptions } from "mapbox-gl";
import { MutableRefObject, useCallback } from "react";

import { buildFilters, getCurrentMeasure } from "features/filters/utils";
import { getScreenlinesVolumesOpacityExpression } from "features/map/modules/screenlines/map-data/layers";

import { useAppDispatch } from "hooks";

import { screenlinesActions } from "store/sections/screenlines";

import { FiltersType, MeasureType, RoadsMetadataResponse } from "types";

import {
  SCREENLINES_INTERSECTIONS_LAYER_ID,
  SCREENLINES_LAYER_ID,
  SCREENLINES_SEGMENTS_LAYER_ID,
  SCREENLINES_VOLUMES_LAYER_ID,
} from "./map-data/layers";

const screenlineLayers = [
  SCREENLINES_INTERSECTIONS_LAYER_ID,
  SCREENLINES_LAYER_ID,
  SCREENLINES_SEGMENTS_LAYER_ID,
  `${SCREENLINES_VOLUMES_LAYER_ID}_right`,
  `${SCREENLINES_VOLUMES_LAYER_ID}_left`,
];

export const useGetScreenlinesSourceData = (
  screenlines: Screenline[],
  screenlineCountsObj: {
    [key: string]: ScreenlineCounts;
  } | null,
  screenlinesMaxCount: number | null,
  roadClasses: number[] | null,
) => {
  return useCallback(
    (): GeoJSONSourceOptions["data"] => ({
      type: "FeatureCollection",
      features: screenlines
        .filter((s) => s.visible)
        .map((s) => {
          const counts = screenlineCountsObj?.[s.id];
          const segmentIntersectionsLength = s.segmentIntersections.reduce(
            (count, intersection) => (roadClasses?.includes(intersection.roadClass) ? count + 1 : count),

            0,
          );

          return {
            type: "Feature",
            properties: {
              id: s.id,
              toLeft: counts?.toLeft || 0,
              toRight: counts?.toRight || 0,
              leftLabel: s.leftLabel || "",
              rightLabel: s.rightLabel || "",
              maxCount: screenlinesMaxCount || 0,
              segmentIntersectionsLength,
              totalSegmentIntersections: s.segmentIntersections?.length || 0,
            },
            geometry: s.geometry,
          };
        }),
    }),
    [screenlines, roadClasses, screenlineCountsObj, screenlinesMaxCount],
  );
};

export const useGetScreenlineSegmentsSourceData = (
  resolvedSegments: ResolvedIntersectionSegmentProperties[],
  candidateSegments: CandidateIntersectionSegmentProperties[],
  draftCandidateSegments: CandidateScreenlineSegment[],
  isEditScreenlineMode: boolean | null,
  isDrawMode: boolean | null,
  isScreenlineVisible: boolean | undefined,
) => {
  return useCallback((): GeoJSONSourceOptions["data"] => {
    const resolved: Feature[] =
      resolvedSegments.map((s) => ({
        type: "Feature",
        properties: {
          segmentId: s.segmentIndex,
          roadClass: s.roadClass,
          candidate: false,
          deprecated: draftCandidateSegments.length > 0,
        },
        geometry: s.geometry,
      })) || [];
    const candidates: Feature[] =
      candidateSegments.map((s) => ({
        type: "Feature",
        properties: {
          segmentId: s.segmentIndex,
          roadClass: s.roadClass,
          candidate: true,
        },
        geometry: s.geometry,
      })) || [];
    const draftCandidates: Feature[] = draftCandidateSegments.map((ds) => ({
      type: "Feature",
      properties: {
        idx: ds.idx,
        candidate: true,
      },
      geometry: ds.geometry,
    }));

    return {
      type: "FeatureCollection",
      features: isScreenlineVisible
        ? [...resolved, ...(isEditScreenlineMode ? [...(isDrawMode ? draftCandidates : candidates)] : [])]
        : [],
    };
  }, [
    candidateSegments,
    resolvedSegments,
    draftCandidateSegments,
    isEditScreenlineMode,
    isDrawMode,
    isScreenlineVisible,
  ]);
};

export const useGetScreenlineIntersectionsSourceData = (
  validatedSegmentIntersections: ValidatedSegmentIntersection[],
  candidateSegments: CandidateIntersectionSegmentProperties[],
  draftCandidateSegments: CandidateScreenlineSegment[],
  isEditScreenlineMode: boolean | null,
  isDrawMode: boolean | null,
  isScreenlineVisible: boolean | undefined,
) => {
  return useCallback((): GeoJSONSourceOptions["data"] => {
    const resolvedIntersections: Feature[] = validatedSegmentIntersections.map((segInt) => {
      const resolved = !!segInt.resolvedIntersection;
      const segmentIntersection = segInt.resolvedIntersection || { ...segInt, intersection: segInt.intersectionPoint };
      const { segmentId, roadClass, intersection } = segmentIntersection;

      return {
        type: "Feature",
        properties: {
          id: `${intersection.fraction}`,
          segmentId,
          roadClass,
          lat: intersection.lat,
          lon: intersection.lon,
          candidate: false,
          fraction: intersection.fraction,
          direction: intersection.direction,
          resolved,
        },
        geometry: {
          type: "Point",
          coordinates: [intersection.lon, intersection.lat],
        },
      };
    }) as Feature[];

    const candidateIntersections: Feature[] =
      candidateSegments?.flatMap(
        (s) =>
          s.segmentIntersections.map((segmentIntersection) => {
            const { segmentId, roadClass, intersection } = segmentIntersection;
            return {
              type: "Feature",
              properties: {
                id: `${intersection.fraction}`,
                segmentId,
                roadClass,
                lat: intersection.lat,
                lon: intersection.lon,
                candidate: true,
                fraction: intersection.fraction,
                direction: intersection.direction,
                resolved: true,
              },
              geometry: {
                type: "Point",
                coordinates: [intersection.lon, intersection.lat],
              },
            };
          }) as Feature[],
      ) || [];

    const draftCandidateIntersections: Feature[] =
      draftCandidateSegments?.flatMap(
        (ds) =>
          ds.intersections.map((intersection) => {
            return {
              type: "Feature",
              properties: {
                idx: ds.idx,
                lat: intersection.lat,
                lon: intersection.lon,
                candidate: true,
              },
              geometry: {
                type: "Point",
                coordinates: [intersection.lon, intersection.lat],
              },
            };
          }) as Feature[],
      ) || [];
    return {
      type: "FeatureCollection",
      features: isScreenlineVisible
        ? [
            ...resolvedIntersections,
            ...(isEditScreenlineMode ? [...(isDrawMode ? draftCandidateIntersections : candidateIntersections)] : []),
          ]
        : [],
    };
  }, [
    candidateSegments,
    validatedSegmentIntersections,
    draftCandidateSegments,
    isEditScreenlineMode,
    isDrawMode,
    isScreenlineVisible,
  ]);
};

export const useChangeShowScreenlines = (
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  showScreenlines: boolean,
) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (mapboxLayerManager.current) {
      screenlineLayers.forEach((layerId) => {
        mapboxLayerManager.current!.updateLayerLayout(layerId, "visibility", !showScreenlines ? "visible" : "none");
      });

      dispatch(screenlinesActions.setShowScreenlines(!showScreenlines));
    }

    return false;
  }, [mapboxLayerManager, showScreenlines, dispatch]);
};

export const useSetSelectedScreenlineId = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (id: string | null) => {
      dispatch(screenlinesActions.setSelectedScreenlineId(id));
    },
    [dispatch],
  );
};

export const useSetSelectedIntersectionId = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (intersectionId: string | null) => {
      dispatch(screenlinesActions.setSelectedIntersectionId(intersectionId));
    },
    [dispatch],
  );
};

export const useFetchScreenlineCountsWithScreenlines = (screenlines: Screenline[]) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (screenlines?.length) {
      dispatch(screenlinesActions.fetchScreenlineCounts(screenlines));
    }
  }, [dispatch, screenlines]);
};

export const useFetchScreenlineCountsWithConfig = (
  timePeriod: string | null,
  roadClasses: number[] | null,
  measure: MeasureType,
  roadFilters: FiltersType | null,
) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (timePeriod && measure) {
      dispatch(
        screenlinesActions.fetchScreenlineCounts(undefined, {
          timePeriod,
          measure,
          filter: buildFilters(roadFilters),
          roadClasses: roadClasses || [],
          includeSegments: true,
        }),
      );
    }
  }, [dispatch, measure, roadClasses, roadFilters, timePeriod]);
};

export const useFetchScreenlineDetails = (
  timePeriod: string | null,
  roadClasses: number[] | null,
  selectedScreenline: Screenline | null,
  measure: MeasureType,
  roadFilters: FiltersType | null,
  roadsMetadata: RoadsMetadataResponse | null,
  isScreelineEditorOpen: boolean,
) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (!isScreelineEditorOpen && selectedScreenline && timePeriod && measure && roadClasses && roadsMetadata) {
      const currentMeasure = getCurrentMeasure(roadsMetadata?.measures, measure);
      const dimensions = currentMeasure?.dimensions?.filter((d) => d.enabled).map((d) => d.columnName) || [];
      const breakdowns: any[] = dimensions.map((d) => ({
        dimensions: [d],
        includeUnfiltered: false,
      }));

      dispatch(
        screenlinesActions.fetchScreenlineDetails({
          screenline: {
            id: selectedScreenline.id,
            geometry: selectedScreenline.geometry,
            segmentIntersections: selectedScreenline.segmentIntersections,
            intersectionDirectionFilter: selectedScreenline.intersectionDirectionFilter,
          },
          summary: {
            filteredTotal: true,
            breakdowns,
          },
          timePeriod,
          measure,
          roadClasses,
          ...(roadFilters ? { filter: buildFilters(roadFilters) } : {}),
        }),
      );
    }
  }, [
    dispatch,
    measure,
    roadClasses,
    roadFilters,
    selectedScreenline,
    timePeriod,
    roadsMetadata,
    isScreelineEditorOpen,
  ]);
};

export const useFetchScreenlineValidation = (
  timePeriod: string | null,
  roadClasses: number[] | null,
  geometry?: ScreenlineGeometry,
  segmentIntersections?: SegmentIntersection[],
) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (timePeriod && geometry && segmentIntersections) {
      dispatch(
        screenlinesActions.fetchScreenlineValidation(geometry, segmentIntersections, timePeriod, roadClasses || []),
      );
    }
  }, [dispatch, geometry, segmentIntersections, timePeriod, roadClasses]);
};

export const useHandleSetDraftFeature = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (feature: Feature | null) => {
      dispatch(screenlinesActions.setDraftFeature(feature));
    },
    [dispatch],
  );
};

export const useHandleSetDrawMode = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (isDrawMode: boolean) => {
      dispatch(screenlinesActions.setDrawMode(isDrawMode));
    },
    [dispatch],
  );
};

export const useFetchScreenlinesValidationSummary = (timePeriod: string | null) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (timePeriod) {
      dispatch(screenlinesActions.fetchScreenlinesValidationSummary(timePeriod));
    }
  }, [timePeriod, dispatch]);
};

export const useChangeScreenlinesOpacity = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (opacityFactor: number) => {
      if (map.current!.getLayer(`${SCREENLINES_VOLUMES_LAYER_ID}_right`)) {
        mapboxLayerManager.current?.updateLayerPaint(
          `${SCREENLINES_VOLUMES_LAYER_ID}_right`,
          "line-opacity",
          getScreenlinesVolumesOpacityExpression(opacityFactor),
        );
      }
      if (map.current!.getLayer(`${SCREENLINES_VOLUMES_LAYER_ID}_left`)) {
        mapboxLayerManager.current?.updateLayerPaint(
          `${SCREENLINES_VOLUMES_LAYER_ID}_left`,
          "line-opacity",
          getScreenlinesVolumesOpacityExpression(opacityFactor),
        );
      }
    },
    [map, mapboxLayerManager],
  );
};
