import { Box, Grid, styled } from "@mui/material";
import {
  EditorToggleControl,
  MapControlContainer,
  SeverityIcon,
  SliderControl,
  ValidationMessagesTooltip,
  VisibilityIconButton
} from "components_new";
import Typography from '@mui/material/Typography';
import { FC, MutableRefObject, useEffect, useMemo, useState } from "react";

import { MapLayersId } from "features/map/MapControlPanel";
import { ModuleData } from "features/map/ModuleManager";
import { SCREENLINE_WIDTH_FACTOR } from "features/map/modules/screenlines/map-data/layers";
import { setScreenlineLayersRoadClasses, setScreenlineLayersWidthFactor } from "features/screenline";

import { useAppDispatch, useAppSelector } from "hooks";

import {
  screenlinesActions,
  selectArePendingChanges,
  selectScreenlinesLoading,
  selectScreenlinesValidationSummaryMaxSeverity,
} from "store/sections/screenlines";

import { ValidationMessageSeverity } from "types";

export interface ScreenlineMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  screenlinesModuleData: ModuleData;
  expandedMapLayers: MapLayersId[];
  onChange: (mapLayerId: MapLayersId) => void;
}

export const InlineGridItem = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-item": {
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

function calculateScreenlineWidthFactor(value: number) {
  return value ** 3;
}

function valueLabelFormat(value: number) {
  return `x ${Math.round(value * 100) / 100}`;
}

function summaryValidationMessage(screenlineCount: number, withUnresolvedIntersections: boolean): string {
  const issueDescription = withUnresolvedIntersections
    ? `intersections that were not found in this road network`
    : `intersections for which there are minor differences in this road network`;

  return screenlineCount === 1
    ? `1 screenline has ${issueDescription}`
    : `${screenlineCount} screenlines have ${issueDescription}`;
}

export const ScreenlineMapLayers: FC<ScreenlineMapLayersProps> = ({
  map,
  screenlinesModuleData,
  expandedMapLayers,
  onChange,
}) => {
  const { changeShowScreenlines, changeScreenlinesOpacity } = screenlinesModuleData.data;

  const dispatch = useAppDispatch();
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const isScreenlinesEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
  const loading = useAppSelector(selectScreenlinesLoading);
  const screenlinesMaxCount = useAppSelector((state) => state.screenlines.maxCount);
  const arePendingChanges = useAppSelector(selectArePendingChanges);
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);
  const showScreenlines = useAppSelector((state) => state.screenlines.showScreenlines);
  const screenlinesValidationSummaryData = useAppSelector(
    (state) => state.screenlines.screenlinesValidationSummary.data,
  );
  const screenlinesValidationSummaryMaxSeverity = useAppSelector(selectScreenlinesValidationSummaryMaxSeverity);

  const screenlinesValidationSummaryMessage = useMemo(() => {
    if (! timePeriod || !screenlinesValidationSummaryData) {
      return null;
    }

    let withUnresolved = 0;
    let withMinorDifferences = 0;
    screenlinesValidationSummaryData.forEach((s) => {
      if (s.summary.unresolvedIntersectionCount > 0) {
        withUnresolved += 1;
      }
      if (s.summary.messages.length) {
        withMinorDifferences += 1;
      }
    });

    const messages = [];
    if (withUnresolved > 0) {
      messages.push(summaryValidationMessage(withUnresolved, true));
    }
    if (withMinorDifferences > 0) {
      messages.push(summaryValidationMessage(withMinorDifferences, false));
    }
    return messages;
  }, [screenlinesValidationSummaryData, timePeriod]);

  const [screenlineWidth, setScreenlineWidth] = useState(1);
  const [opacityFactor, setOpacityFactor] = useState(1);

  const handleToggleScreenlineEditor = () => {
    if (!arePendingChanges || window.confirm("Changes that you made may not be saved."))
      dispatch(screenlinesActions.setScreelineEditorOpen(!isScreenlinesEditorOpen));
  };

  const handleChangeScreenlineWidth = (newValue: number | number[]) => {
    setScreenlineWidth(newValue as number);
  };

  const handleChangeLineOpacity = (newValue: number) => {
    setOpacityFactor(newValue);
    changeScreenlinesOpacity(newValue);
  };

  useEffect(() => {
    if (map.current) {
      const widthFactor = calculateScreenlineWidthFactor(screenlineWidth) * SCREENLINE_WIDTH_FACTOR;
      setScreenlineLayersWidthFactor(map.current, widthFactor, screenlinesMaxCount || 1);
    }
  }, [map, screenlineWidth, screenlinesMaxCount]);

  useEffect(() => {
    if (map.current) {
      setScreenlineLayersRoadClasses(map.current, roadClasses);
    }
  }, [map, roadClasses]);

  return (
    <>
      <MapControlContainer
        title="Screenlines"
        primaryAction={<VisibilityIconButton visible={showScreenlines} onClick={changeShowScreenlines} />}
        secondaryAction={<EditorToggleControl open={isScreenlinesEditorOpen} onClick={handleToggleScreenlineEditor} />}
        collapse
        expanded={expandedMapLayers.includes("screenlines")}
        onChange={() => onChange("screenlines")}
        icon={
          screenlinesValidationSummaryMaxSeverity && (
            <ValidationMessagesTooltip title={
              <Box paddingLeft={2}>
                <ul>
                  {screenlinesValidationSummaryMessage?.map((msg, index) => (
                    <Typography key={index} fontSize={11} component={"li"}>{msg}</Typography>
                  ))}
                </ul>
              </Box>
            }>
              <SeverityIcon
                severity={screenlinesValidationSummaryMaxSeverity as ValidationMessageSeverity}
                fontSize="inherit"
              />
            </ValidationMessagesTooltip>
          )
        }
      >
        <Box padding={1}>
          <SliderControl
            label="Opacity"
            value={opacityFactor}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => handleChangeLineOpacity(value as number)}
          />
          <SliderControl
            label="Width"
            disabled={loading}
            value={screenlineWidth}
            min={0}
            max={2}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            scale={calculateScreenlineWidthFactor}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            onChange={(e, value) => handleChangeScreenlineWidth(value)}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
