import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import { Icon, IconProps } from "@mui/material";
import { forwardRef } from "react";

import { ValidationMessageSeverity } from "types";

export interface SeverityIconProps extends IconProps {
  severity: ValidationMessageSeverity | undefined;
}

const getIconProps = (severity: ValidationMessageSeverity | undefined) => {
  switch (severity) {
    case ValidationMessageSeverity.Error:
      return { component: ErrorOutlineOutlined, color: "error" };
    case ValidationMessageSeverity.Warning:
      return { component: WarningAmberRounded, color: "warning" };
    case ValidationMessageSeverity.Info:
      return { component: InfoOutlined, color: "info" };
    default:
      return { component: CheckCircleOutlineOutlined, color: "success" };
  }
};

export const SeverityIcon = forwardRef<SVGSVGElement, SeverityIconProps>(({ severity, ...props }, ref) => {
  const { component, color } = getIconProps(severity);

  return <Icon ref={ref} component={component} color={color as any} {...props} />;
});
