import { useState } from "react";

import { reportAboutErrorState } from "utils/reports";

export function useFetch<T>(
  url: string,
  options?: RequestInit,
  condition: boolean = true,
  transformFunction?: (data: any) => T,
  callback?: (data: T) => void,
) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  (async () => {
    if (Boolean(data) || loading || Boolean(error) || !condition) return;

    setError(null);
    setLoading(true);

    try {
      const token = sessionStorage.getItem("accessToken");

      if (!token) {
        throw new Error("No access token found");
      }

      const response = await fetch(process.env.REACT_APP_API_HOST + url, {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching from ${url}: ${response.statusText}`);
      }

      const data = await response.json();

      const transformedData = transformFunction ? transformFunction(data) : data;
      setData(transformedData);

      if (callback) {
        callback(transformedData);
      }

      setLoading(false);
    } catch (error: any) {
      reportAboutErrorState(error, `Error fetching from ${url}`);

      setError(error?.body?.what ?? `Error fetching from ${url}`);
      setLoading(false);
    }
  })();

  return [data, loading, error, setData, setError] as const;
}
