import { ArrowForwardIosSharp, ThumbUpAltOutlined } from "@mui/icons-material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { SeverityIcon } from "components_new";
import React, { useState } from "react";

import { ValidationMessage, ValidationMessageDetail, ValidationMessageSeverity } from "types";

import { capitalize } from "utils/format";

interface Props {
  validationMessages: ValidationMessage[];
  selectedIssue: ValidationMessageDetail | null;
  handleSelectIssue: (severity: ValidationMessageSeverity, issue: ValidationMessageDetail) => void;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
  ({ theme }) => ({
    marginTop: `${theme.spacing(1)} !important`,
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }),
);

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderRadius: 4,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export const ValidationTab = ({ validationMessages, selectedIssue, handleSelectIssue }: Props) => {
  const [activeMessage, setActiveMessage] = useState<number | undefined>(undefined);

  return (
    <>
      {validationMessages.length ? (
        validationMessages.map((message, index) => (
          <Accordion
            key={index}
            expanded={activeMessage === index}
            onChange={() =>
              message?.details?.length ? setActiveMessage(index === activeMessage ? undefined : index) : undefined
            }
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={message?.details?.length ? <ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} /> : null}
            >
              <Typography variant="subtitle2">
                <SeverityIcon severity={message.severity} sx={{ marginBottom: "-2.5px", fontSize: "inherit" }} />{" "}
                {capitalize(message.severity)}:{" "}
                <Typography variant="body2" component="span">
                  {message.text}
                </Typography>
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {message.details?.length && message.details.length > 0 && (
                <List dense sx={{ maxHeight: 165, overflowY: "auto" }}>
                  {message.details.map((detail) => (
                    <ListItemButton
                      selected={selectedIssue?.id === detail.id}
                      disableRipple
                      key={detail.id}
                      sx={{ fontSize: 12 }}
                      onClick={() => handleSelectIssue(message.severity, detail)}
                    >
                      {detail.text}
                    </ListItemButton>
                  ))}
                </List>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Grid container justifyContent={"center"} alignItems={"center"} height={220}>
          <ThumbUpAltOutlined color="secondary" /> <Typography marginLeft={1}>Validation successful</Typography>
        </Grid>
      )}
    </>
  );
};
