import { startSpan } from "@sentry/react";

import { reportAboutCustomEventInfo, reportAboutErrorState } from "utils/reports";

const ONE_MINUTE_IN_MS = 60 * 1000;

function getBodyOrThrow<T>(res: Response, duration: number, suppressEvents?: boolean): Promise<T> {
  const responseInfo = {
    duration,
    url: res?.url,
    status: res?.status,
    statusText: res?.statusText,
    headers: res?.headers,
    type: res?.type,
    redirected: res?.redirected,
  };

  if (duration > ONE_MINUTE_IN_MS && !suppressEvents) {
    reportAboutCustomEventInfo("Long response time", responseInfo);
  }

  if (!res.ok) {
    if (!suppressEvents) {
      reportAboutErrorState(responseInfo, `Invalid response status: ${res.status}`);
    }

    if (res.status === 413) {
      return Promise.reject({ status: res.status, body: { what: "File size is too large" } });
    } else if (res.status >= 500 && res.status < 600) {
      return Promise.reject({ status: res.status, body: { what: "An error occurred, please try again later" } });
    }

    return res.json().then((err) => {
      return Promise.reject({ status: res.status, body: err });
    });
  }

  return res.json();
}

function getCSVBodyOrThrow(res: Response, duration: number): Promise<unknown> {
  const responseInfo = {
    duration,
    url: res?.url,
    status: res?.status,
    statusText: res?.statusText,
    headers: res?.headers,
    type: res?.type,
    redirected: res?.redirected,
  };

  if (duration > ONE_MINUTE_IN_MS) {
    reportAboutCustomEventInfo("Long response time", responseInfo);
  }

  if (!res.ok) {
    reportAboutErrorState(responseInfo, `Invalid response status: ${res.status}`);

    throw new Error(`invalid response status: ${res.status}`);
  }

  return res.text();
}

function getAccessToken(): string {
  // TBD: Throw an error if no accessToken or send to login
  return sessionStorage.getItem("accessToken") ?? "";
}

function getLicensedAreaId(): string {
  return sessionStorage.getItem("licensedAreaId") ?? "";
}

function writeAuthHeader(token: string, init: RequestInit): void {
  if (!init.headers) {
    init.headers = new Headers({ Authorization: token });
  } else if (init.headers instanceof Headers) {
    init.headers.set("Authorization", token);
  } else if (Array.isArray(init.headers)) {
    init.headers.push(["Authorization", token]);
  } else {
    init.headers.Authorization = token;
  }
}

function writeLicensedAreaIdHeader(licensedAreaId: string, init: RequestInit): void {
  if (!init.headers) {
    init.headers = new Headers({ "licensed-area-id": licensedAreaId });
  } else if (init.headers instanceof Headers) {
    init.headers.set("licensed-area-id", licensedAreaId);
  } else if (Array.isArray(init.headers)) {
    init.headers.push(["licensed-area-id", licensedAreaId]);
  } else {
    init.headers["licensed-area-id"] = licensedAreaId;
  }
}

function isNotAllowedOrigin(url: string): boolean {
  const allowedOrigins = [
    process.env.REACT_APP_AUTH0_DOMAIN,
    process.env.REACT_APP_AUTH0_HOST,
    process.env.REACT_APP_API_HOST,
    process.env.REACT_APP_REDIRECT_URI,
    process.env.REACT_APP_ANALYTICS_API_HOST,
    process.env.REACT_APP_LICENSE_API_HOST
  ];

  return allowedOrigins.indexOf(url) === -1;
}

export default class RestHandler {
  constructor(private readonly apiPath?: string) {
    this.apiPath = apiPath ? `${apiPath + "/"}` : "";
  }

  fetchWithToken(path: string, requestInit: RequestInit = {}): Promise<Response> {
    const token = getAccessToken();
    const licensedAreaId = getLicensedAreaId();
    const url = new URL(path);

    if (isNotAllowedOrigin(url.origin)) {
      throw new Error("Invalid request destination");
    }

    if (token) {
      const bearerToken = `Bearer ${token}`;
      writeAuthHeader(bearerToken, requestInit);

      if (licensedAreaId) {
        writeLicensedAreaIdHeader(licensedAreaId, requestInit);
      }
    }

    return fetch(url.toString(), requestInit);
  }

  private async makeRequest(path: string, init?: RequestInit) {
    return await startSpan({ op: "http.client", name: `${init?.method} ${path}` }, async (span) => {
      if (!span) {
        return this.fetchWithToken(path, init);
      }

      span.setAttribute("http.request.method", init?.method);
      span.setAttribute("server.address", `${this.apiPath}`);

      const response = await this.fetchWithToken(path, init);

      span.setAttribute("http.response.status_code", response.status);
      span.setAttribute("http.response_content_length", Number(response.headers.get("content-length")));

      span.end();

      return response;
    });
  }

  private async fetchBody<T>(path: string, init?: RequestInit, suppressEvents?: boolean): Promise<T> {
    const startTime = performance.now();
    const res = await this.makeRequest(`${this.apiPath}${path}`, init);
    const endTime = performance.now();

    return getBodyOrThrow(res, endTime - startTime, suppressEvents);
  }

  private async fetchCSVBody(path: string, init?: RequestInit): Promise<unknown> {
    const startTime = performance.now();
    const res = await this.makeRequest(`${this.apiPath}${path}`, init);
    const endTime = performance.now();

    return getCSVBodyOrThrow(res, endTime - startTime);
  }

  private async fetchBodyWithPayload<T>(
    path: string,
    init: RequestInit,
    payload: T,
    isCSV: boolean = false,
    suppressEvents?: boolean,
  ): Promise<unknown> {
    const options: any = { ...init };

    if (payload instanceof FormData || payload instanceof Blob) {
      options.body = payload;
    } else {
      options.headers = {
        "Content-Type": "application/json",
      };
      options.body = JSON.stringify(payload);
    }

    return isCSV ? this.fetchCSVBody(path, options) : this.fetchBody(path, options, suppressEvents);
  }

  get<T>(path: string, init?: RequestInit, suppressEvents = false): Promise<T> {
    return this.fetchBody(path, { ...init, method: "GET" }, suppressEvents);
  }

  delete(path: string, init?: RequestInit): Promise<unknown> {
    return this.fetchBody(path, { ...init, method: "DELETE" });
  }

  put<T>(path: string, body: T, init?: RequestInit): Promise<unknown> {
    return this.fetchBodyWithPayload<T>(path, { ...init, method: "PUT" }, body);
  }

  post<T>(path: string, body: T, init?: RequestInit, suppressEvents = false): Promise<unknown> {
    return this.fetchBodyWithPayload<T>(path, { ...init, method: "POST" }, body, false, suppressEvents);
  }

  postForCSV<T>(path: string, body: T, init?: RequestInit): Promise<unknown> {
    return this.fetchBodyWithPayload<T>(path, { ...init, method: "POST" }, body, true);
  }

  async postForBinary<T>(path: string, body: T): Promise<any> {
    return await startSpan({ op: "http.client", name: `POST ${path}` }, async (span) => {
      const token = getAccessToken();
      const licensedAreaId = getLicensedAreaId();
      const pathLink = `${this.apiPath}${path}`;

      span?.setAttribute("http.request.method", "POST");
      span?.setAttribute("server.address", `${this.apiPath}`);
      span?.setAttribute("http.request.body", JSON.stringify({ body, areaOfInterest: "n / a" }));

      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", pathLink);
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.setRequestHeader("licensed-area-id", licensedAreaId);
        xhr.responseType = "arraybuffer";
        xhr.setRequestHeader("Content-type", "text/plain;charset=UTF-8");
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState !== 4) {
            return;
          }

          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reportAboutErrorState(
              {
                body: xhr?.response,
                statusText: xhr?.statusText,
                status: xhr?.status,
                headers: xhr?.getAllResponseHeaders(),
                type: xhr?.responseType,
              },
              `Invalid response status: ${xhr.status}`,
            );

            reject(`invalid response status: ${xhr.status}`);
          }

          span?.setAttribute("http.response.status_code", xhr.status);
          span?.end();
        };
        xhr.onerror = reject;
        xhr.send(JSON.stringify({ ...body, format: "protobuf" }));
      });
    });
  }
}
