import {
  Box,
  Grid,
  Paper,
  PaperProps,
  TableCellProps,
  TableContainer,
  Typography,
  TypographyProps,
  alpha,
  styled,
} from "@mui/material";
import { CircularProgress } from "components_new";
import { FC, useMemo } from "react";

import { MapErrorPage } from "components";

import { HeadTableCell, Table, TableBody, TableCell, TableHead, TableRow } from "components/pages/docs/Table";

import { AreaAccuracyTableItem } from "types";

import ACV from "./AVC-formula.png";
import { InfoPopover } from "./InfoPopover";
import MAD from "./MAD-formula.png";
import MAPE from "./MAPE-formula.png";
import MSD from "./MSD-formula.png";
import RMSE from "./RMSE-formula.png";
import RMSEP from "./RMSEP-formula.png";

export interface AreaAccuracyTableProps {
  data: AreaAccuracyTableItem[] | null;
  loading?: boolean;
  error?: boolean;
}

const TablePaper = styled((props: PaperProps) => <Paper variant="outlined" {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  minHeight: "300px",
  height: "300px",
}));

const StyledHeadTableCell = styled(HeadTableCell)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  minWidth: "130px",
  maxWidth: "150px",
  lineHeight: "1.5",
}));

const PercentageBarOverlay = styled("div", {
  shouldForwardProp: (prop) => prop !== "percentageBarValue",
})<{ percentageBarValue: number }>(({ theme, percentageBarValue }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: `${percentageBarValue}%`,
  height: "100%",
  backgroundColor: `${alpha(theme.palette.info.main, 0.1)} !important`,
}));

const PercentageBarTableCell = ({
  percentageBarValue,
  children,
  ...props
}: TableCellProps & { percentageBarValue: number | null }) => (
  <TableCell {...props}>
    <PercentageBarOverlay percentageBarValue={percentageBarValue || 0} />
    {children}
  </TableCell>
);

const PositiveValueOverlay = styled("div")<{ width: number; value: number }>(({ theme, width, value }) => ({
  position: "absolute",
  top: 0,
  right: `${width}%`,
  left: 0,
  width: `${value}%`,
  height: "100%",
  backgroundColor: `${alpha(theme.palette.success.main, 0.1)} !important`,
}));

const NegativeValueOverlay = styled("div")<{ width: number; value: number }>(({ theme, width, value }) => ({
  position: "absolute",
  top: 0,
  left: `-calc(${width}%)`,
  right: 0,
  width: `${value}%`,
  height: "100%",
  backgroundColor: `${alpha(theme.palette.error.main, 0.08)} !important`,
}));

const ValueTableCall = ({
  children,
  value,
  limits,
  ...props
}: TableCellProps & { value: number; limits: { min: number; max: number } }) => {
  const isNegative = Number(value) < 0;
  const total = limits.max + Math.abs(limits.min);
  const negativeWidth = Math.abs(limits.min / total) * 100;
  const positiveWidth = Math.abs(limits.max / total) * 100;
  const valuePercentage = Math.abs(Number(value) / total) * 100;
  const positiveValue = isNegative ? 0 : valuePercentage;
  const negativeValue = isNegative ? valuePercentage : 0;

  return (
    <TableCell {...props}>
      <NegativeValueOverlay width={negativeWidth} value={negativeValue} />
      <PositiveValueOverlay width={positiveWidth} value={positiveValue} />
      {children}
    </TableCell>
  );
};

const InfoPopoverHeader = styled((props: TypographyProps) => (
  <Typography fontSize={12} fontWeight={700} gutterBottom {...props} />
))``;

const InfoPopoverContent = styled((props: TypographyProps) => (
  <Typography fontSize={11} color="textSecondary" maxWidth={450} {...props} />
))``;

const FormulaImage = styled("img")(({ theme }) => ({
  margin: theme.spacing(1),
}));

const getLimits = (
  data: AreaAccuracyTableItem[] | null,
  rowKey: keyof Omit<AreaAccuracyTableItem, "aadtGroup" | "tooFewSegmentsWithCounters">,
) => {
  if (!data) {
    return { min: 0, max: 0 };
  }

  return data.reduce(
    (acc, row) => {
      const value = row[rowKey] || 0;
      if (value > acc.max) {
        acc.max = value;
      }
      if (value < acc.min) {
        acc.min = value;
      }
      return acc;
    },
    { min: 0, max: 0 },
  );
};

const headData = [
  {
    label: "AADT Group",
    content: undefined,
  },
  {
    label: "Number of segments with counter",
    content: undefined,
  },
  {
    label: "MaxDev%",
    content: (
      <Box padding={1}>
        <InfoPopoverHeader>Percentage within maximum desirable deviation</InfoPopoverHeader>
        <InfoPopoverContent>
          <Box>
            This represents the percentage of count sites where the volumes are within a maximum desirable deviation.
            A value of 95 indicates that 95% of volumes at count locations are within the preferred deviation range.
          </Box>
          <Box paddingTop={0.5}>
            The <b>National Cooperative Highway Research Program Report 255: Highway Traffic Data for Urbanized Area Project Planning
              and Design</b> introduced maximum desirable deviation curves which defined the preferred maximum percent deviation of
            volumes and counts based on the magnitude of observed traffic counts:
          </Box>
          <Box component={"ul"} paddingLeft={2} paddingTop={0.5}>
            <li>As the magnitude of counts increases, the preferred percent deviation decreases.</li>
            <li>The curves logically permit higher percent deviations for small counts, where large percent deviations are small absolute differences.</li>
            <li>Lower percent deviations are logically preferred for high count locations because the absolute differences are larger.</li>
            <li>Even at the highest volumes, however, some deviation is reasonable since there are statistical errors and variabilities in traffic counts.</li>
          </Box>
        </InfoPopoverContent>
      </Box>
    ),
  },
  {
    label: "RMSE",
    content: (
      <Box padding={1}>
        <InfoPopoverHeader>Root mean squared error</InfoPopoverHeader>
        <FormulaImage src={RMSE} alt="RMSE formula" />
        <InfoPopoverContent>
          The Root Mean Squared Error (RMSE) measures the average difference between volumes and observed traffic
          counts, while removing the cancelling potential of positive and negative differences. It calculates the
          average of the squared differences, then takes the square root to get back to the original units of the data.
        </InfoPopoverContent>
      </Box>
    ),
  },
  {
    label: "RMSE%",
    content: (
      <Box padding={1}>
        <InfoPopoverHeader>Root mean squared error percentage</InfoPopoverHeader>
        <FormulaImage src={RMSEP} alt="RMSEP formula" />
        <InfoPopoverContent>
          The percent root mean squared error (RMSE) measures the average percent difference between observed traffic
          counts and volumes. This takes the RMSE and expresses it as a percentage of the average count. This makes it
          easier to understand how large the errors are relative to the size of the data. A percent RMSE of 10% means
          that, on average, volumes are 10% different than the average count.
        </InfoPopoverContent>
      </Box>
    ),
  },
  {
    label: "MSD",
    content: (
      <Box padding={1}>
        <InfoPopoverHeader>Mean signed difference</InfoPopoverHeader>
        <FormulaImage src={MSD} alt="MSD formula" />
        <Typography fontSize={11} color="textSecondary" maxWidth={450}>
          The mean signed difference (MSD) measures the average difference between volumes and counts, with the focus of
          identifying if the differences are biased (i.e., high or low). Unbiased results are closer to zero.
        </Typography>
      </Box>
    ),
  },
  {
    label: "MAD",
    content: (
      <Box padding={1}>
        <InfoPopoverHeader>Mean absolute difference</InfoPopoverHeader>
        <FormulaImage src={MAD} alt="MAD formula" />
        <InfoPopoverContent>
          The mean absolute difference (MAD) measures, on average, how much volumes differ from counts, without giving
          extra weight to larger differences. It calculates the absolute value of differences and then the average.
        </InfoPopoverContent>
      </Box>
    ),
  },
  {
    label: "MAPE",
    content: (
      <Box padding={1}>
        <InfoPopoverHeader>Mean absolute percentage error</InfoPopoverHeader>
        <FormulaImage src={MAPE} alt="MAPE formula" />
        <InfoPopoverContent>
          The mean absolute percentage error (MAPE) measures, on average, the difference between volumes and counts,
          expressed as a percentage. If the MAPE is 5%, on average, volumes are 5% different from counts.
        </InfoPopoverContent>
      </Box>
    ),
  },
  {
    label: "ACV",
    content: (
      <Box padding={1}>
        <InfoPopoverHeader>Average coefficent of variation</InfoPopoverHeader>
        <FormulaImage src={ACV} alt="ACV formula" />
        <InfoPopoverContent>
          The average coefficient of variation (CV) measures the statistical similarity or dissimilarity of volumes and
          counts. It calculates the standard deviation of each count pair divided by the mean of each pair, then the
          average of these values expressed as a percentage. The smaller the value, the more similar the volumes are to
          counts.
        </InfoPopoverContent>
      </Box>
    ),
  },
];

export const AreaAccuracyTable: FC<AreaAccuracyTableProps> = ({ data, loading, error }) => {
  const numberOfSegmentsWithCountersLimits = useMemo(() => getLimits(data, "numberOfSegmentsWithCounters"), [data]);
  const rmseLimits = useMemo(() => getLimits(data, "rmse"), [data]);
  const msdLimits = useMemo(() => getLimits(data, "msd"), [data]);
  const madLimits = useMemo(() => getLimits(data, "mad"), [data]);

  return (
    <TableContainer component={TablePaper}>
      {error ? (
        <Grid container height={"100%"}>
          <MapErrorPage size="sm" />
        </Grid>
      ) : loading ? (
        <Grid container alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <CircularProgress />
        </Grid>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {headData.map((head) => (
                <StyledHeadTableCell key={head.label} align="left">
                  <Grid container alignItems={"center"}>
                    <Grid item xs={head.content ? 10 : 12}>
                      <Typography fontSize={12} fontWeight={500}>
                        {head.label}
                      </Typography>
                    </Grid>
                    {head.content && (
                      <Grid item xs={2}>
                        <InfoPopover>{head.content}</InfoPopover>
                      </Grid>
                    )}
                  </Grid>
                </StyledHeadTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.aadtGroup}
                sx={(theme) => ({
                  "& :not(td:first-of-type)": {
                    backgroundColor: row.tooFewSegmentsWithCounters
                      ? alpha(theme.palette.text.disabled, 0.05)
                      : theme.palette.common.white,
                  },
                })}
              >
                <StyledHeadTableCell align="left">{row.aadtGroup}</StyledHeadTableCell>
                <ValueTableCall
                  value={row.numberOfSegmentsWithCounters ?? 0}
                  limits={numberOfSegmentsWithCountersLimits}
                >
                  {row.numberOfSegmentsWithCounters ?? 0}
                </ValueTableCall>
                <PercentageBarTableCell percentageBarValue={row.maxDeviationPercent}>
                  {row.maxDeviationPercent ?? "*"}
                </PercentageBarTableCell>
                <ValueTableCall value={row.rmse ?? 0} limits={rmseLimits}>
                  {row.rmse ?? "*"}
                </ValueTableCall>
                <PercentageBarTableCell percentageBarValue={row.rmsePercent}>
                  {row.rmsePercent ?? "*"}
                </PercentageBarTableCell>
                <ValueTableCall value={row.msd ?? 0} limits={msdLimits}>
                  {row.msd ?? "*"}
                </ValueTableCall>
                <ValueTableCall value={row.mad ?? 0} limits={madLimits}>
                  {row.mad ?? "*"}
                </ValueTableCall>
                <PercentageBarTableCell percentageBarValue={row.mape}>{row.mape ?? "*"}</PercentageBarTableCell>
                <PercentageBarTableCell percentageBarValue={row.acv}>{row.acv ?? "*"}</PercentageBarTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
