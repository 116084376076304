import { Paper, PaperProps, TableContainer, styled } from "@mui/material";
import { TextField } from "components_new";
import React, { ChangeEvent, FC, useState } from "react";

import { FooterTableCell, HeadTableCell, Table, TableBody, TableCell, TableHead, TableRow } from "./Table";

export interface DocsTableProps {
  data: Object[];
}

const StyledPaper = styled((props: PaperProps) => <Paper variant={"outlined"} {...props} />)`
  max-width: 1200px;
  margin: 0 auto;
`;

const SelectContainer = styled("div")`
  max-width: 250px;
  margin: 1rem 0;
`;

const renderHeader = (data: Object[]) =>
  data.length ? (
    <TableHead>
      <TableRow>
        {Object.keys(data[0]).map((key, i) => (
          <HeadTableCell key={i} width={"33%"}>
            {key}
          </HeadTableCell>
        ))}
      </TableRow>
    </TableHead>
  ) : null;

export const DocsTable: FC<DocsTableProps> = ({ data }) => {
  const [items, setItems] = useState(data);
  const [selectedItem, setSelectedItem] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const filteredItems = data.filter((item) => {
      const values = Object.values(item);
      return values[0].toLowerCase().includes(value);
    });
    setItems(filteredItems);
    setSelectedItem(value);
  };

  return (
    <div>
      {data.length > 20 && (
        <SelectContainer>
          <TextField placeholder="Search.." value={selectedItem} onChange={handleChange} />
        </SelectContainer>
      )}
      <TableContainer component={StyledPaper}>
        <Table>
          {renderHeader(data)}
          <TableBody>
            {items.map((obj, i) => (
              <TableRow key={i}>
                {Object.values(obj).map((v, i) => (
                  <TableCell key={i}>{v}</TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <FooterTableCell colSpan={Object.keys(data?.[0] || {}).length}>&nbsp;</FooterTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
