import { styled } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { AnalyticMap } from "features/map/AnalyticMap";
import { LoadingSpinnerWrapper } from "features/map/LoadingSpinnerWrapper";
import { ModuleManager } from "features/map/ModuleManager";
import {
  IntersectionPopupContent,
  IntersectionPopupContentProps,
  ScreenlineGeometryPopupContent,
  ScreenlineGeometryPopupContentProps,
  ScreenlinePopupContent,
  ScreenlinePopupContentProps,
} from "features/screenline";

import {
  MapErrorPage,
  ODPopup,
  ODPopupProps,
  PopupWrapper,
  RoadsHoverPopup,
  RoadsHoverPopupProps,
  VolumePopupContent,
  VolumePopupContentProps,
} from "components";

import { useAppSelector, usePageTracking, useResetMap } from "hooks";

import { DataState } from "store/interfaces";

import { reportAboutErrorState } from "utils/reports";

const MapPageContainer = styled("div")`
  position: relative;
  display: grid;
  grid-template-columns: 290px 1fr;
  grid-template-rows: 100%;
  grid-template-areas: "left-sidebar map";
  height: 100%;
  overflow: hidden;
`;

export const MapPage = () => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [volumesLoading, setVolumesLoading] = useState(false);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  const focusAreasState = useAppSelector((state) => state.analytics.focusAreasAndDatasets.state);

  const ODMetadataState = useAppSelector((state) => state.analytics.ODMetadata.state);
  const ODMetadataError = useAppSelector((state) => state.analytics.ODMetadata.error);
  const ODIdsState = useAppSelector((state) => state.analytics.ODIds.state);
  const ODCountsState = useAppSelector((state) => state.analytics.ODCounts.state);

  const datasetMetadataState = useAppSelector((state) => state.analytics.datasetMetadata.state);
  const datasetCountsState = useAppSelector((state) => state.analytics.datasetCounts.state);
  const datasetIdsState = useAppSelector((state) => state.analytics.datasetIds.state);
  const datasetCountsByZoneIdState = useAppSelector((state) => state.analytics.datasetCountsByZoneId.state);
  const datasetGatesState = useAppSelector((state) => state.analytics.datasetGates.state);

  const zoneDetailsState = useAppSelector((state) => state.analytics.zoneDetails.state);

  const roadsMetadataState = useAppSelector((state) => state.analytics.roadsMetadata.state);
  const roadsMetadataError = useAppSelector((state) => state.analytics.roadsMetadata.error);
  const roadSegmentIndexesState = useAppSelector((state) => state.analytics.roadSegmentIndexes.state);
  const roadsVolumesState = useAppSelector((state) => state.analytics.roadsVolumes.state);
  const roadSegmentsDetailsState = useAppSelector((state) => state.analytics.roadSegmentsDetails.state);
  const roadSegmentsFeatureDetailsState = useAppSelector((state) => state.analytics.roadSegmentsFeatureDetails.state);

  const map = useRef(null);
  const draw = useRef(null);

  const ODPopupRef = useRef<HTMLDivElement>(null);
  const setODPopupPropsRef = useRef<Dispatch<SetStateAction<ODPopupProps | null>>>(null);
  const roadsPopupRef = useRef<HTMLDivElement>(null);
  const setRoadsPopupRef = useRef<Dispatch<SetStateAction<RoadsHoverPopupProps | null>>>(null);
  const roadsVolumesPopupRef = useRef<HTMLDivElement>(null);
  const setRoadsVolumesPopupRef = useRef<Dispatch<SetStateAction<VolumePopupContentProps | null>>>(null);
  const screenlinePopupRef = useRef<HTMLDivElement>(null);
  const setScreenlinePopupPropsRef = useRef<Dispatch<SetStateAction<ScreenlinePopupContentProps | null>>>(null);
  const intersectionPopupRef = useRef<HTMLDivElement>(null);
  const setIntersectionPopupPropsRef = useRef<Dispatch<SetStateAction<IntersectionPopupContentProps | null>>>(null);
  const screenlinGeometryPopupRef = useRef<HTMLDivElement>(null);
  const setScreenlineGeometryPopupPropsRef =
    useRef<Dispatch<SetStateAction<ScreenlineGeometryPopupContentProps | null>>>(null);

  const ODError = useMemo(
    () =>
      (ODMetadataState === DataState.ERROR && ODMetadataError && ODMetadataError.status !== 403) ||
      ODIdsState === DataState.ERROR ||
      ODCountsState === DataState.ERROR,
    [ODMetadataState, ODMetadataError, ODIdsState, ODCountsState],
  );

  const datasetError = useMemo(
    () =>
      datasetMetadataState === DataState.ERROR ||
      datasetCountsState === DataState.ERROR ||
      datasetIdsState === DataState.ERROR ||
      datasetCountsByZoneIdState === DataState.ERROR ||
      datasetGatesState === DataState.ERROR,
    [datasetMetadataState, datasetCountsState, datasetIdsState, datasetCountsByZoneIdState, datasetGatesState],
  );

  const topFlowsError = useMemo(() => zoneDetailsState === DataState.ERROR, [zoneDetailsState]);

  const roadsError = useMemo(
    () =>
      (roadsMetadataState === DataState.ERROR && roadsMetadataError && roadsMetadataError.status !== 403) ||
      roadSegmentIndexesState === DataState.ERROR ||
      roadSegmentsDetailsState === DataState.ERROR ||
      roadSegmentsFeatureDetailsState === DataState.ERROR ||
      roadsVolumesState === DataState.ERROR,
    [
      roadsMetadataState,
      roadsMetadataError,
      roadSegmentIndexesState,
      roadsVolumesState,
      roadSegmentsDetailsState,
      roadSegmentsFeatureDetailsState,
    ],
  );

  const mapError = useMemo(
    () => focusAreasState === DataState.ERROR || datasetError || ODError || roadsError || topFlowsError,
    [focusAreasState, datasetError, ODError, roadsError, topFlowsError],
  );

  usePageTracking();

  // Catching errors
  useEffect(() => {
    if (mapError) {
      reportAboutErrorState(
        {
          extraData: `Error statutes: focus areas: ${
            focusAreasState === DataState.ERROR
          }, dataset: ${datasetError}, OD: ${ODError}, roads: ${roadsError}, top flows: ${topFlowsError}`,
        },
        "The map failed to show the data",
      );
    }
  }, [mapError]); // eslint-disable-line react-hooks/exhaustive-deps

  useResetMap(map, mapLoaded, setMapLoaded, [selectedFocusAreaId, baseMapStyle]);

  return (
    <>
      {!mapError && (
        <MapPageContainer>
          <AnalyticMap map={map} draw={draw} mapLoaded={mapLoaded} onMapLoaded={setMapLoaded}>
            <PopupWrapper
              popupRef={ODPopupRef}
              setPopupRef={setODPopupPropsRef}
              renderPopupContent={(ODPopupProps) => (
                <ODPopup
                  count={ODPopupProps.count}
                  type={ODPopupProps.type}
                  countByDensity={ODPopupProps.countByDensity}
                  gateId={ODPopupProps.gateId}
                  flowInfo={ODPopupProps.flowInfo}
                />
              )}
            />
            <PopupWrapper
              popupRef={roadsPopupRef}
              setPopupRef={setRoadsPopupRef}
              renderPopupContent={(roadsPopupProps) => (
                <RoadsHoverPopup
                  volume={roadsPopupProps.volume}
                  gateId={roadsPopupProps.gateId}
                  isPedestriansMode={roadsPopupProps.isPedestriansMode}
                  loading={volumesLoading}
                />
              )}
            />
            <PopupWrapper
              popupRef={roadsVolumesPopupRef}
              setPopupRef={setRoadsVolumesPopupRef}
              renderPopupContent={(volumePopupProps) => (
                <VolumePopupContent
                  selectedVolume={volumePopupProps.selectedVolume}
                  volume={volumePopupProps.volume}
                  onHover={volumePopupProps.onHover}
                  onClick={volumePopupProps.onClick}
                  isPedestriansMode={volumePopupProps.isPedestriansMode}
                />
              )}
            />
            <PopupWrapper
              popupRef={screenlinePopupRef}
              setPopupRef={setScreenlinePopupPropsRef}
              renderPopupContent={({
                toLeft,
                toRight,
                leftLabel,
                rightLabel,
                segmentIntersectionsLength,
                totalSegmentIntersections,
                intersectioDirectionFilter,
              }) => (
                <ScreenlinePopupContent
                  toLeft={toLeft}
                  toRight={toRight}
                  leftLabel={leftLabel}
                  rightLabel={rightLabel}
                  segmentIntersectionsLength={segmentIntersectionsLength}
                  totalSegmentIntersections={totalSegmentIntersections}
                  intersectioDirectionFilter={intersectioDirectionFilter}
                />
              )}
            />
            <PopupWrapper
              popupRef={intersectionPopupRef}
              setPopupRef={setIntersectionPopupPropsRef}
              renderPopupContent={({ featuresProps }) => <IntersectionPopupContent featuresProps={featuresProps} />}
            />
            <PopupWrapper
              popupRef={screenlinGeometryPopupRef}
              setPopupRef={setScreenlineGeometryPopupPropsRef}
              renderPopupContent={({ onClose }) => <ScreenlineGeometryPopupContent draw={draw} onClose={onClose} />}
            />
          </AnalyticMap>

          {mapLoaded ? (
            <ModuleManager
              map={map}
              draw={draw}
              ODPopupRef={ODPopupRef}
              setODPopupPropsRef={setODPopupPropsRef}
              roadsPopupRef={roadsPopupRef}
              setRoadsPopupRef={setRoadsPopupRef}
              roadsVolumesPopupRef={roadsVolumesPopupRef}
              setRoadsVolumesPopupRef={setRoadsVolumesPopupRef}
              screenlinePopupRef={screenlinePopupRef}
              setScreenlinePopupPropsRef={setScreenlinePopupPropsRef}
              intersectionPopupRef={intersectionPopupRef}
              setIntersectionPopupPropsRef={setIntersectionPopupPropsRef}
              screenlinGeometryPopupRef={screenlinGeometryPopupRef}
              setScreenlineGeometryPopupPropsRef={setScreenlineGeometryPopupPropsRef}
              setVolumesLoading={setVolumesLoading}
            />
          ) : null}
          <LoadingSpinnerWrapper loading={volumesLoading} />
        </MapPageContainer>
      )}
      {mapError && <MapErrorPage />}
    </>
  );
};
