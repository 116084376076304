import { bbox } from "@turf/turf";
import { Geometry } from "geojson";
import mapboxgl, { LngLatBounds, MapboxGeoJSONFeature, PaddingOptions, Popup, PromoteIdSpecification } from "mapbox-gl";

import {
  DatasetGate,
  ExtendedQueryType,
  Flow,
  FlowPattern,
  ODTileLayer,
  QueryType,
  SelectedVolume,
  Volume,
  ZoneIds,
  ZoningLevel,
} from "types";

export const initialFlowsSettings = {
  n: 10,
  excludeExternal: false,
  excludeGates: false,
  excludeZones: false,
  excludeSameStartEnd: false,
};

export const getBounds = (geometry: Geometry) => {
  const boundingBox = bbox(geometry);
  return new LngLatBounds(boundingBox as [number, number, number, number]);
};

export const getAvailableZoomLevels = (layers: ODTileLayer[]) => layers.map((layer) => layer.level);

export const zoomOnArea = (map: mapboxgl.Map, bounds: LngLatBounds, padding: number | PaddingOptions = 40) => {
  if (map) {
    map.fitBounds(bounds, {
      padding,
    });
  }
};

export const getLayerFromZoom = (zoom: number = 0, layers: ODTileLayer[] = []) => {
  let minZoom = 22;
  let maxZoom = 0;
  const floorZoom = Math.floor(zoom);

  if (!layers.length) return;

  const foundLayer = layers.find((layer) => {
    if (layer.minzoom < minZoom) minZoom = layer.minzoom;
    if (layer.maxzoom > maxZoom) maxZoom = layer.maxzoom;
    return floorZoom >= layer.minzoom && floorZoom <= layer.maxzoom;
  });

  if (foundLayer) return foundLayer;
  else if (floorZoom > maxZoom) return layers[layers.length - 1];
  else return layers[0];
};

export const getGatesSegmentsId = (gates: DatasetGate[] | undefined | null): { [key: string]: boolean } => {
  return (
    gates?.reduce((gatesIds: { [key: string]: boolean }, gate) => {
      gate.segments.forEach((segment) => {
        if (!gatesIds[segment.id]) gatesIds[segment.id] = true;
      });
      return gatesIds;
    }, {}) || {}
  );
};

export const getUniqueFeatures = (features: MapboxGeoJSONFeature[]) => {
  const uniqueIds = new Set();
  const uniqueFeatures = [];

  for (const feature of features) {
    const { id } = feature;

    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniqueFeatures.push(feature);
    }
  }

  return uniqueFeatures;
};

export const getODPromoteIds = (layers: ODTileLayer[]): PromoteIdSpecification => {
  return layers.reduce((promoteIds: any, layer) => {
    promoteIds[layer.name] = layer.idField;
    return promoteIds;
  }, {});
};

export const getODLayerNames = (layers: ODTileLayer[]) => {
  return layers.map((layer) => layer.name);
};

export const getMaxZoom = (availableLayers: ODTileLayer[]) => {
  let maxZoom = 0;
  availableLayers.forEach((layer) => {
    if (layer.maxzoom > maxZoom) maxZoom = layer.maxzoom;
  });

  return maxZoom;
};

export const closePopup = (popup: Popup, updateState: (newState: any) => void) => {
  if (popup.isOpen()) {
    updateState(null);
    popup.remove();
  }
};

export const getQueryType = (queryType: QueryType) =>
  queryType === QueryType.INCOMING ? QueryType.OUTGOING : QueryType.INCOMING;

export const getPopupQueryType = (selectedZoneId: string | undefined, featureId: string, queryType: QueryType) => {
  if (selectedZoneId && selectedZoneId !== featureId) {
    return getQueryType(queryType);
  } else if (selectedZoneId && selectedZoneId === featureId) {
    return ExtendedQueryType.INTERNAL;
  }

  return queryType;
};

export const getSqMiDensity = (count: number, area_sqkm: number) => {
  return Math.round(area_sqkm ? count / (area_sqkm / 2.59) : 0);
};

export const updateFeatureStatesByCounts = ({
  map,
  counts,
  zoneSourceId,
  sourceLayerId,
  ids,
  scale,
  layerZoomLevel,
  byArea = true,
}: {
  map: mapboxgl.Map;
  counts: { zones: Map<string, number> };
  zoneSourceId: string;
  sourceLayerId: string;
  layerZoomLevel: string;
  scale: (n: number) => string;
  ids: ZoneIds;
  byArea?: boolean;
}) => {
  const layerIDs = ids.get(layerZoomLevel) || new Map();

  for (const [zoneId, stats] of layerIDs) {
    const count = counts?.zones?.get(zoneId) ?? 0;
    const color = !counts?.zones?.get(zoneId)
      ? "transparent"
      : scale(byArea ? getSqMiDensity(count, stats.sqKm) : Math.floor(count / (stats?.pop ?? 1.0)));
    map.setFeatureState(
      {
        source: zoneSourceId,
        sourceLayer: sourceLayerId,
        id: zoneId,
      },
      {
        color,
        count,
      },
    );
  }
};

export const getFlowInfo = (
  flowDirection: QueryType,
  zoomLevel: string,
  id: string,
  areaName: string | undefined,
  levelName: string | undefined,
  countryName: string | undefined,
  isGate: boolean,
  zoningLevels: ZoningLevel[],
  index: number,
) => {
  const direction = flowDirection === QueryType.INCOMING ? "from" : "to";

  if (isGate) {
    return `${index}. ${direction} Gate: ${id}`;
  } else {
    const levelText = levelName || zoningLevels.find((l) => l.id === zoomLevel)?.longNameSingular;
    const areaText = areaName || id;
    const countryText = countryName ? ` (${countryName})` : "";

    return `${index}. ${direction} ${levelText}: ${areaText}${countryText}`;
  }
};

export const getCustomFlowColor = (flowPattern: FlowPattern | string) => {
  switch (flowPattern) {
    case FlowPattern.internal:
      return "#0F1426";
    case FlowPattern.gate:
      return "#139EEC";
    case FlowPattern.external:
      return "#B8A58A";
    case FlowPattern.counties:
      return "#A1A1A2";
    case FlowPattern.internalRound:
      return "purple";
    case "hover":
      return "#A7A95E";
    default:
      return "#4584D1";
  }
};

export const getSelectedVolumeObject = (volumeProp: Volume): SelectedVolume => ({
  ftSegmentId: volumeProp.fromToId,
  ftSegmentIdx: volumeProp.fromToIdx,
  streetName: volumeProp.st_name || "Not named",
  ftDirection: volumeProp.ft_dir,
  tfSegmentId: volumeProp.toFromId || undefined,
  tfSegmentIdx: volumeProp.toFromIdx || undefined,
  tfDirection: (volumeProp.seg_pair && volumeProp.tf_dir) || undefined,
  lngLat: volumeProp.lngLat,
  feature: volumeProp,
});

export const getFlowPattern = (
  { external, isGate, id, level }: Flow,
  selectedZoneId: string,
  selectedZoneType: string,
) => {
  if (
    (!external && !isGate && selectedZoneId === id && selectedZoneType !== "gate") ||
    (external && isGate && id === selectedZoneId && selectedZoneType === "gate")
  ) {
    return FlowPattern.internalRound;
  } else if (!isGate && !external) {
    return FlowPattern.internal;
  } else if (isGate) {
    return FlowPattern.gate;
  } else if (!isGate && external && level === "County") {
    return FlowPattern.counties;
  } else {
    return FlowPattern.unknown;
  }
};

export const closeAllMapboxPopups = (map: mapboxgl.Map) => {
  const popups = document.getElementsByClassName("mapboxgl-popup");

  if (popups.length > 0) {
    for (const popup of popups) {
      popup.remove();
    }
  }
};
