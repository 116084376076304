import { Box, Collapse, CollapseProps, SxProps, Typography, alpha, styled } from "@mui/material";
import { ExpandIconButton } from "components_new";
import { FC, ReactNode } from "react";

interface MapControlContainerBaseProps {
  title: string;
  children: ReactNode;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
  icon?: ReactNode;
  sx?: SxProps;
}

interface MapControlContainerCollapsibleProps {
  collapse?: true;
  expanded?: boolean;
  onChange?: (expanded: boolean) => void;
}

interface MapControlContainerScrollableProps {
  collapse?: false;
  expanded: never;
  onChange: never;
}

export type MapControlContainerProps = MapControlContainerBaseProps &
  (MapControlContainerCollapsibleProps | MapControlContainerScrollableProps);

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 6,
}));

const HeaderContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "expanded" })<{ expanded?: boolean }>(
  ({ expanded, theme }) => ({
    height: 32,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: expanded === false ? 6 : "6px 6px 0 0",
    borderBottom: expanded === false ? 0 : `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    display: "grid",
    gridTemplateColumns: "auto auto 1fr auto",
    gridColumnGap: theme.spacing(0.5),
    alignItems: "center",
  }),
);

const CollapsibleContent: FC<{ children: ReactNode } & CollapseProps> = ({ children, ...props }) => (
  <Collapse {...props}>{children}</Collapse>
);

export const MapControlContainer: FC<MapControlContainerProps> = ({
  title,
  children,
  collapse,
  expanded,
  primaryAction,
  secondaryAction,
  icon,
  sx,
  onChange,
}) => {
  return (
    <Container sx={sx}>
      <HeaderContainer expanded={expanded}>
        {collapse ? (
          <ExpandIconButton expanded={Boolean(expanded)} onClick={() => onChange && onChange(Boolean(expanded))} />
        ) : (
          <div />
        )}
        {primaryAction ? primaryAction : <div />}
        <Box
          sx={{
            display: "inline-grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          <Typography fontSize={12} fontWeight={500} color="text.secondary" noWrap>
            {title}
          </Typography>
          {icon && icon}
        </Box>
        {secondaryAction ? secondaryAction : <div />}
      </HeaderContainer>
      {collapse ? <CollapsibleContent in={expanded}>{children}</CollapsibleContent> : children}
    </Container>
  );
};
