import { CenterFocusStrong } from "@mui/icons-material";
import { Grid, Typography, styled } from "@mui/material";
import { length } from "@turf/turf";
import { IntersectionDirection, Screenline, ScreenlineGeometry } from "api/analytics/index.d";
import { EditConfirmCancelButton, IconButton, MapControlContainer, TextField, TextFieldProps } from "components_new";
import { LngLatLike, PaddingOptions } from "mapbox-gl";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions, selectArePendingChanges } from "store/sections/screenlines";
import { datasetFoldersActions } from "store/sections/userFolders";

import { StyledCheckbox } from "./ScreenlineEditor";
import { ScreenlineIntersections } from "./ScreenlineIntersections";
import { getScreenlinesBboxPolygon } from "./utils";

interface ScreenlineDetailForm {
  id: string;
  name: string;
  description: string;
  leftLabel: string;
  rightLabel: string;
}

export interface ScreenlineDetailProps {
  screenline: Screenline;
  loading: boolean;
  isEditPropsMode: boolean;
  isEditGeometryMode: boolean;
  isEditIntersectionsMode: boolean;
  updateConfigDocument: boolean;
  handleSetEditIntersectionsMode: (editMode: boolean) => void;
  handleSetPropsEditMode: (editMode: boolean) => void;
  handleZoomOnBounds: (geometry: ScreenlineGeometry, padding?: number | PaddingOptions) => void;
  handleZoomOnPoint: (lngLat: LngLatLike, zoom?: number) => void;
  setSelectedIntersectionId: ((intersectionId: string | null) => void) | null;
  handleSetEditGeometryMode: () => void;
}

export const SmallTextField = styled((props: TextFieldProps) => (
  <TextField size="small" fullWidth InputLabelProps={{ shrink: true }} {...props} />
))(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    fontSize: 13,
  },
  "& .MuiFormHelperText-root": {
    fontSize: 10,
    margin: "0 0 0 4px",
  },
}));

const IntersectionFiltererTextField = styled(SmallTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    padding: "8px",
  },
}));

const INITIAL_DETAIL_FORM = {
  id: "",
  name: "",
  description: "",
  leftLabel: "",
  rightLabel: "",
};

export const ScreenlineDetail: FC<ScreenlineDetailProps> = ({
  screenline,
  loading,
  isEditPropsMode,
  isEditGeometryMode,
  isEditIntersectionsMode,
  updateConfigDocument,
  handleSetEditIntersectionsMode,
  handleSetPropsEditMode,
  handleZoomOnBounds,
  handleZoomOnPoint,
  setSelectedIntersectionId,
  handleSetEditGeometryMode,
}) => {
  const dispatch = useAppDispatch();

  const screenlines = useAppSelector((state) => state.screenlines.screenlines);
  const loadedConfigDocument = useAppSelector((state) => state.datasetFolders.loadedConfigDocument);
  const arePendingChanges = useAppSelector(selectArePendingChanges);
  const isEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);

  const [value, setValue] = useState<ScreenlineDetailForm>(INITIAL_DETAIL_FORM);

  const [error, setError] = useState<ScreenlineDetailForm>(INITIAL_DETAIL_FORM);

  const [intersectionDirectionFilter, setIntersectionDirectionFilter] = useState<IntersectionDirection | false>(false);

  const arePropsPendingChanges = useMemo(() => {
    const { id, name, description, leftLabel, rightLabel } = value;
    const savedId = screenline.id || "";
    const savedName = screenline.name || "";
    const savedDescription = screenline.description || "";
    const savedLeftLabel = screenline.leftLabel || "";
    const savedRightLabel = screenline.rightLabel || "";

    return (
      id !== savedId ||
      name !== savedName ||
      description !== savedDescription ||
      leftLabel !== savedLeftLabel ||
      rightLabel !== savedRightLabel
    );
  }, [value, screenline]);

  const areIntersectionFilterPendingCHanges = useMemo(() => {
    const savedIntersectionDirectionFilter = screenline.intersectionDirectionFilter || false;
    return intersectionDirectionFilter !== savedIntersectionDirectionFilter;
  }, [intersectionDirectionFilter, screenline.intersectionDirectionFilter]);

  const areUnappliedChanges = useMemo(
    () => arePendingChanges || arePropsPendingChanges || areIntersectionFilterPendingCHanges,

    [arePendingChanges, arePropsPendingChanges, areIntersectionFilterPendingCHanges],
  );

  const resetScreenlinePropsToDefault = useCallback(() => {
    setValue({
      id: screenline.id || INITIAL_DETAIL_FORM.id,
      name: screenline.name || INITIAL_DETAIL_FORM.name,
      description: screenline.description || INITIAL_DETAIL_FORM.description,
      leftLabel: screenline.leftLabel || INITIAL_DETAIL_FORM.leftLabel,
      rightLabel: screenline.rightLabel || INITIAL_DETAIL_FORM.rightLabel,
    });
    setIntersectionDirectionFilter(screenline.intersectionDirectionFilter || false);
    setError(INITIAL_DETAIL_FORM);
  }, [screenline]);

  const validate = (key: string, value: string) => {
    const newErrors = { ...error };
    switch (key) {
      case "id":
        if (!value) {
          newErrors.id = "Id is required";
        } else if (screenlines.find((s) => s.id === value.trim() && s.id !== screenline.id)) {
          newErrors.id = "Id must be unique";
        } else {
          newErrors.id = "";
        }

        break;
    }

    setError(newErrors);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    validate(name, value);
    setValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleApplyProps = () => {
    if (Object.values(error).some((e) => e)) return;

    handleSetPropsEditMode(false);

    const { id, name, description, leftLabel, rightLabel } = value;
    const updatedScreenline = {
      ...screenline,
      id,
      name,
      description,
      leftLabel,
      rightLabel,
      intersectionDirectionFilter: intersectionDirectionFilter === false ? undefined : intersectionDirectionFilter,
    };

    dispatch(screenlinesActions.editScreenline(screenline.id, updatedScreenline, areIntersectionFilterPendingCHanges));

    if (updateConfigDocument && loadedConfigDocument.data) {
      const configDocumentId = loadedConfigDocument.data.configDocument.id;
      const updatedScreenlines = screenlines.map((s) => {
        if (s.id === screenline.id) {
          return updatedScreenline;
        }
        return s;
      });
      const polygon = getScreenlinesBboxPolygon(updatedScreenlines);

      dispatch(
        datasetFoldersActions.editConfigDocumentPayload(configDocumentId, {
          configPayload: updatedScreenlines,
          configSchemaVersion: "1.0",
          boundingBox: polygon,
        }),
      );
    }
  };

  const handleApplyIntersections = () => {
    handleSetEditIntersectionsMode(false);

    const updatedScreenline = {
      ...screenline,
      segmentIntersections: screenline.segmentIntersections,
    };

    dispatch(screenlinesActions.editScreenline(screenline.id, updatedScreenline, true));

    if (updateConfigDocument && loadedConfigDocument.data) {
      const configDocumentId = loadedConfigDocument.data.configDocument.id;
      const updatedScreenlines = screenlines.map((s) => {
        if (s.id === screenline.id) {
          return updatedScreenline;
        }
        return s;
      });
      const polygon = getScreenlinesBboxPolygon(updatedScreenlines);

      dispatch(
        datasetFoldersActions.editConfigDocumentPayload(configDocumentId, {
          configPayload: updatedScreenlines,
          configSchemaVersion: "1.0",
          boundingBox: polygon,
        }),
      );
    }
  };

  const handleCancel = () => {
    if (isEditPropsMode) {
      handleSetPropsEditMode(false);
      resetScreenlinePropsToDefault();
    }

    if (isEditGeometryMode) {
      dispatch(screenlinesActions.setDraftFeature(null));
    }

    if (isEditIntersectionsMode) {
      handleSetEditIntersectionsMode(false);
    }

    dispatch(screenlinesActions.setDraftScreenline(null));
  };

  const handleToggleIntersectionDirectionFilter = (direction: IntersectionDirection) => {
    if (!intersectionDirectionFilter || intersectionDirectionFilter !== direction) {
      setIntersectionDirectionFilter(direction);
    } else {
      setIntersectionDirectionFilter(false);
    }
  };

  const getScreenlineLength = () => {
    return length(screenline.geometry as any, { units: "miles" }).toFixed(2);
  };

  useEffect(() => {
    if (screenline) {
      resetScreenlinePropsToDefault();
    }
  }, [screenline, resetScreenlinePropsToDefault]);

  useEffect(() => {
    if (!isEditorOpen) {
      resetScreenlinePropsToDefault();
    }
  }, [isEditorOpen, resetScreenlinePropsToDefault]);

  return (
    <>
      <MapControlContainer
        title="Properties"
        secondaryAction={
          <EditConfirmCancelButton
            editMode={isEditPropsMode}
            confirmDisabled={Boolean(!areUnappliedChanges || error.id)}
            editDisabled={Boolean(loading || isEditGeometryMode || isEditIntersectionsMode)}
            handleSetEditMode={() => handleSetPropsEditMode(true)}
            handleApply={handleApplyProps}
            handleCancel={handleCancel}
          />
        }
      >
        <Grid container spacing={0.5} padding={1}>
          <Grid item xs={4}>
            <SmallTextField
              name="id"
              label="Id"
              value={value.id}
              disabled={!isEditPropsMode}
              onChange={handleChange}
              error={Boolean(error.id)}
              helperText={error.id}
              required
            />
          </Grid>
          <Grid item xs={8}>
            <SmallTextField
              name="name"
              label="Name"
              value={value.name}
              disabled={!isEditPropsMode}
              onChange={handleChange}
              placeholder={value.name ? "" : "No name"}
            />
          </Grid>
          <Grid item xs={12}>
            <SmallTextField
              multiline
              minRows={1}
              maxRows={2}
              name="description"
              label="Description"
              value={value.description}
              disabled={!isEditPropsMode}
              onChange={handleChange}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid container item xs={6} alignItems={"center"}>
              <Grid item xs={"auto"} marginRight={0.5}>
                <StyledCheckbox
                  disabled={!isEditPropsMode || loading}
                  checked={!intersectionDirectionFilter || intersectionDirectionFilter === IntersectionDirection.left}
                  onChange={() => handleToggleIntersectionDirectionFilter(IntersectionDirection.right)}
                />
              </Grid>
              <Grid item xs>
                <IntersectionFiltererTextField
                  name="leftLabel"
                  label="Left label"
                  value={value.leftLabel}
                  disabled={!isEditPropsMode}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid container item xs={6} alignItems={"center"}>
              <Grid item xs={"auto"} marginRight={0.5}>
                <StyledCheckbox
                  disabled={!isEditPropsMode || loading}
                  checked={!intersectionDirectionFilter || intersectionDirectionFilter === IntersectionDirection.right}
                  onChange={() => handleToggleIntersectionDirectionFilter(IntersectionDirection.left)}
                />
              </Grid>
              <Grid item xs>
                <IntersectionFiltererTextField
                  name="rightLabel"
                  label="Right label"
                  value={value.rightLabel}
                  disabled={!isEditPropsMode}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MapControlContainer>
      <MapControlContainer
        title="Geometry"
        secondaryAction={
          <EditConfirmCancelButton
            editMode={isEditGeometryMode}
            confirmDisabled={!areUnappliedChanges}
            editDisabled={loading || isEditIntersectionsMode || isEditPropsMode}
            handleSetEditMode={handleSetEditGeometryMode}
            handleApply={() => {}}
            handleCancel={handleCancel}
          />
        }
      >
        <Grid container justifyContent={"space-between"} alignItems={"center"} padding={1}>
          <Typography variant="caption">
            <strong>Length:</strong> {getScreenlineLength()} miles
          </Typography>
          <IconButton edge="end" size="small" onClick={() => handleZoomOnBounds(screenline.geometry)}>
            <CenterFocusStrong color="secondary" fontSize="inherit" />
          </IconButton>
        </Grid>
      </MapControlContainer>

      <MapControlContainer
        title="Segment intersections"
        secondaryAction={
          <EditConfirmCancelButton
            editMode={isEditIntersectionsMode}
            confirmDisabled={!areUnappliedChanges}
            editDisabled={loading || isEditPropsMode || isEditGeometryMode}
            handleSetEditMode={handleSetEditIntersectionsMode}
            handleApply={handleApplyIntersections}
            handleCancel={handleCancel}
          />
        }
      >
        <ScreenlineIntersections
          loading={loading}
          intersectionDirectionFilter={screenline.intersectionDirectionFilter}
          editMode={isEditIntersectionsMode}
          rightLabel={screenline.rightLabel}
          leftLabel={screenline.leftLabel}
          handleZoomOnPoint={handleZoomOnPoint}
          setSelectedIntersectionId={setSelectedIntersectionId}
        />
      </MapControlContainer>
    </>
  );
};
