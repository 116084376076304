import { Box } from "@mui/material";
import { Divider, MapControlContainer, SliderControl, VisibilityIconButton } from "components_new";
import { FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";

import { ColorSchemeSelector } from "features/filters/ColorSchemeSelector";
import { RangeFilter } from "features/filters/RangeFilter";
import { isInAvailableRange } from "features/filters/utils";
import { MapLayersId } from "features/map/MapControlPanel";
import { ModuleData } from "features/map/ModuleManager";
import { sequentialSchemes } from "features/map/layerColors";
import { getLayerFromZoom, getQueryType } from "features/map/utils";

import { ODLegend } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";
import { mapActions } from "store/sections/map";

import { MapVisualizationType, MeasureRange } from "types";

interface ODMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  ODModuleData: ModuleData;
  expandedMapLayers: MapLayersId[];
  isSelectLinkMode: boolean;
  onChange: (mapLayerId: MapLayersId) => void;
}

export const ODMapLayers: FC<ODMapLayersProps> = ({
  map,
  ODModuleData,
  expandedMapLayers,
  isSelectLinkMode,
  onChange,
}) => {
  const dispatch = useAppDispatch();

  const {
    zoningLevels,
    ODZoningLevelBlockedZoom,
    filterZonesByRange,
    filterGatesByRange,
    updateODModeCounts,
    changeZonesFillOpacity,
  } = ODModuleData.data;

  const [opacityFactor, setOpacityFactor] = useState(1);

  const mapMode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  const showZoneCounts = useAppSelector((state) => state.map.showZoneCounts);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const colorScale = useAppSelector((state) => state.map.colorScale);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);

  const queryType = useAppSelector((state) => state.filters.queryType);
  const zoningLevel = useAppSelector((state) => state.filters.zoningLevel);

  const rangeByZone = useAppSelector((state) => state.filters.rangeByZone);
  const availableRangeByZone = useAppSelector((state) => state.filters.availableRangeByZone);

  //OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODCountsByZone = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const ODRange = useAppSelector((state) => state.filters.ODRange);
  const ODAvailableRange = useAppSelector((state) => state.filters.ODAvailableRange);
  // @TODO replace with Select Link mode later
  const isOD =
    (mapMode === MapVisualizationType.OD || mapMode === null) && selectedFocusArea && !selectedFocusArea?.datasetId;

  //Dataset
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);
  const datasetCountsByZone = useAppSelector((state) => state.analytics.datasetCountsByZoneId);
  const datasetRange = useAppSelector((state) => state.filters.datasetRange);
  const datasetAvailableRange = useAppSelector((state) => state.filters.datasetAvailableRange);
  // @TODO replace with Select Link mode later
  const isDataset = (mapMode === MapVisualizationType.OD || mapMode === null) && selectedFocusArea?.datasetId;

  const layers = isOD ? ODMetadata.data?.tileService.layers : datasetMetadata.data?.tileService.layers;

  const availableRange = useMemo(() => {
    if (selectedZone && availableRangeByZone) return availableRangeByZone;
    if (isOD && ODAvailableRange.data && zoningLevel) return ODAvailableRange.data?.[zoningLevel?.level];
    if (isDataset && datasetAvailableRange.data && zoningLevel) return datasetAvailableRange.data?.[zoningLevel?.level];
  }, [
    selectedZone,
    availableRangeByZone,
    isOD,
    ODAvailableRange.data,
    zoningLevel,
    isDataset,
    datasetAvailableRange.data,
  ]);

  const range = useMemo(() => {
    const availableRangeTouple: [number, number] = availableRange ? [availableRange.min, availableRange.max] : [0, 0];
    if (selectedZone) return rangeByZone || availableRangeTouple;
    if (isOD && zoningLevel)
      return ODRange?.[zoningLevel?.level] ? ODRange?.[zoningLevel?.level] : availableRangeTouple;
    if (isDataset && zoningLevel)
      return datasetRange?.[zoningLevel?.level] ? datasetRange?.[zoningLevel?.level] : availableRangeTouple;
  }, [selectedZone, rangeByZone, availableRange, isOD, zoningLevel, ODRange, isDataset, datasetRange]);

  const loading =
    ODCounts.state === DataState.LOADING ||
    ODCountsByZone.state === DataState.LOADING ||
    datasetCounts.state === DataState.LOADING ||
    datasetCountsByZone.state === DataState.LOADING;

  useEffect(() => {
    const zoom = map.current?.getZoom();
    const isZoningLevelBlocked =
      (range && availableRange && (range[0] !== availableRange.min || range[1] !== availableRange.max)) || selectedZone;

    if (isZoningLevelBlocked && !ODZoningLevelBlockedZoom.current && zoom !== undefined) {
      ODZoningLevelBlockedZoom.current = zoom;
    } else if (!isZoningLevelBlocked) {
      ODZoningLevelBlockedZoom.current = null;
      const layer = getLayerFromZoom(zoom, layers);
      if (layer) dispatch(filtersActions.setZoningLevel(layer));
      updateODModeCounts.current?.();
    }
  }, [
    range,
    availableRange,
    selectedZone,
    map,
    layers,
    dispatch,
    zoningLevel,
    ODZoningLevelBlockedZoom,
    updateODModeCounts,
  ]);

  const handleSetRange = (newRange: [number, number], availableRange: MeasureRange) => {
    if (selectedZone) {
      dispatch(filtersActions.setRangeByZone(isInAvailableRange(newRange, availableRange) ? null : newRange));
    } else if (isOD && zoningLevel) {
      dispatch(
        filtersActions.setODRange(
          isInAvailableRange(newRange, availableRange) ? null : { [zoningLevel?.level]: newRange },
        ),
      );
    } else if (isDataset && zoningLevel) {
      dispatch(
        filtersActions.setDatasetRange(
          isInAvailableRange(newRange, availableRange) ? null : { [zoningLevel?.level]: newRange },
        ),
      );
    }
  };

  const filterZones = useCallback(() => {
    if (map.current && range && availableRange && zoningLevel) {
      if (range[0] > availableRange.min || range[1] < availableRange.max) {
        filterZonesByRange(range, zoningLevel, opacityFactor, isSelectLinkMode);
        if (isDataset) filterGatesByRange(range);
      } else {
        filterZonesByRange([availableRange.min, availableRange.max], zoningLevel, opacityFactor, isSelectLinkMode);
        if (isDataset) filterGatesByRange([availableRange.min, availableRange.max]);
      }
    }
  }, [
    availableRange,
    filterZonesByRange,
    filterGatesByRange,
    isDataset,
    map,
    range,
    zoningLevel,
    isSelectLinkMode,
    opacityFactor,
  ]);

  const handleChangeShowZoneCounts = () => {
    dispatch(mapActions.setShowZoneCounts(!showZoneCounts));
    ODModuleData.data.changeShowZoneCounts(!showZoneCounts);
  };

  const handleChangeColorScheme = useCallback(
    (colorScheme: string) => {
      dispatch(mapActions.setColorScheme(colorScheme));
    },
    [dispatch],
  );

  const handleChangeFillOpacity = (opacityFactor: number) => {
    changeZonesFillOpacity(layers, range, opacityFactor, isSelectLinkMode);
    setOpacityFactor(opacityFactor);
  };

  return (
    <>
      <ODLegend
        colorSchemeName={colorScheme}
        scale={colorScale}
        queryType={selectedZone ? getQueryType(queryType) : queryType}
        zoningLevel={zoningLevel?.level}
        zoningLevels={zoningLevels}
        subtitle="Counts per square mile"
      />

      <MapControlContainer
        title="OD Counts"
        primaryAction={<VisibilityIconButton visible={showZoneCounts} onClick={handleChangeShowZoneCounts} />}
        collapse
        expanded={expandedMapLayers.includes("od")}
        onChange={() => onChange("od")}
      >
        <Box padding={1}>
          <RangeFilter
            label={"Filter"}
            range={range}
            availableRange={availableRange}
            loading={loading}
            filterByRange={filterZones}
            setRange={handleSetRange}
          />
          <Divider sx={{ marginY: 0.5 }} />
          <SliderControl
            label="Opacity"
            value={opacityFactor}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => handleChangeFillOpacity(value as number)}
          />
          <Divider sx={{ marginY: 0.5 }} />
          <ColorSchemeSelector
            availableSchemes={sequentialSchemes}
            selectedColorScheme={colorScheme}
            onChange={handleChangeColorScheme}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
