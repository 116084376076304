import { Box } from "@mui/material";
import { useMemoryStore } from "api/MemoryStoreContext";
import { MemoryStoreKeys } from "api/memoryStore";
import { Divider, MapControlContainer, SliderControl, VisibilityIconButton } from "components_new";
import React, { FC, MutableRefObject, useMemo, useState } from "react";

import { RangeFilter } from "features/filters/RangeFilter";
import { isInAvailableRange } from "features/filters/utils";
import { MapLayersId } from "features/map/MapControlPanel";
import { ModuleData } from "features/map/ModuleManager";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";
import { mapActions } from "store/sections/map";

import { MeasureRange, RoadClassCategory } from "types";

import { getFromToSegmentIndex, getReverseSegmentIndex } from "utils/parse";

interface RoadsMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  roadsModuleData: ModuleData;
  expandedMapLayers: MapLayersId[];
  isSelectLinkResults: boolean;
  onChange: (mapLayerId: MapLayersId) => void;
}

export const RoadsMapLayers: FC<RoadsMapLayersProps> = ({
  map,
  roadsModuleData,
  expandedMapLayers,
  isSelectLinkResults,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const memoryStore = useMemoryStore();

  const { filterRoadSegmentsByRange, changeShowRoadVolumes, changeVolumesOpacity, changeVolumesWidth } =
    roadsModuleData.data;

  const [opacityFactor, setOpacityFactor] = useState(1);
  const [widthFactor, setWidthFactor] = useState(1);

  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIndexes = useAppSelector((state) => state.analytics.roadSegmentIndexes);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const showRoadVolumes = useAppSelector((state) => state.map.showRoadVolumes);

  const availableRange = useAppSelector((state) => state.filters.roadAvailableRange.data);
  const availableRangeState = useAppSelector((state) => state.filters.roadAvailableRange.state);

  const roadsRange = useAppSelector((state) => state.filters.roadRange);

  const range = useMemo<[number, number]>(
    () => (roadsRange ? roadsRange : [availableRange?.min || 0, availableRange?.max || 0]),
    [roadsRange, availableRange],
  );

  const loading =
    roadsVolumes.state === DataState.LOADING ||
    roadsMetadata.state === DataState.LOADING ||
    roadSegmentIndexes.state === DataState.LOADING ||
    availableRangeState === DataState.LOADING;

  const handleChangeShowRoadVolumes = () => {
    dispatch(mapActions.setShowRoadVolumes(!showRoadVolumes));
    changeShowRoadVolumes(!showRoadVolumes);
  };

  const handleFilterRoadSegmentsByRange = () => {
    if (roadsVolumes.data && roadsMetadata.data && roadSegmentIndexes.data && availableRange && range) {
      if (range[0] >= availableRange.min || range[1] <= availableRange.max) {
        const allSegmentFromToIndexes = new Set();
        const limitedAccessFromToSegmentIndexes = new Set();
        const restAccessFromToSegmentIndexes = new Set();

        const [minRange, maxRange] = range;
        const segmentVolumesData = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_VOLUMES) as Map<number, number>;
        const allLimitedAccessFromToSegmentIndexes = new Set(
          memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES_BY_ROAD_CLASS)[
            RoadClassCategory.LIMITED_ACCESS
          ],
        );
        const allAccessSegmentFromToIndexes = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES);

        if (segmentVolumesData) {
          segmentVolumesData.forEach((volume, volumeSegmentIdx) => {
            const reverseSegmentIdx = getReverseSegmentIndex(volumeSegmentIdx);
            const reverseVolume = segmentVolumesData.get(reverseSegmentIdx) || null;

            if (
              !allAccessSegmentFromToIndexes.has(volumeSegmentIdx) &&
              !allAccessSegmentFromToIndexes.has(reverseSegmentIdx)
            ) {
              return;
            }

            if (
              (volume >= minRange && volume <= maxRange) ||
              (reverseVolume && reverseVolume >= minRange && reverseVolume <= maxRange)
            ) {
              const fromToSegmentIndex = getFromToSegmentIndex(volumeSegmentIdx);

              allSegmentFromToIndexes.add(fromToSegmentIndex);

              if (allLimitedAccessFromToSegmentIndexes.has(fromToSegmentIndex)) {
                limitedAccessFromToSegmentIndexes.add(fromToSegmentIndex);
              } else {
                restAccessFromToSegmentIndexes.add(fromToSegmentIndex);
              }
            }
          });

          filterRoadSegmentsByRange(
            Array.from(allSegmentFromToIndexes.keys()).sort(),
            Array.from(limitedAccessFromToSegmentIndexes.keys()).sort(),
            Array.from(restAccessFromToSegmentIndexes.keys()).sort(),
          );
        }
      } else {
        filterRoadSegmentsByRange(null);
      }
    }
  };

  const handleSetRoadSegmentsRange = (newRange: [number, number], availableRange: MeasureRange) => {
    dispatch(filtersActions.setRoadsRange(isInAvailableRange(newRange, availableRange) ? null : newRange));
  };

  const handleChangeVolumesOpacity = (opacityFactor: number) => {
    changeVolumesOpacity(opacityFactor, isSelectLinkResults);
    setOpacityFactor(opacityFactor);
  };

  return (
    <>
      <MapControlContainer
        title="Segment Volumes"
        primaryAction={
          <VisibilityIconButton visible={showRoadVolumes} onClick={handleChangeShowRoadVolumes} disabled={loading} />
        }
        collapse
        expanded={expandedMapLayers.includes("roads")}
        onChange={() => onChange("roads")}
      >
        <Box padding={1}>
          {!roadsModuleData.data.isSelectLinkResults ? (
            <RangeFilter
              label="Filter"
              range={range}
              availableRange={availableRange || { min: 0, max: 0 }}
              loading={loading}
              filterByRange={handleFilterRoadSegmentsByRange}
              setRange={handleSetRoadSegmentsRange}
            />
          ) : null}
          <Divider sx={{ marginY: 0.5 }} />
          <SliderControl
            label="Opacity"
            value={opacityFactor}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => handleChangeVolumesOpacity(value as number)}
          />
          <SliderControl
            label="Width"
            value={widthFactor}
            min={0}
            max={3}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => setWidthFactor(value as number)}
            onChangeCommitted={(e, value) => changeVolumesWidth(value as number)}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
