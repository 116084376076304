import { MenuItem, styled } from "@mui/material";
import { TextField } from "components_new";
import { interpolateRgbBasis } from "d3-interpolate";
import { scaleLinear, scaleSequential } from "d3-scale";
import React, { useLayoutEffect, useMemo, useRef } from "react";

import { ColorScheme } from "features/map/layerColors";

export interface Props {
  availableSchemes: ColorScheme;
  selectedColorScheme: string;
  onChange: (colorScheme: string) => void;
  disabled?: boolean;
}

const ColorRampCanvas = styled("canvas")`
  display: flex;
  flex-grow: 1;
  border-radius: 6px;
`;

const ColorRamp: React.FC<{
  colorScheme: string[];
  width?: number;
  height?: number;
}> = ({ colorScheme, width = 194, height = 22 }) => {
  const colorScale = scaleSequential(interpolateRgbBasis(colorScheme));
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const xScale = useMemo(() => scaleLinear().range([0, width]), [width]);

  useLayoutEffect(() => {
    const { current } = canvasRef;
    if (current) {
      const ctx = current.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, width, height);
        for (let i = 0; i < width; i++) {
          ctx.fillStyle = colorScale(xScale.invert(i));
          ctx.fillRect(i, 0, 1, height);
        }
      }
    }
  });

  return <ColorRampCanvas ref={canvasRef} width={width} height={height} />;
};

const ColorSchemeSelector: React.FC<Props> = ({ availableSchemes, selectedColorScheme, disabled, onChange }) => {
  return (
    <TextField
      fullWidth
      select
      disabled={disabled}
      value={selectedColorScheme}
      sx={{ marginBottom: 1 }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          sx: { maxHeight: 450 },
        },
      }}
    >
      {Object.entries(availableSchemes).map(([colorSchemeKey, colorScheme]) => (
        <MenuItem key={colorSchemeKey} value={colorSchemeKey} onClick={() => onChange(colorSchemeKey)}>
          <ColorRamp colorScheme={colorScheme} />
        </MenuItem>
      ))}
    </TextField>
  );
};

export { ColorSchemeSelector };
