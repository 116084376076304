import styled from "@emotion/styled";
import { CircularProgress } from "components_new";

import { Card } from "components";

const SpinnerContainer = styled(Card)`
  display: flex;
  border-radius: 4px;
  background: #fff;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 120px;
  left: calc(var(--sidebar-width) + 20px);

  box-shadow: 0 0 0 2px rgb(0 0 0/10%);
`;

export const SpinnerOverlay = () => (
  <SpinnerContainer>
    <CircularProgress size={20} />
  </SpinnerContainer>
);
