import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { themeColors, themePaddings } from "./themeConstants";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    elevated: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    dialogTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    dialogTitle?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    dialogTitle: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    screenline: {
      circle: string;
      line: string;
      volume: string;
      candidate: string;
      unresolved: string;
    };
  }

  interface PaletteOptions {
    screenline?: {
      circle: string;
      line: string;
      volume: string;
      candidate: string;
      unresolved: string;
    };
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.primaryLight,
      contrastText: themeColors.textContrast,
    },

    screenline: {
      circle: "#004d20",
      line: "#008035",
      volume: "#00c853",
      candidate: "#ba68c8",
      unresolved: "#ed6c02",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
        disableRipple: true,
      },
    },

    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          dialogTitle: "span",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
          paddingBottom: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    dialogTitle: {
      display: "block",
      fontSize: 16,
      marginBottom: themePaddings.md,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
