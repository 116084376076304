import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { Box } from "@mui/material";
import { MapControlContainer, SliderControl, VisibilityIconButton } from "components_new";
import { FC, MutableRefObject, useMemo, useState } from "react";

import { ColorSchemeSelector } from "features/filters/ColorSchemeSelector";
import { sequentialSchemes } from "features/map/layerColors";

import { useAppSelector } from "hooks";

import { useChangeFillOpacity } from "./RoadVmtControllerCallbacks";
import { getAvailableLayers } from "./utils";

export interface MapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  mapboxLayerManager: LayerManager | null;
  showZones: boolean;
  colorScheme: string;
  changeShowZones: () => void;
  changeColorScheme: (scheme: string) => void;
}

export const RoadVmtMapLayers: FC<MapLayersProps> = ({
  map,
  mapboxLayerManager,
  showZones,
  colorScheme,
  changeShowZones,
  changeColorScheme,
}) => {
  const roadVmtMetadata = useAppSelector((state) => state.roadVmt.roadVmtMetadata);

  const layers = useMemo(
    () => (roadVmtMetadata.data ? getAvailableLayers(roadVmtMetadata.data) : []),
    [roadVmtMetadata],
  );

  const [opacityFactor, setOpacityFactor] = useState(1);

  const changeFillOpacity = useChangeFillOpacity(map, mapboxLayerManager);

  const handleChangeFillOpacity = (value: number) => {
    setOpacityFactor(value);
    changeFillOpacity(value, layers);
  };

  return (
    <>
      <MapControlContainer
        title="Road VMT Counts"
        primaryAction={<VisibilityIconButton visible={showZones} onClick={changeShowZones} />}
      >
        <Box padding={1}>
          <SliderControl
            label="Opacity"
            value={opacityFactor}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            onChange={(e, value) => handleChangeFillOpacity(value as number)}
          />
          <ColorSchemeSelector
            availableSchemes={sequentialSchemes}
            selectedColorScheme={colorScheme}
            onChange={changeColorScheme}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
