import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SeverityIcon } from "components_new";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { FC } from "react";

import { ValidationMessageSeverity } from "types";

import { capitalize } from "utils/format";

export interface IssuePopupContentProps {
  feature: MapboxGeoJSONFeature;
  issueSeverity: ValidationMessageSeverity;
}

export const IssuePopupContent: FC<IssuePopupContentProps> = ({ feature, issueSeverity }) => (
  <Box py={1} px={2} maxWidth={300}>
    <Grid container alignItems={"flex-end"} mb={1}>
      <SeverityIcon severity={issueSeverity} fontSize="small" />
      <Typography variant="subtitle2" fontSize={11}>
        {capitalize(issueSeverity)}
      </Typography>
    </Grid>
    <Typography variant="caption">{feature?.properties?.text}</Typography>
  </Box>
);
