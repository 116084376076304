import { Stack, StackProps, styled } from "@mui/material";

export const AnalyticsContainer = styled((props: StackProps) => <Stack spacing={1} {...props} />, {
  shouldForwardProp: (prop) => prop !== "heightOperand",
})<{
  heightOperand?: number;
}>(({ heightOperand = 62, theme }) => ({
  height: `calc(100% - ${heightOperand}px)`,
  padding: theme.spacing(1, 2),
  overflowY: "auto",
}));
