import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "components_new";
import * as React from "react";

export const ExpandIconButton: React.FC<
  { expanded: boolean; direction?: "vertical" | "horizontal" } & IconButtonProps
> = ({ expanded, direction = "vertical", ...props }) => (
  <IconButton {...props}>
    {direction === "vertical" ? (
      expanded ? (
        <ExpandLess />
      ) : (
        <ExpandMore />
      )
    ) : direction === "horizontal" ? (
      expanded ? (
        <ChevronRight />
      ) : (
        <ChevronLeft />
      )
    ) : null}
  </IconButton>
);
