import { RestartAlt } from "@mui/icons-material";
import { Grid, Slider, SliderProps, Typography, styled } from "@mui/material";
import { IconButton } from "components_new";
import { FC, SyntheticEvent, useRef } from "react";

export interface SliderControlProps extends SliderProps {
  label: string;
  loading?: boolean;
  onChange: (event: SyntheticEvent | Event, value: number | number[]) => void;
}

const InlineGridItem = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-item": {
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const SliderControl: FC<SliderControlProps> = ({
  label = "",
  loading,
  sx,
  onChange,
  onChangeCommitted,
  ...props
}) => {
  const defaultValue = useRef(props.defaultValue || props.value || 0);

  return (
    <Grid container columnGap={1} alignItems="center" sx={sx}>
      <InlineGridItem minWidth={55} item>
        <Typography fontSize={12} fontWeight={500} color="text.secondary">
          {label}
        </Typography>
      </InlineGridItem>
      <InlineGridItem item xs>
        <Slider
          disabled={loading || props.disabled}
          sx={{
            marginTop: "0",
            "& .MuiSlider-mark, .MuiSlider-markActive": {
              width: "6px",
              height: "6px",
              borderRadius: "50%",
            },
          }}
          color="secondary"
          size="small"
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          {...props}
        />
      </InlineGridItem>
      <InlineGridItem item>
        <IconButton
          disabled={loading || props.disabled}
          size="small"
          onClick={(e) => {
            onChange(e, defaultValue.current);
            if (onChangeCommitted) onChangeCommitted(e, defaultValue.current);
          }}
        >
          <RestartAlt fontSize={"small"} />
        </IconButton>
      </InlineGridItem>
    </Grid>
  );
};
