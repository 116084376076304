import { Expression, Layer } from "mapbox-gl";

import {
  CHOROPLETH_FILL_COLOR,
  CHOROPLETH_FILL_OPACITY,
  CHOROPLETH_FILL_OPACITY_HOVER,
  CHOROPLETH_FILL_OPACITY_SELECTED,
  CHOROPLETH_LINE_COLOR,
  CHOROPLETH_LINE_OPACITY,
  COUNTY_BORDER_LINE_COLOR,
  COUNTY_BORDER_LINE_OPACITY,
  COUNTY_BORDER_LINE_WIDTH,
  SELECT_LINK_FILL_OPACITY,
  SELECT_LINK_FILL_OPACITY_HOVER,
  ZONE_HOVER_HIGHLIGHT_COLOR,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
  ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY,
} from "features/map/layerColors";

import { ODTileService } from "types";

import { OD_ZONE_SOURCE_ID, OUT_ZONES_SOURCE_ID } from "./sources";

export const OUT_ZONES_LAYER_LINE = "OUT_ZONES_LAYER_LINE";
export const OUT_ZONES_LAYER_FILL = "OUT_ZONES_LAYER_FILL";
export const ZONE_BORDERS_LAYER_NAME = "ZONE_BORDERS_LAYER_NAME";
export const SELECTED_ZONES_LAYER_NAME = "SELECTED_ZONES_LAYER_NAME";

export const CHOROPLETH_LINE_WIDTH = 0.6;

export const getODFillOpacityExpression = (
  range: [number, number] = [0, Infinity],
  opacityFactor: number = 1,
  isSelectLinkMode?: boolean,
): Expression => [
  "case",
  [
    "!",
    [
      "all",
      [">=", ["coalesce", ["feature-state", "count"], 0], range[0]],
      ["<=", ["coalesce", ["feature-state", "count"], 0], range[1]],
    ],
  ],
  ["*", 0.4, opacityFactor],
  ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
  ["*", ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY, opacityFactor],
  ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
  ["*", ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY, opacityFactor],
  ["==", ["feature-state", "color"], null],
  0,
  ["boolean", ["feature-state", "hover"], false],
  ["*", isSelectLinkMode ? SELECT_LINK_FILL_OPACITY_HOVER : CHOROPLETH_FILL_OPACITY_HOVER, opacityFactor],
  ["boolean", ["feature-state", "click"], false],
  ["*", CHOROPLETH_FILL_OPACITY_SELECTED, opacityFactor],
  ["*", isSelectLinkMode ? SELECT_LINK_FILL_OPACITY : CHOROPLETH_FILL_OPACITY, opacityFactor],
];

export const getODFillColorExpression = (range: [number, number] = [0, Infinity]): Expression => [
  "case",
  [
    "!",
    [
      "all",
      [">=", ["coalesce", ["feature-state", "count"], 0], range[0]],
      ["<=", ["coalesce", ["feature-state", "count"], 0], range[1]],
    ],
  ],
  "#bdbdbd",
  ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
  ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
  ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
  ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
  ["==", ["feature-state", "color"], null],
  CHOROPLETH_FILL_COLOR,
  ["feature-state", "color"],
];

export const getODLayers = (
  zonesTileService: ODTileService,
  isSelectLinkMode?: boolean,
): {
  zoningLayers: (Layer & { level?: string; idField?: string })[];
  selectLinkSelectedZonesLayers: (Layer & { level?: string; idField?: string })[];
} => {
  const zoningLayers: (Layer & { level?: string; idField?: string })[] = [
    // Add county borders layer
    {
      id: OUT_ZONES_LAYER_LINE,
      type: "line",
      source: OUT_ZONES_SOURCE_ID,
      "source-layer": zonesTileService.layers[0].name,
      paint: {
        "line-color": COUNTY_BORDER_LINE_COLOR,
        "line-opacity": [
          "case",
          ["has", "country_name"],
          0, // transparent when country name is defined => Mexico or Canada
          COUNTY_BORDER_LINE_OPACITY, // some opacity when country name is not defined => US
        ],
        "line-width": COUNTY_BORDER_LINE_WIDTH,
      },
    },
    // Add county fill layer
    {
      id: OUT_ZONES_LAYER_FILL,
      type: "fill",
      source: OUT_ZONES_SOURCE_ID,
      "source-layer": zonesTileService.layers[0].name,
      paint: {
        "fill-color": "yellow",
        "fill-opacity": 0.2,
        "fill-outline-color": "black", // outline width can unfortunately not be set; black will be light gray at 0.2 opacity
      },
      filter: ["in", zonesTileService.layers[0].idField, ""],
    },
  ] as Layer[];
  const selectLinkSelectedZonesLayers: (Layer & { level?: string; idField?: string })[] = [];

  // Add choropleth fill layers
  zonesTileService.layers.forEach((layer) => {
    zoningLayers.push({
      id: layer.name,
      idField: layer.idField,
      type: "fill",
      source: `${OD_ZONE_SOURCE_ID} ${layer.name}`,
      "source-layer": layer.name,
      level: layer.level,
      layout: {
        visibility: "none",
      },
      paint: {
        "fill-color": getODFillColorExpression([0, Infinity]),
        "fill-opacity": getODFillOpacityExpression([0, Infinity], 1, isSelectLinkMode),
      },
    });
  });

  // Add choropleth line layers
  zonesTileService.layers.forEach((layer) => {
    zoningLayers.push({
      id: `${ZONE_BORDERS_LAYER_NAME} ${layer.name}`,
      idField: layer.idField,
      type: "line",
      source: `${OD_ZONE_SOURCE_ID} ${layer.name}`,
      "source-layer": layer.name,
      level: layer.level,
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
          ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
          ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
          ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
          ["boolean", ["feature-state", "hover"], false],
          ZONE_HOVER_HIGHLIGHT_COLOR,
          CHOROPLETH_LINE_COLOR,
        ],
        "line-opacity": CHOROPLETH_LINE_OPACITY,
        "line-width": ["case", ["boolean", ["feature-state", "hover"], false], 2, CHOROPLETH_LINE_WIDTH],
      },
    });
  });

  if (isSelectLinkMode) {
    zonesTileService.layers.forEach((layer) => {
      selectLinkSelectedZonesLayers.push({
        id: `${SELECTED_ZONES_LAYER_NAME} ${layer.name}`,
        idField: layer.idField,
        type: "line",
        source: `${OD_ZONE_SOURCE_ID} ${layer.name}`,
        "source-layer": layer.name,
        level: layer.level,
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
            CHOROPLETH_LINE_COLOR,
          ],
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            0.9,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            0.9,
            CHOROPLETH_LINE_OPACITY,
          ],
          "line-width": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            2,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            2,
            0,
          ],
        },
      });
    });
  }

  return { zoningLayers, selectLinkSelectedZonesLayers };
};
