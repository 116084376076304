import TabContext from "@mui/lab/TabContext";
import { ScreenlineImportValidationMessage } from "api/analytics/index";
import { SeverityIcon, Tabs } from "components_new";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { Map } from "mapbox-gl";
import { Dispatch, FC, SetStateAction, useState } from "react";

import { StyledTab, StyledTabPanel } from "features/zoning-uploader/CustomZoningInfo";

import { SelectorRoadClasses } from "components";

import { ValidationMessageSeverity } from "types";

import { ImportOptionsTab } from "./ImportOptionsTab";
import { ImportValidationTab } from "./ImportValidationTab";

enum ImportTabs {
  OPTIONS = "options",
  VALIDATION = "validation",
}

export interface ImportScreenlinesTabsProps {
  map: React.MutableRefObject<Map | null>;
  validationMessages: ScreenlineImportValidationMessage[];
  preparedFeatures: Feature<Geometry, GeoJsonProperties>[];
  validFeatures: Feature<Geometry, GeoJsonProperties>[];
  roadClasses: SelectorRoadClasses | null;
  setRoadClasses: Dispatch<SetStateAction<SelectorRoadClasses | null>>;
  appendToExistingScreenlines: boolean;
  setAppendToExistingScreenlines: Dispatch<SetStateAction<boolean>>;
  ignoreDisjointFeatures: boolean;
  setIgnoreDisjointFeatures: Dispatch<SetStateAction<boolean>>;
}

const getSeverity = (validationMessages: ScreenlineImportValidationMessage[]): ValidationMessageSeverity => {
  if (validationMessages?.some((message) => message.severity === ValidationMessageSeverity.Error))
    return ValidationMessageSeverity.Error;
  if (validationMessages?.some((message) => message.severity === ValidationMessageSeverity.Warning))
    return ValidationMessageSeverity.Warning;
  return ValidationMessageSeverity.Success;
};

export const ImportScreenlinesTabs: FC<ImportScreenlinesTabsProps> = ({
  map,
  validationMessages,
  preparedFeatures,
  validFeatures,
  roadClasses,
  setRoadClasses,
  appendToExistingScreenlines,
  setAppendToExistingScreenlines,
  ignoreDisjointFeatures,
  setIgnoreDisjointFeatures,
}) => {
  const [tabs, setTabs] = useState<ImportTabs>(ImportTabs.OPTIONS);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: ImportTabs) => {
    setTabs(newValue);
  };
  return (
    <TabContext value={tabs}>
      <Tabs value={tabs} onChange={handleTabsChange}>
        <StyledTab label="Options" value={ImportTabs.OPTIONS} />

        <StyledTab
          label="Validation"
          icon={<SeverityIcon severity={getSeverity(validationMessages)} fontSize="small" />}
          value={ImportTabs.VALIDATION}
        />
      </Tabs>
      <StyledTabPanel value={ImportTabs.OPTIONS}>
        <ImportOptionsTab
          roadClasses={roadClasses}
          setRoadClasses={setRoadClasses}
          appendToExistingScreenlines={appendToExistingScreenlines}
          setAppendToExistingScreenlines={setAppendToExistingScreenlines}
          ignoreDisjointFeatures={ignoreDisjointFeatures}
          setIgnoreDisjointFeatures={setIgnoreDisjointFeatures}
        />
      </StyledTabPanel>

      <StyledTabPanel value={ImportTabs.VALIDATION}>
        <ImportValidationTab map={map} validationMessages={validationMessages} preparedFeatures={preparedFeatures} />
      </StyledTabPanel>
    </TabContext>
  );
};
