import { Layer } from "mapbox-gl";

import {
  CHOROPLETH_FILL_COLOR,
  CHOROPLETH_FILL_OPACITY,
  CHOROPLETH_FILL_OPACITY_HOVER,
  CHOROPLETH_FILL_OPACITY_SELECTED,
  CHOROPLETH_LINE_COLOR,
  CHOROPLETH_LINE_OPACITY,
  COUNTY_BORDER_LINE_COLOR,
  COUNTY_BORDER_LINE_OPACITY,
  COUNTY_BORDER_LINE_WIDTH,
  ZONE_HOVER_HIGHLIGHT_COLOR,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_ZONE,
  ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY,
} from "features/map/layerColors";
import {
  CHOROPLETH_LINE_WIDTH,
  OUT_ZONES_LAYER_FILL,
  OUT_ZONES_LAYER_LINE,
  ZONE_BORDERS_LAYER_NAME,
} from "features/map/modules/od/map-data/od/layers";
import { OUT_ZONES_SOURCE_ID } from "features/map/modules/od/map-data/od/sources";

import { RoadVmtMetadata } from "types";

import { getAvailableLayers } from "../utils";
import { ROAD_VMT_ZONE_SOURCE_ID } from "./sources";

export const ROAD_VMT_LAYER_PREFIX = "ROAD_VMT_LAYER";

export const getVmtFillOpacityExpression = (opacityFactor: number = 1) => [
  "*",
  [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY,
    ["==", ["feature-state", "color"], null],
    0,
    ["boolean", ["feature-state", "hover"], false],
    CHOROPLETH_FILL_OPACITY_HOVER,
    ["boolean", ["feature-state", "click"], false],
    CHOROPLETH_FILL_OPACITY_SELECTED,
    CHOROPLETH_FILL_OPACITY,
  ],
  opacityFactor,
];

export const getCommonRoadVmtLayers = (roadVmtMetadata: RoadVmtMetadata): Layer[] => {
  const commonRoadVmtLayers: Layer[] = [];
  const externalZonesTileService = roadVmtMetadata.tileService;

  commonRoadVmtLayers.push({
    id: OUT_ZONES_LAYER_LINE,
    type: "line",
    source: OUT_ZONES_SOURCE_ID,
    "source-layer": externalZonesTileService.layers[0].name,
    paint: {
      "line-color": COUNTY_BORDER_LINE_COLOR,
      "line-opacity": [
        "case",
        ["has", "country_name"],
        0, // transparent when country name is defined => Mexico or Canada
        COUNTY_BORDER_LINE_OPACITY, // some opacity when country name is not defined => US
      ],
      "line-width": COUNTY_BORDER_LINE_WIDTH,
    },
  });

  commonRoadVmtLayers.push({
    id: OUT_ZONES_LAYER_FILL,
    type: "fill",
    source: OUT_ZONES_SOURCE_ID,
    "source-layer": externalZonesTileService.layers[0].name,
    paint: {
      "fill-color": "yellow",
      "fill-opacity": 0.2,
      "fill-outline-color": "black", // outline width can unfortunately not be set; black will be light gray at 0.2 opacity
    },
    filter: ["in", externalZonesTileService.layers[0].idField, ""],
  });

  return commonRoadVmtLayers;
};

export const getRoadVmtLayers = (roadVmtMetadata: RoadVmtMetadata): Layer[] => {
  const availableLayers = getAvailableLayers(roadVmtMetadata);
  const roadVmtLayers: Layer[] = [];

  availableLayers.forEach((layer) => {
    roadVmtLayers.push({
      id: `${ROAD_VMT_LAYER_PREFIX}_${layer.name}`,
      type: "fill",
      "source-layer": layer.name,
      source: ROAD_VMT_ZONE_SOURCE_ID,
      layout: {
        visibility: "visible",
      },
      paint: {
        "fill-color": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          ZONE_PERMANENT_HIGHLIGHT_COLOR_ZONE,
          ["==", ["feature-state", "color"], null],
          CHOROPLETH_FILL_COLOR,
          ["feature-state", "color"],
        ],
        "fill-opacity": getVmtFillOpacityExpression(),
      },
      level: layer.level,
    } as Layer);

    roadVmtLayers.push({
      id: `${ROAD_VMT_LAYER_PREFIX}_${ZONE_BORDERS_LAYER_NAME}_${layer.name}`,
      type: "line",
      source: ROAD_VMT_ZONE_SOURCE_ID,
      "source-layer": layer.name,
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          ZONE_PERMANENT_HIGHLIGHT_COLOR_ZONE,
          ["boolean", ["feature-state", "hover"], false],
          ZONE_HOVER_HIGHLIGHT_COLOR,
          CHOROPLETH_LINE_COLOR,
        ],
        "line-opacity": CHOROPLETH_LINE_OPACITY,
        "line-width": ["case", ["boolean", ["feature-state", "hover"], false], 2, CHOROPLETH_LINE_WIDTH],
      },
      level: layer.level,
    } as Layer);
  });

  return roadVmtLayers;
};
