import scaleCluster from "d3-scale-cluster";
import mapboxgl, { MapLayerMouseEvent, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { sequentialSchemes } from "features/map/layerColors";
import { ZONE_BORDERS_LAYER_NAME } from "features/map/modules/od/map-data/od/layers";
import { OD_ZONE_SOURCE_ID } from "features/map/modules/od/map-data/od/sources";
import { closeAllMapboxPopups, getLayerFromZoom, getPopupQueryType, getSqMiDensity } from "features/map/utils";

import { ODPopupProps } from "components";

import { Counts, ODTileLayer, ODTileService, QueryType, SelectedArea, ZoneIds, ZoneStats } from "types";

interface MapActionState {
  id: string | number;
  source: string;
}

export const getSelectLinkZonesHandlers = (
  map: mapboxgl.Map,
  tileService: ODTileService,
  ODIds: ZoneIds,
  counts: MutableRefObject<Counts>,
  queryType: MutableRefObject<QueryType>,
  colorScheme: MutableRefObject<string>,
  ODPopupRef: React.RefObject<HTMLDivElement>,
  mapboxODZoneHoverPopupRef: React.RefObject<mapboxgl.Popup>,
  ODZoningLevelBlockedZoom: MutableRefObject<number | null>,
  setODPopupProps: React.RefObject<Dispatch<SetStateAction<ODPopupProps | null>>>,
  showZoneCountsRef: MutableRefObject<boolean>,
  setColorScale: (colorScale: any) => void,
  setZoningLevel: (zoningLevel: ODTileLayer) => void,
  setSelectedZone: (selectedZone: SelectedArea | null) => void,
) => {
  let hoveredState: MapActionState | null = null;
  let zoningLevel = getLayerFromZoom(ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()), tileService.layers);
  setZoningLevel(zoningLevel as ODTileLayer);

  const getColorScale = (layerIDs: Map<string, ZoneStats>) => {
    const sqmi: number[] = [];

    for (const [zoneId, stats] of layerIDs) {
      sqmi.push(getSqMiDensity(counts.current?.zones?.get(zoneId) ?? 0, stats.sqKm));
    }

    const currentColorScheme = sequentialSchemes[colorScheme.current];
    const scale = scaleCluster().domain(sqmi).range(currentColorScheme);
    return scale;
  };

  const updateFeatureStatesByCounts = ({
    activeLayerLevel,
    activeLayerName,
    scale,
  }: {
    activeLayerLevel: string;
    activeLayerName: string;
    scale: (n: number) => string;
  }) => {
    if (!counts.current) return;

    const layerIDs = ODIds.get(activeLayerLevel) || new Map();

    for (const [zoneId, { sqKm }] of layerIDs) {
      const count = counts.current?.zones?.get(zoneId) ?? 0;
      const color = scale(getSqMiDensity(count ?? 0, sqKm));
      map.setFeatureState(
        {
          source: `${OD_ZONE_SOURCE_ID} ${activeLayerName}`,
          sourceLayer: activeLayerName,
          id: zoneId,
        },
        {
          color,
          count,
        },
      );
    }
  };

  const updateODCounts = () => {
    const activeLayer = getLayerFromZoom(
      ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()),
      tileService.layers,
    );
    const activeLayerName = activeLayer?.name || "";

    if (!activeLayer || !map.getLayer(activeLayerName) || !ODIds) return;

    const sourceId = `${OD_ZONE_SOURCE_ID} ${activeLayerName}`;

    if (map.getSource(sourceId) && map.isSourceLoaded(sourceId)) {
      const scale = getColorScale(ODIds.get(activeLayer.level) || new Map());
      setColorScale(scale);
      updateFeatureStatesByCounts({
        activeLayerName,
        activeLayerLevel: activeLayer.level,
        scale,
      });
    }
  };

  const updateLayersVisibility = () => {
    if (!map) {
      return;
    }

    const activeLayer = getLayerFromZoom(
      ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()),
      tileService.layers,
    );

    for (const layer of tileService.layers) {
      const layerName = layer.name;

      if (!map.getLayer(layerName)) {
        continue;
      }

      if (layerName !== activeLayer?.name) {
        map.setLayoutProperty(layerName, "visibility", "none");
        map.setLayoutProperty(`${ZONE_BORDERS_LAYER_NAME} ${layerName}`, "visibility", "none");
      } else if (
        layerName === activeLayer?.name &&
        map.getLayer(layerName) &&
        map.getLayoutProperty(layerName, "visibility") === "none"
      ) {
        map.setLayoutProperty(layerName, "visibility", showZoneCountsRef.current ? "visible" : "none");
        map.setLayoutProperty(`${ZONE_BORDERS_LAYER_NAME} ${layerName}`, "visibility", "visible");
      }
    }
  };

  const updateODCountsAndLayersVisibility = () => {
    if (!(map as any)?.style) return;

    updateLayersVisibility();
    updateODCounts();
  };

  const handleUpdateODCounts = (event: any) => {
    if (!event.isSourceLoaded) return;

    const activeLayer = getLayerFromZoom(
      ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()),
      tileService.layers,
    );
    const activeLayerName = activeLayer?.name || "";

    if (!activeLayer || !ODIds) return;

    const sourceId = `${OD_ZONE_SOURCE_ID} ${activeLayerName}`;

    if (event.sourceId === sourceId) {
      updateODCounts();
    }
  };

  const handleMousemove = (e: MapLayerMouseEvent) => {
    const activeLayer =
      zoningLevel ||
      getLayerFromZoom(ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()), tileService.layers);

    if (!activeLayer) return;

    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = feature.properties?.[activeLayer.idField] || feature.id;
      const count = counts.current?.zones?.get(featureId) || 0;

      if (hoveredState) {
        map.setFeatureState(
          {
            source: `${OD_ZONE_SOURCE_ID} ${hoveredState.source}`,
            sourceLayer: hoveredState.source,
            id: hoveredState.id,
          },
          { hover: false },
        );

        map.getCanvas().style.cursor = "crosshair";
      }

      hoveredState = { id: featureId, source: activeLayer.name };
      map.setFeatureState(
        {
          source: `${OD_ZONE_SOURCE_ID} ${activeLayer.name}`,
          sourceLayer: activeLayer.name,
          id: featureId,
        },
        { hover: true },
      );

      if (typeof setODPopupProps.current === "function") {
        setODPopupProps.current({
          count,
          countByDensity: getSqMiDensity(count, feature.properties?.area_sqkm),
          type: getPopupQueryType(undefined, featureId, queryType.current),
        });
      }

      if (mapboxODZoneHoverPopupRef.current) {
        mapboxODZoneHoverPopupRef.current.remove();
      }

      if (ODPopupRef.current) {
        (mapboxODZoneHoverPopupRef.current as mapboxgl.Popup) = new Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 15,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(ODPopupRef.current as Node)
          .addTo(map);
      }
    }
  };

  const handleMouseleave = () => {
    if (hoveredState) {
      map.setFeatureState(
        {
          source: `${OD_ZONE_SOURCE_ID} ${hoveredState.source}`,
          sourceLayer: hoveredState.source,
          id: hoveredState.id,
        },
        { hover: false },
      );
    }

    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }

    hoveredState = null;
    map.getCanvas().style.cursor = "grab";
  };

  const handleMouseClick = (e: MapLayerMouseEvent) => {
    const activeLayer = getLayerFromZoom(
      ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()),
      tileService.layers,
    );

    if (!activeLayer || !ODIds) return;

    const zoom = e.target.getZoom();

    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = feature.properties?.[activeLayer.idField] || feature.id;

      setSelectedZone({
        id: featureId,
        itemType: "zone",
        level: activeLayer.level,
        zoom,
      });
    }

    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }
  };

  const handleZoom = () => {
    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }

    if (hoveredState) {
      map.setFeatureState(
        {
          source: `${OD_ZONE_SOURCE_ID} ${hoveredState.source}`,
          sourceLayer: hoveredState.source,
          id: hoveredState.id,
        },
        { hover: false },
      );

      hoveredState = null;
    }

    const activeLayer = getLayerFromZoom(
      ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()),
      tileService.layers,
    );

    if (counts.current && activeLayer && zoningLevel?.name !== activeLayer.name && !ODZoningLevelBlockedZoom.current) {
      zoningLevel = activeLayer;
      setColorScale(getColorScale(ODIds.get(activeLayer.level) || new Map()));
      setZoningLevel(activeLayer);
      updateLayersVisibility();
    }
  };

  map.on("sourcedata", handleUpdateODCounts);
  map.on(
    "mousemove",
    tileService.layers.map((l) => l.name),
    handleMousemove,
  );
  map.on(
    "mouseleave",
    tileService.layers.map((l) => l.name),
    handleMouseleave,
  );
  map.on("click", [...tileService.layers.map((l) => l.name)], handleMouseClick);
  map.on("zoom", handleZoom);

  return {
    updateODCountsAndLayersVisibility,
    cleanODHandlers: () => {
      closeAllMapboxPopups(map);

      if (ODPopupRef.current) {
        ODPopupRef.current.remove();
      }

      map.off("sourcedata", handleUpdateODCounts);
      map.off(
        "mousemove",
        tileService.layers.map((l) => l.name),
        handleMousemove,
      );
      map.off(
        "mouseleave",
        tileService.layers.map((l) => l.name),
        handleMouseleave,
      );
      map.off("click", [...tileService.layers.map((l) => l.name)], handleMouseClick);
      map.off("zoom", handleZoom);
    },
  };
};
