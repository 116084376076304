import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { scaleLog } from "d3-scale";
import { Feature, FeatureCollection, Geometry } from "geojson";
import uniqBy from "lodash/uniqBy";
import { AnySourceData, Layer } from "mapbox-gl";
import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters } from "features/filters/utils";
import { getAvailableZoomLevels, getCustomFlowColor, getFlowPattern, getLayerFromZoom } from "features/map/utils";

import { analyticsActions } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";
import { mapActions } from "store/sections/map";

import {
  DatasetMetadata,
  FiltersType,
  Flow,
  FlowsSettings,
  FocusAreaItem,
  MeasureType,
  ODMetadata,
  ODTileLayer,
  QueryType,
  SelectedArea,
  ZoneDetails,
} from "types";

import { GATES_LAYER_ID, getCircleColorExpression } from "../gates/map-data/layers";
import { getODFillColorExpression, getODFillOpacityExpression } from "./map-data/od/layers";
import { getTopFlowLayerIds, getTopFlowLayers } from "./map-data/top-flows/layers";
import { getTopFlowSourceIds, getTopFlowSources } from "./map-data/top-flows/sources";

interface FetchZoneDetailsConfig {
  selectedZoneId: string;
  measure: MeasureType;
  level: string;
  isGate: boolean;
  breakdowns: {
    dimensions: string[];
    includeUnfiltered: boolean;
  }[];
  filters: FiltersType;
}

export const useGetODIds = (
  metadata: ODMetadata | DatasetMetadata | null,
  selectedFocusArea: FocusAreaItem | null,
  timePeriod: string | null,
) => {
  const dispatch = useDispatch();

  return useCallback(
    (datasetId?: string) => {
      if (metadata?.tileService?.layers) {
        if (!datasetId && timePeriod) {
          dispatch(
            analyticsActions.fetchODIds(getAvailableZoomLevels(metadata?.tileService?.layers), {
              timePeriod,
              areaOfInterest: null,
            }),
          );
        }

        if (datasetId) {
          dispatch(
            analyticsActions.fetchDatasetIds(datasetId, getAvailableZoomLevels(metadata?.tileService?.layers), {
              areaOfInterest: null,
            }),
          );
        }
      }
    },
    [metadata?.tileService?.layers, timePeriod, dispatch],
  );
};

export const useGetODCounts = (
  queryType: QueryType,
  metadata: ODMetadata | DatasetMetadata | null,
  selectedFocusArea: FocusAreaItem | null,
  timePeriod: string | null,
) => {
  const dispatch = useDispatch();

  return useCallback(
    (filters: FiltersType, datasetId?: string) => {
      if (metadata?.tileService?.layers && timePeriod) {
        if (!datasetId) {
          dispatch(
            analyticsActions.fetchODCounts(getAvailableZoomLevels(metadata.tileService.layers), {
              timePeriod,
              measure: MeasureType.AADT,
              queryType,
              filter: buildFilters(filters),
              areaOfInterest: null,
            }),
          );
        }

        if (datasetId) {
          dispatch(
            analyticsActions.fetchDatasetCounts(datasetId, getAvailableZoomLevels(metadata.tileService.layers), {
              measure: MeasureType.AADT,
              queryType,
              filter: buildFilters(filters),
              areaOfInterest: null,
            }),
          );
        }
      }
    },
    [queryType, metadata, timePeriod, dispatch],
  );
};

export const useGetODCountsByZoneId = (queryType: QueryType, timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(
    (config: any) => {
      if (!config.datasetId && timePeriod) {
        dispatch(
          analyticsActions.fetchODCountsByZoneId({
            selectedZoneId: config.selectedZoneId,
            timePeriod,
            level: config.level,
            measure: config.requestMeasure,
            queryType,
            filter: buildFilters(config.filters),
            areaOfInterest: config.filter,
          }),
        );
      }
      if (config.datasetId) {
        dispatch(
          analyticsActions.fetchDatasetCountsByZoneId(config.datasetId, {
            selectedId: config.selectedZoneId,
            isGate: config.isGate,
            level: config.level,
            measure: config.requestMeasure,
            queryType,
            filter: buildFilters(config.filters),
            areaOfInterest: config.filter,
            compression: "gzip",
          }),
        );
      }
    },
    [queryType, timePeriod, dispatch],
  );
};

export const useHandleSelectZone = () => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedZone: SelectedArea | null) => {
      dispatch(analyticsActions.setSelectedZone(selectedZone));
    },
    [dispatch],
  );
};

export const useGetODZoneDetails = (queryType: QueryType, timePeriod: string | null) => {
  const dispatch = useDispatch();

  return useCallback(
    (config: FetchZoneDetailsConfig, flowsSettings: FlowsSettings, datasetId?: string) => {
      if (timePeriod && !datasetId) {
        dispatch(
          analyticsActions.fetchODZoneDetails({
            zoneId: config.selectedZoneId,
            timePeriod,
            measure: config.measure,
            level: config.level,
            summaries: {
              [queryType]: {
                filteredTotal: true,
                unfilteredTotal: false,
                breakdowns: config.breakdowns,
              },
            },
            topFlows: {
              [queryType]: flowsSettings,
            },
            filter: buildFilters(config.filters),
          }),
        );
      } else if (datasetId) {
        dispatch(
          analyticsActions.fetchDatasetZoneDetails(datasetId, {
            id: config.selectedZoneId,
            timePeriod,
            measure: config.measure,
            level: config.level,
            isGate: config.isGate,
            summaries: {
              [queryType]: {
                filteredTotal: true,
                unfilteredTotal: false,
                breakdowns: config.breakdowns,
              },
            },
            topFlows: {
              [queryType]: flowsSettings,
            },
            filter: buildFilters(config.filters),
          }),
        );
      }
    },
    [dispatch, queryType, timePeriod],
  );
};

export const useCloseODAnalyticsPanel = (handleSelectZone: (selectedZone: SelectedArea | null) => void) => {
  return useCallback(
    (selectedZone: SelectedArea | null) => {
      if (selectedZone) {
        handleSelectZone(null);
      }
    },
    [handleSelectZone],
  );
};

const getArrowsGeoJson = (
  zoneDetails: ZoneDetails,
  queryType: QueryType,
  selectedZoneId: string,
  selectedZoneType: string,
  getWidthInPixelsByVolume: (value: number) => number,
): FeatureCollection => {
  const flows = zoneDetails.topFlows[queryType].flows;

  return {
    type: "FeatureCollection",
    features: flows
      .filter((flow) => flow.lat && flow.lon)
      .map((flow, index) => {
        return {
          type: "Feature",
          id: flow.id,
          properties: {
            id: flow.id,
            value: flow.value,
            width: getWidthInPixelsByVolume(flow.value),
            pattern: getFlowPattern(flow, selectedZoneId, selectedZoneType),
            level: flow.level,
            areaName: flow.areaName,
            levelName: flow.levelName,
            countryName: flow.countryName,
            isGate: flow.isGate,
            index: index + 1,
            external: flow.external,
            selectedZoneId,
            selectedZoneType,
          },
          geometry: {
            type: "LineString",
            coordinates:
              queryType === QueryType.INCOMING
                ? [
                    [flow.lon, flow.lat],
                    [zoneDetails.lon, zoneDetails.lat],
                  ]
                : [
                    [zoneDetails.lon, zoneDetails.lat],
                    [flow.lon, flow.lat],
                  ],
          },
        };
      }) as Array<Feature<Geometry, any>>,
  };
};

const getCentroidsGeoJson = (
  zoneDetails: ZoneDetails,
  queryType: QueryType,
  selectedZoneId: string,
  selectedZoneType: string,
  getWidthInPixelsByVolume: (value: number) => number,
): FeatureCollection => {
  const flows = zoneDetails.topFlows[queryType].flows;

  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          id: `${zoneDetails.zoneId}-centroid`,
          radius: 10,
          color: "#6c2167",
        },
        geometry: {
          type: "Point",
          coordinates: [zoneDetails.lon, zoneDetails.lat],
        },
      },
      ...(uniqBy(flows, (flow) => [flow.lat, flow.lon].join("-"))
        .filter((flow) => flow.lat && flow.lon)
        .map((flow) => {
          return {
            type: "Feature",
            properties: {
              id: `${flow.id}-${flow.external ? "external" : "internal"}-centroid`,
              radius: getWidthInPixelsByVolume(flow.value) / 2,
              color: getCustomFlowColor(getFlowPattern(flow, selectedZoneId, selectedZoneType)),
            },
            geometry: {
              type: "Point",
              coordinates: [flow.lon, flow.lat],
            },
          };
        }) as Array<Feature<Geometry, any>>),
    ],
  };
};

export const useShowTopFlows = (layerManagerRef: MutableRefObject<LayerManager | null>) => {
  return useCallback(
    (zoneDetails: ZoneDetails, queryType: QueryType, selectedZone: SelectedArea | null) => {
      if (!zoneDetails.topFlows[queryType] || !layerManagerRef.current) return;

      const flows: Flow[] = zoneDetails.topFlows[queryType].flows;
      const { min, max } = flows.reduce(
        (acc: { min: number; max: number }, { value }) => {
          return {
            min: value ? Math.min(acc.min, value) : acc.min,
            max: Math.max(acc.max, value),
          };
        },
        { min: Infinity, max: 0 },
      );
      const logScale = scaleLog().domain([min, max]).range([5, 30]);
      const getWidthInPixelsByVolume = (value: number) => logScale(value);
      const getArrowsGeoJsonData = getArrowsGeoJson(
        zoneDetails,
        queryType,
        selectedZone?.id || "",
        selectedZone?.itemType || "",
        getWidthInPixelsByVolume,
      );
      const centroidsGeoJsonData = getCentroidsGeoJson(
        zoneDetails,
        queryType,
        selectedZone?.id || "",
        selectedZone?.itemType || "",
        getWidthInPixelsByVolume,
      );

      const topFlowsSources = getTopFlowSources(getArrowsGeoJsonData, centroidsGeoJsonData) as {
        id: string;
        source: AnySourceData;
      }[];
      const topFlowsLayers = getTopFlowLayers() as Layer[];

      layerManagerRef.current.addSources(topFlowsSources);
      layerManagerRef.current.addLayers(topFlowsLayers);
    },
    [layerManagerRef],
  );
};

export const useHideTopFlows = (layerManagerRef: MutableRefObject<LayerManager | null>) => {
  return useCallback(() => {
    if (!layerManagerRef.current) return;

    const topFlowsSourceIds = getTopFlowSourceIds();
    const topFlowsLayerIds = getTopFlowLayerIds();

    layerManagerRef.current.removeLayers(topFlowsLayerIds);
    layerManagerRef.current.removeSources(topFlowsSourceIds);
  }, [layerManagerRef]);
};

export const useChangeShowZoneCounts = (
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
  layers: ODTileLayer[] | undefined,
  ODZoningLevelBlockedZoom: MutableRefObject<number | null>,
) => {
  return useCallback(
    (showZoneCounts: boolean) => {
      if (mapboxLayerManager.current) {
        const map = mapboxLayerManager.current.getMapInstance()!;
        const activeLayer = getLayerFromZoom(ODZoningLevelBlockedZoom.current ?? Math.floor(map.getZoom()), layers);

        if (
          activeLayer?.name &&
          map.getLayoutProperty(activeLayer.name, "visibility") === "visible" &&
          !showZoneCounts
        ) {
          mapboxLayerManager.current?.updateLayerLayout(activeLayer.name, "visibility", "none");
        } else if (
          activeLayer?.name &&
          map.getLayoutProperty(activeLayer.name, "visibility") === "none" &&
          showZoneCounts
        ) {
          mapboxLayerManager.current?.updateLayerLayout(activeLayer.name, "visibility", "visible");
        }
      }
    },
    [mapboxLayerManager, layers, ODZoningLevelBlockedZoom],
  );
};

export const useHandleSetColorScale = () => {
  const dispatch = useDispatch();
  return useCallback(
    (colorScale: any) => {
      dispatch(mapActions.setColorScale(colorScale));
    },
    [dispatch],
  );
};

export const useHandleSetZoningLevel = () => {
  const dispatch = useDispatch();
  return useCallback(
    (zoningLevel: ODTileLayer) => {
      dispatch(filtersActions.setZoningLevel(zoningLevel));
    },
    [dispatch],
  );
};

export const useFilterZonesByRange = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (range: [number, number], layer: ODTileLayer, opacityFactor: number, isSelectLinkMode?: boolean) => {
      if (map.current!.getLayer(layer.name)) {
        mapboxLayerManager.current?.updateLayerPaint(
          layer.name,
          "fill-opacity",
          getODFillOpacityExpression(range, opacityFactor, isSelectLinkMode),
        );
        mapboxLayerManager.current?.updateLayerPaint(layer.name, "fill-color", getODFillColorExpression(range));
      }
    },
    [map, mapboxLayerManager],
  );
};

export const useFilterGatesByRange = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (range: [number, number]) => {
      if (map.current!.getLayer(GATES_LAYER_ID)) {
        mapboxLayerManager.current?.updateLayerPaint(GATES_LAYER_ID, "circle-color", getCircleColorExpression(range));
      }
    },
    [map, mapboxLayerManager],
  );
};

export const useChangeZonesFillOpacity = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: MutableRefObject<LayerManager | null>,
) => {
  return useCallback(
    (layers: ODTileLayer[] | undefined, range: [number, number], opacityFactor: number, isSelectLinkMode?: boolean) => {
      layers?.forEach((layer) => {
        if (map.current!.getLayer(layer.name)) {
          mapboxLayerManager.current?.updateLayerPaint(
            layer.name,
            "fill-opacity",
            getODFillOpacityExpression(range, opacityFactor, isSelectLinkMode),
          );
        }
      });
    },
    [map, mapboxLayerManager],
  );
};
