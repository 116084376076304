import { CenterFocusStrong, Delete, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, ListItem, ListItemButton, ListItemButtonProps, Stack, Typography } from "@mui/material";
import { ScreenlineValidationSummary } from "api/analytics/index.d";
import { IconButton, SeverityIcon, ValidationMessagesTooltip } from "components_new";
import { FC, useRef } from "react";

import { ValidationMessageSeverity } from "types";

export interface ScreenlineItemProps extends ListItemButtonProps {
  screenlineId: string;
  name: string | undefined;
  selected?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  visible?: boolean;
  validationSummary?: ScreenlineValidationSummary;
  handleZoomOnBounds: () => void;
  handleDeleteScreenline: () => void;
  handleToggleScreenlineVisibility: () => void;
}

export const ScreenlineItem: FC<ScreenlineItemProps> = ({
  screenlineId,
  name = "No name",
  selected,
  hovered,
  disabled,
  visible,
  validationSummary,
  onClick,
  handleZoomOnBounds,
  handleDeleteScreenline,
  handleToggleScreenlineVisibility,
  ...props
}) => {
  const ListItemButtonRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    handleZoomOnBounds();
  };

  return (
    <ListItem
      disablePadding
      disableGutters
      secondaryAction={
        <Stack direction="row" spacing={1} marginRight={2}>
          <IconButton color="secondary" edge="end" size="small">
            {validationSummary?.maximumMessageSeverity && (
              <ValidationMessagesTooltip
                title={
                  <Box paddingLeft={2}>
                    <ul>
                      {validationSummary?.messages.map((message, index) => (
                        <Typography key={index} fontSize={11} component={"li"}>
                          {message.text}
                        </Typography>
                      ))}
                    </ul>
                  </Box>
                }
              >
                <SeverityIcon
                  fontSize="inherit"
                  severity={validationSummary?.maximumMessageSeverity as ValidationMessageSeverity}
                />
              </ValidationMessagesTooltip>
            )}
          </IconButton>

          <IconButton color="secondary" edge="end" size="small" onClick={handleToggleScreenlineVisibility}>
            {visible ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
          </IconButton>
          <IconButton color="secondary" edge="end" size="small" onClick={handleClick}>
            <CenterFocusStrong fontSize="inherit" />
          </IconButton>
          <IconButton color="secondary" edge="end" size="small" disabled={disabled} onClick={handleDeleteScreenline}>
            <Delete fontSize="inherit" />
          </IconButton>
        </Stack>
      }
    >
      <ListItemButton
        dense
        ref={ListItemButtonRef}
        color={"secondary"}
        selected={selected}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        <Typography fontSize={12} fontWeight={600} marginRight={1} minWidth={"40px"} maxWidth={"40px"} noWrap>
          {screenlineId}
        </Typography>
        <Typography
          fontSize={12}
          noWrap
          color={"text.secondary"}
          maxWidth={Boolean(validationSummary?.maximumMessageSeverity) ? 170 : 180}
        >
          {name || "No name"}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};
