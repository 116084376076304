import { UniqueIdentifier } from "@dnd-kit/core";
import protobuf from "protobufjs";

import { getRoadCategoryFromFactype } from "store/utils";

// TODO move this file to the analytics folder (after the refactoring)
import {
  AddGateArguments,
  AddGateResponse,
  AoiExportRequest,
  AreaAccuracyScatterPlotItem,
  AreaAccuracyScatterPlotRequest,
  AreaAccuracyTableRequest,
  AreaAccuracyTableResponse,
  CatalogItem,
  ConfigDocument,
  ConfigDocumentType,
  CorridorEdgeCounts,
  CorridorHeatmapConfiguration,
  CorridorHeatmapConfigurationRequestParams,
  CorridorMetadata,
  CorridorNodeCounts,
  Counts,
  CountsByZoneId,
  CreateDatasetPayload,
  CustomDataset,
  CustomDatasetRepository,
  CustomZoningSelectorItemsResponse,
  DatasetCountsArguments,
  DatasetCountsByZoneIdArguments,
  DatasetFolder,
  DatasetFolders,
  DatasetFoldersResponse,
  DatasetGate,
  DatasetMetadata,
  DatasetZoneDetailsArguments,
  EdgesRangeRequest,
  ExportJobs,
  FocusAreaItem,
  Gate,
  GateCoordinates,
  GateCoordinatesArguments,
  GenerateGatesArguments,
  MeasureRange,
  MeasureRangeRequest,
  MeasureRangeResponse,
  NumZonesResponse, //Export
  ODCountsArguments,
  ODCountsByZoneIdArguments,
  ODDatasetComputation,
  ODDatasetConfig,
  ODDatasetExportRequest,
  ODDatasetValidation,
  ODMeasureRange,
  ODMetadata,
  ODMetadataArguments,
  OdDatasetConfigSaveAsRequest,
  OdNumZonesRequest,
  PreparedZoningConfigRequest,
  RoadMeasureRangeRequest,
  SegmentVolumeDetail,
  SegmentFeatureDetailsRequest,
  SegmentIndexesRequest,
  RoadSegmentIndexesByRoadClass,
  RoadSegmentIndexesWithFactype,
  SegmentVolumeDetailsRequest,
  RoadVmtMetadata,
  RoadVmtMetadataRequest,
  RoadVmtZoneCounts,
  RoadVmtZoneCountsRequest,
  RoadVmtZoneDetails,
  RoadVmtZoneDetailsRequest,
  RoadsMetadataArguments,
  RoadsMetadataResponse,
  RoadsVolumes,
  SegmentVolumesRequest,
  SegmentFeatureDetailsResponse,
  SegmentStatsRequest,
  SegmentStatsResponse,
  SelectLinkConfig,
  SelectLinkConfigCreationRequest,
  SelectLinkConfigUpdateRequest,
  SelectLinkMetadataResponse,
  SelectLinkMetadataArguments,
  SelectLinkSegmentCountsRequest,
  ServiceOverlay,
  ShapesInputFormat,
  SubareaPolygon,
  SubareaPolygonArguments,
  SubareaState,
  SubareaStateArguments,
  UpdateODDatasetConfigPayload,
  UploadZoningResponse,
  ZoneDetails,
  ZoneDetailsArguments,
  ZoneIdAreaPairs,
  ZoneIds,
  ZoneIdsArguments,
  Zoning,
  ZoningItem,
} from "types";

import RestHandler from "./RestHandler";
import {
  AOIScreenlineCountsRequest,
  AOIScreenlineDetailsRequest,
  CandidateScreenlineIntersectionsRequest,
  CandidateScreenlineIntersectionsResponse,
  ConfigDocumentCreationRequest,
  ConfigDocumentPayloadUpdateRequest,
  ConfigDocumentUpdateRequest,
  ConvertFeaturesToScreenlinesRequest,
  ConvertFeaturesToScreenlinesResponse,
  CreateScreenlineRequest,
  CreateScreenlineResponse,
  GetConfigDocumentPayloadResponse,
  ReadScreenlineShapefileResponse,
  ScreenlineCountsResponse,
  ScreenlineDetailsResponse,
  ScreenlineValidationRequest,
  ScreenlineValidationResponse,
  ScreenlineValidationSummaryRequest,
  ScreenlineValidationSummaryResponse,
  SearchConfigDocumentsResponse,
  SegmentIndexesForIdsRequest,
  UpdateScreenlineGeometryRequest,
  UpdateScreenlineGeometryResponse,
} from "./analytics/index.d";
import {
  getDatasetVolumesProtobuf,
  getVolumesProtobuf,
  mergeLicensedAreaAndDatasetItems,
  mergeZoningLevelsCounts,
  parseCounts,
  parseNumbers,
  parseSegmentIndexes,
  parseZoneIds,
} from "./helper";
import memoryStore, { MemoryStoreKeys } from "./memoryStore";

export interface AnalyticsApiType {
  getDatasetZoneDetails(datasetId: string, config: DatasetZoneDetailsArguments): Promise<ZoneDetails>;
  getODZoneDetails(config: ZoneDetailsArguments): Promise<ZoneDetails>;
  getODIds(levels: string[], config: ZoneIdsArguments): Promise<ZoneIds>;
  getODCounts(levels: string[], config: ODCountsArguments): Promise<Counts>;
  getODCountsByZoneId(config: ODCountsByZoneIdArguments): Promise<CountsByZoneId>;
  getODMetadata(config: ODMetadataArguments): Promise<ODMetadata>;
  getSubareaState(config: SubareaStateArguments): Promise<SubareaState>;
  getSubareaPolygon(config: SubareaPolygonArguments): Promise<SubareaPolygon>;
  getGeneratedGates(config: GenerateGatesArguments): Promise<Gate[]>;
  getAddGate(config: AddGateArguments): Promise<AddGateResponse>;
  getGateCoordinates: (config: GateCoordinatesArguments) => Promise<GateCoordinates>;
  getRoadsMetadata(config: RoadsMetadataArguments): Promise<RoadsMetadataResponse>;
  getRoadsVolumes(config: SegmentVolumesRequest): Promise<RoadsVolumes>;
  getRoadSegmentIndexes(config: SegmentIndexesRequest): Promise<boolean>;
  getRoadSegmentStats(config: SegmentStatsRequest): Promise<SegmentStatsResponse>;
  getSegmentVolumeDetails(config: SegmentVolumeDetailsRequest): Promise<SegmentVolumeDetail[]>;
  getSegmentsFeatureDetails(config: SegmentFeatureDetailsRequest): Promise<SegmentFeatureDetailsResponse>;
  getFocusAreasAndDatasets(): Promise<FocusAreaItem[]>;
  getSegmentIndexesForIds(config: SegmentIndexesForIdsRequest): Promise<Map<string, number>>;

  // Datasets
  getDatasetCounts(datasetId: string, levels: string[], config: DatasetCountsArguments): Promise<Counts>;
  getDatasetIds(datasetId: string, levels: string[], config: ZoneIdsArguments): Promise<ZoneIds>;
  getDatasetCountsByZoneId(datasetId: string, config: DatasetCountsByZoneIdArguments): Promise<CountsByZoneId>;
  getDatasetMetadata(datasetId: string): Promise<DatasetMetadata>;
  getDatasetGates(datasetId: string): Promise<DatasetGate[]>;
  getDatasetFolders(): Promise<DatasetFolders>;
  addDatasetFolder(folderName: string): Promise<DatasetFolder>;
  renameDatasetFolder(folderId: UniqueIdentifier, folderName: string): Promise<DatasetFolder>;
  deleteDatasetFolder(folderId: UniqueIdentifier): Promise<boolean>;
  changeFolderIndex(folderId: UniqueIdentifier, index: number): Promise<CustomDatasetRepository>;
  addDatasetInFolder(config: CreateDatasetPayload): Promise<CustomDataset>;
  renameDataset(datasetId: UniqueIdentifier, datasetName: string): Promise<CustomDataset>;
  deleteDataset(datasetId: UniqueIdentifier): Promise<boolean>;
  changeCatalogItemIndex(
    folderId: UniqueIdentifier,
    catalogItemId: UniqueIdentifier,
    index: number,
  ): Promise<CatalogItem[]>;
  copyDataset(
    datasetId: UniqueIdentifier,
    datasetName: string,
    timePeriod: string,
    discardGates?: boolean,
  ): Promise<{ copiedDatasetId: string; items: CatalogItem[] }>;
  getODDatasetConfig(datasetId: UniqueIdentifier): Promise<ODDatasetConfig>;
  updateODDatasetConfig(datasetId: string, config: UpdateODDatasetConfigPayload): Promise<ODDatasetConfig>;
  saveAsODDatasetConfig(config: OdDatasetConfigSaveAsRequest): Promise<ODDatasetConfig>;
  validateODDatasetConfig(datasetConfigId: string): Promise<ODDatasetValidation>;
  computeODDataset(datasetConfigId: string, notifyByEmail: boolean): Promise<ODDatasetComputation>;
  cancelODDatasetComputation(datasetConfigId: string): Promise<ODDatasetConfig>;
  getFocusAreas(customZoningId?: string | null, forDatasetCreation?: boolean): Promise<FocusAreaItem[]>;
  getRoadsMeasureRange(request: RoadMeasureRangeRequest): Promise<MeasureRange>;
  getODMeasureRange(levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange>;
  getDatasetMeasureRange(datasetId: string, levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange>;

  // Custom zoning
  uploadZoningShapefiles(shapefiles: Blob, formats: ShapesInputFormat): Promise<UploadZoningResponse>;
  prepareZoning(config: PreparedZoningConfigRequest): Promise<UploadZoningResponse>;
  createZoning(zoningId: string, folderId: string, name: string, description: string): Promise<any>;
  deleteZoning(zoningId: string): Promise<boolean>;
  deleteCustomZoning(zoningId: string): Promise<boolean>;
  getCustomZoningSelectorList(): Promise<CustomZoningSelectorItemsResponse>;
  editZoning(zoningItemId: string, name: string, description: string): Promise<ZoningItem>;
  getZoning(zoningId: string): Promise<Zoning>;

  //Export
  getExportJobs(): Promise<ExportJobs>;
  addDatasetExportJob(datasetId: string, request: ODDatasetExportRequest): Promise<string>;
  addAoiExportJob(request: AoiExportRequest): Promise<string>;
  getNumZones(request: OdNumZonesRequest): Promise<NumZonesResponse>;
  recreateExportJob(uuid: string): Promise<string>;

  // Mapbox Geocoding API
  getGeocoding(request: { searchText: string; token: string; proximity: string }): Promise<any>;

  // Select link analysis
  getSelectLinkSegmentCounts(config: SelectLinkSegmentCountsRequest): Promise<RoadsVolumes>;
  listSelectLinkConfigs(): Promise<SelectLinkConfig[]>;
  createSelectLinkConfig(request: SelectLinkConfigCreationRequest): Promise<SelectLinkConfig>;
  fetchSelectLinkConfig(configId: string): Promise<SelectLinkConfig>;
  updateSelectLinkConfig(configId: string, request: SelectLinkConfigUpdateRequest): Promise<SelectLinkConfig>;
  deleteSelectLinkConfig(configId: string): Promise<SelectLinkConfig>;
  getSelectLinkMetadata(config: SelectLinkMetadataArguments): Promise<SelectLinkMetadataResponse>;

  // Corridor Discovery
  fetchCorridorMetadata(config: any): Promise<CorridorMetadata>;
  fetchCorridorEdgeIds(config: any): Promise<number[]>;
  fetchCorridorEdgeCounts(config: any): Promise<CorridorEdgeCounts>;
  fetchCorridorEdgeAvailableRange(config: EdgesRangeRequest): Promise<MeasureRangeResponse>;
  fetchCorridorEdgeDetails(config: any): Promise<any>;
  fetchCorridorNodeIds(config: any): Promise<number[]>;
  fetchCorridorNodeCounts(config: any): Promise<CorridorNodeCounts>;
  fetchCorridorHeatmapConfiguration(
    config: CorridorHeatmapConfigurationRequestParams,
  ): Promise<CorridorHeatmapConfiguration>;
  fetchServiceOverlayLayers(): Promise<ServiceOverlay[]>;

  // Road VMT Analysis
  fetchRoadVmtMetadata(config: RoadVmtMetadataRequest): Promise<RoadVmtMetadata>;
  fetchRoadVmtZoneCounts(level: string, config: RoadVmtZoneCountsRequest): Promise<RoadVmtZoneCounts>;
  fetchRoadVmtZoneDetails(config: RoadVmtZoneDetailsRequest): Promise<RoadVmtZoneDetails>;

  // Configuration documents
  createConfigDocument(config: ConfigDocumentCreationRequest): Promise<ConfigDocument>;
  searchConfigDocuments(
    type: ConfigDocumentType,
    filterByLicensedArea: boolean,
    excludeEmptyFolders: boolean,
    folderId: string | null,
    timePeriod: string | null,
  ): Promise<SearchConfigDocumentsResponse>;
  getConfigDocument(configDocumentId: string): Promise<ConfigDocument>;
  getConfigDocumentPayload(configDocumentId: string): Promise<GetConfigDocumentPayloadResponse>;
  updateConfigDocument(configDocumentId: string, config: ConfigDocumentUpdateRequest): Promise<ConfigDocument>;
  updateConfigDocumentPayload(
    configDocumentId: string,
    config: ConfigDocumentPayloadUpdateRequest,
  ): Promise<ConfigDocument>;
  deleteConfigDocument(configDocumentId: string): Promise<ConfigDocument>;

  // Screenlines
  // - creation / editing
  candidateScreenlineIntersections(
    config: CandidateScreenlineIntersectionsRequest,
  ): Promise<CandidateScreenlineIntersectionsResponse>;
  createScreenline(config: CreateScreenlineRequest): Promise<CreateScreenlineResponse>;
  validateScreenline(config: ScreenlineValidationRequest): Promise<ScreenlineValidationResponse>;
  validateScreenlines(config: ScreenlineValidationSummaryRequest): Promise<ScreenlineValidationSummaryResponse>;
  updateScreenlineGeometry(config: UpdateScreenlineGeometryRequest): Promise<UpdateScreenlineGeometryResponse>;
  // - importing
  readScreenlineShapefile(zippedShapefile: Blob): Promise<ReadScreenlineShapefileResponse>;
  convertFeaturesToScreenlines(
    config: ConvertFeaturesToScreenlinesRequest,
  ): Promise<ConvertFeaturesToScreenlinesResponse>;
  // - getting counts/details
  fetchAOIScreenlineCounts(config: AOIScreenlineCountsRequest): Promise<ScreenlineCountsResponse>;
  fetchAOIScreenlineDetails(config: AOIScreenlineDetailsRequest): Promise<ScreenlineDetailsResponse>;

  // Data quality
  fetchAreaAccuracyTableData(config: AreaAccuracyTableRequest): Promise<AreaAccuracyTableResponse>;
  fetchAreaAccuracyScatterPlotData(config: AreaAccuracyScatterPlotRequest): Promise<AreaAccuracyScatterPlotItem[]>;
}

export default function AnalyticsApi(restHandler: RestHandler) {
  return {
    async getDatasetCounts(datasetId: string, levels: string[], config: DatasetCountsArguments): Promise<Counts> {
      const allBodies = await Promise.all(
        levels.map((level) =>
          restHandler
            .postForBinary(`od/datasets/${datasetId}/counts`, {
              ...config,
              level: level,
            })
            .then((res) => {
              return parseCounts(res, level, "OdCountWithIsGate");
            }),
        ),
      );

      return mergeZoningLevelsCounts(allBodies);
    },

    async getDatasetCountsByZoneId(datasetId: string, config: DatasetCountsByZoneIdArguments): Promise<CountsByZoneId> {
      const counts = await restHandler.postForBinary(`od/datasets/${datasetId}/counts`, config).then((res) => {
        return parseCounts(res, config.selectedId, "OdCountWithIsGate");
      });

      return {
        counts,
        selectedZoneId: config.selectedId,
      };
    },

    async getDatasetIds(datasetId: string, levels: string[], config: ZoneIdsArguments): Promise<ZoneIds> {
      const allBodies = (await Promise.all(
        levels.map((level) =>
          restHandler.post(`od/datasets/${datasetId}/zone-ids/json`, {
            ...config,
            level: level,
          }),
        ),
      )) as ZoneIdAreaPairs[];

      return allBodies.reduce((zoneIds: ZoneIds, body: ZoneIdAreaPairs, i) => {
        zoneIds.set(levels[i], parseZoneIds(body));

        return zoneIds;
      }, new Map());
    },

    async getDatasetMetadata(datasetId: string): Promise<DatasetMetadata> {
      const body = await restHandler.get<DatasetMetadata>(`od/datasets/${datasetId}/metadata`);
      return body;
    },

    async getDatasetGates(datasetId: string): Promise<DatasetGate[]> {
      const body = await restHandler.get<DatasetGate[]>(`od/datasets/${datasetId}/gates`);
      return body;
    },

    async getDatasetZoneDetails(datasetId: string, config: DatasetZoneDetailsArguments): Promise<ZoneDetails> {
      const body = await restHandler.post(`od/datasets/${datasetId}/selection-details`, config);
      return body as ZoneDetails;
    },

    /*
     Get zone details for a single zone
     Return details for a single selected zone, to be shown in an information sidebar or popup
     */
    async getODZoneDetails(config: ZoneDetailsArguments): Promise<ZoneDetails> {
      const body = await restHandler.post(`od/zone-details`, config);
      return body as ZoneDetails;
    },

    /*
     Get incoming/outgoing counts for entitled zones in the area
     For all zones in the entitlement, get the outgoing or incoming trip counts for a specified filter configuration
     */
    async getODCounts(levels: string[], config: ODCountsArguments): Promise<Counts> {
      const allBodies = await Promise.all(
        levels.map((level) =>
          restHandler
            .postForBinary(`od/zone-counts`, {
              ...config,
              level: level,
            })
            .then((res) => {
              return parseCounts(res, level, "OdCount");
            }),
        ),
      );

      return mergeZoningLevelsCounts(allBodies);
    },

    async getODCountsByZoneId(config: ODCountsByZoneIdArguments): Promise<CountsByZoneId> {
      const counts = await restHandler.postForBinary(`od/zone-counts`, config).then((res) => {
        return parseCounts(res, config.selectedZoneId, "OdCount");
      });

      return {
        counts,
        selectedZoneId: config.selectedZoneId,
      };
    },

    async getODIds(levels: string[], config: ZoneIdsArguments): Promise<ZoneIds> {
      const allBodies = (await Promise.all(
        levels.map((level) =>
          restHandler.post(`od/zone-ids/json`, {
            ...config,
            level: level,
          }),
        ),
      )) as ZoneIdAreaPairs[];

      return allBodies.reduce((zoneIds: ZoneIds, body: ZoneIdAreaPairs, i) => {
        zoneIds.set(levels[i], parseZoneIds(body));

        return zoneIds;
      }, new Map());
    },

    /*
     Return metadata needed to create the filter UI and render the OD map content
    */
    async getODMetadata(config: ODMetadataArguments): Promise<ODMetadata> {
      const body = await restHandler.post(`od/metadata`, config);
      return body as ODMetadata;
    },

    /*
     While editing the sub-area geometry, the selected zones as well as the road segments that are candidates for gates (intersecting boundary, selected facility types) should be highlighted. This endpoint returns both the zone ids and the from-two segment ids of the road features
    */
    async getSubareaState(config: SubareaStateArguments): Promise<SubareaState> {
      const body = await restHandler.post(`od/config/subarea-state`, config);
      return body as SubareaState;
    },

    /*
     Return polygon for a given subarea
    */
    async getSubareaPolygon(config: SubareaPolygonArguments): Promise<SubareaPolygon> {
      const body: any = await restHandler.post(`/od/config/subarea-polygon`, config);

      return body as SubareaPolygon;
    },

    /*
     Return possible gates for a given subarea
    */
    async getGeneratedGates(config: GenerateGatesArguments): Promise<Gate[]> {
      const body: any = await restHandler.post(`/od/config/gates/generate`, config);
      return body.gates as Gate[];
    },

    /*
     Return new Gate and list of updated, unchanged and deleted gates
    */
    async getAddGate(config: AddGateArguments): Promise<AddGateResponse> {
      const body: any = await restHandler.post(`/od/config/gates/add`, config);
      return body as AddGateResponse;
    },

    /*
     Return Gate new centroid coordinates based on his segments
    */
    async getGateCoordinates(config: GateCoordinatesArguments): Promise<GateCoordinates> {
      const body: any = await restHandler.post(`/od/config/gates/getcoordinates`, config);
      return body as GateCoordinates;
    },

    /*
     Return metadata needed to create the filter UI and render the roads map content
    */
    async getRoadsMetadata(config: RoadsMetadataArguments): Promise<RoadsMetadataResponse> {
      const body = await restHandler.post(`roads/metadata`, config);
      return body as RoadsMetadataResponse;
    },

    /*
     Return volumes for road segments
    */
    async getRoadsVolumes(config: SegmentVolumesRequest): Promise<RoadsVolumes> {
      const response = config.datasetId
        ? getDatasetVolumesProtobuf(restHandler, "roads/segment-volumes", config, "SegmentVolume")
        : getVolumesProtobuf(restHandler, "roads/segment-volumes", config, "SegmentVolume");

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.ROADS_SEGMENT_VOLUMES, res?.volumes);

        return {
          measure: config.measure,
          maxVolume: res?.maxVolume,
          minVolume: res?.minVolume,
          size: res?.volumes.size,
        } as RoadsVolumes;
      });
    },

    /*
     Return road segment ids needed to filter the road segments by id
    */
    async getRoadSegmentIndexes(config: SegmentIndexesRequest): Promise<boolean> {
      return await restHandler.postForBinary(`roads/segment-indexes`, config).then((res) => {
        const segmentIndexesWithFactype: RoadSegmentIndexesWithFactype = new Map();
        const buffer = new Uint8Array(res);
        const reader = protobuf.Reader.create(buffer);

        return Promise.resolve(
          protobuf
            .load("/SegmentIndex.proto")
            .then((root: any) => {
              const segmentIndexes = root.lookupType("analytics.clickhouse.SegmentIndex");

              while (reader.pos < reader.len) {
                const msg = segmentIndexes.decodeDelimited(reader);
                segmentIndexesWithFactype.set(msg.segmentIdx, {
                  factype: msg.roadClass,
                });
              }

              const roadSegmentIndexesByRoadClass: RoadSegmentIndexesByRoadClass = {
                limitedAccess: [],
                other: [],
              };

              for (const [key, value] of segmentIndexesWithFactype) {
                roadSegmentIndexesByRoadClass[getRoadCategoryFromFactype(value.factype)].push(key);
              }

              memoryStore.setItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES, segmentIndexesWithFactype);
              memoryStore.setItem(
                MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES_BY_ROAD_CLASS,
                roadSegmentIndexesByRoadClass,
              );

              return true;
            })
            .catch((err: any) => {
              throw new Error(err);
            }),
        );
      });
    },

    // Get road segment statistics for the specified area and optional zoom level.
    async getRoadSegmentStats(config: SegmentStatsRequest): Promise<SegmentStatsResponse> {
      const body: any = await restHandler.post(`roads/segment-stats`, config);
      return body as SegmentStatsResponse;
    },

    // Get road segment details for a single segment
    async getSegmentVolumeDetails(config: SegmentVolumeDetailsRequest): Promise<SegmentVolumeDetail[]> {
      const body: any = await restHandler.post(`roads/segment-volume-details`, config);
      return body?.segments as SegmentVolumeDetail[];
    },

    // Get road segment feature details for a single segment
    async getSegmentsFeatureDetails(
      config: SegmentFeatureDetailsRequest,
    ): Promise<SegmentFeatureDetailsResponse> {
      const body: any = await restHandler.post(`roads/segment-feature-details`, config);
      return body as SegmentFeatureDetailsResponse;
    },

    // Get dataset folders structure
    async getDatasetFolders(): Promise<DatasetFolders> {
      const body: DatasetFoldersResponse = await restHandler.get(
        "catalog/folder-v2?recursive=true&include-permissions=true",
        undefined,
        true,
      );

      const folders: CustomDatasetRepository = {};

      for (const folder of body.folders) {
        folders[folder.folderId] = {
          folderName: folder.folderName,
          items: folder?.items || [],
          permissions: folder.permissions,
        };
      }

      return Promise.resolve({ folders, permissions: body.permissions });
    },

    // Create dataset folder
    async addDatasetFolder(folderName: string): Promise<DatasetFolder> {
      const body = (await restHandler.post(`catalog/folder`, {
        folderName,
      })) as DatasetFolder;

      return Promise.resolve(body);
    },

    // Rename dataset folder
    async renameDatasetFolder(folderId: UniqueIdentifier, folderName: string): Promise<DatasetFolder> {
      const body = (await restHandler.put(`catalog/folder/${folderId}`, {
        folderName,
      })) as DatasetFolder;

      return Promise.resolve(body);
    },

    // Delete dataset folder
    async deleteDatasetFolder(folderId: UniqueIdentifier): Promise<boolean> {
      const body = (await restHandler.delete(`catalog/folder/${folderId}`)) as DatasetFolder;

      if (body.folderId === folderId) {
        return Promise.resolve(true);
      }

      return Promise.resolve(false);
    },

    // Change dataset folder index
    async changeFolderIndex(folderId: UniqueIdentifier, index: number): Promise<CustomDatasetRepository> {
      const body = (await restHandler.put(`catalog/folder/${folderId}/index?recursive=true`, {
        index,
      })) as { updatedFolderList: DatasetFolder[] };

      const folders: CustomDatasetRepository = {};

      for (const folder of body.updatedFolderList) {
        folders[folder.folderId] = {
          folderName: folder.folderName,
          items: folder?.items || [],
          permissions: folder.permissions,
        };
      }

      return Promise.resolve(folders);
    },

    // Create dataset
    async addDatasetInFolder(config: CreateDatasetPayload): Promise<CustomDataset> {
      const body = (await restHandler.post(`catalog/dataset`, config)) as CustomDataset;

      return body;
    },

    // Rename dataset
    async renameDataset(datasetId: UniqueIdentifier, datasetName: string): Promise<CustomDataset> {
      const body = (await restHandler.put(`catalog/dataset/${datasetId}`, {
        datasetName,
      })) as CustomDataset;

      return body;
    },

    // Delete dataset
    async deleteDataset(datasetId: UniqueIdentifier): Promise<boolean> {
      const body = (await restHandler.delete(`catalog/dataset/${datasetId}`)) as CustomDataset;

      if (body.id === datasetId) {
        return Promise.resolve(true);
      }

      return Promise.resolve(false);
    },

    // Change dataset index
    async changeCatalogItemIndex(
      folderId: UniqueIdentifier,
      catalogItemId: UniqueIdentifier,
      index: number,
    ): Promise<CatalogItem[]> {
      const body = (await restHandler.put(`catalog/item/${catalogItemId}/index?recursive=true`, {
        folderId,
        index,
      })) as { updatedTargetFolderItemList: CatalogItem[] };

      return body.updatedTargetFolderItemList;
    },

    // Copy dataset
    async copyDataset(
      datasetId: UniqueIdentifier,
      datasetName: string,
      timePeriod: string,
      discardGates?: boolean,
    ): Promise<{ copiedDatasetId: string; items: CatalogItem[] }> {
      const body: any = await restHandler.post(`catalog/dataset/${datasetId}/copy`, {
        datasetName,
        timePeriod,
        discardGates,
      });

      return { copiedDatasetId: body.datasetId, items: body.items };
    },

    // Get OD dataset configuration
    async getODDatasetConfig(datasetId: UniqueIdentifier): Promise<ODDatasetConfig> {
      const body = (await restHandler.get(`od/config/${datasetId}`)) as ODDatasetConfig;

      return body;
    },

    // Update OD dataset configuration
    async updateODDatasetConfig(datasetId: string, config: UpdateODDatasetConfigPayload): Promise<ODDatasetConfig> {
      const body = (await restHandler.put(`od/config/${datasetId}`, config)) as ODDatasetConfig;

      return body;
    },

    // Save current editor settings for the dataset as a new OD dataset configuration
    async saveAsODDatasetConfig(config: OdDatasetConfigSaveAsRequest): Promise<ODDatasetConfig> {
      const body = (await restHandler.post("od/config/save-as", config)) as ODDatasetConfig;

      return body;
    },

    // Validate the persisted configuration
    async validateODDatasetConfig(datasetConfigId: string): Promise<ODDatasetValidation> {
      const body: any = await restHandler.post(`od/config/${datasetConfigId}/validation`, {});

      return body as ODDatasetValidation;
    },

    // Compute OD dataset
    async computeODDataset(datasetConfigId: string, notifyByEmail: boolean): Promise<ODDatasetComputation> {
      const body: any = await restHandler.post(`od/config/${datasetConfigId}/computation`, {
        disableEmailNotifications: !notifyByEmail,
      });

      return body as ODDatasetComputation;
    },

    // Cancel OD dataset computation
    async cancelODDatasetComputation(datasetConfigId: string): Promise<ODDatasetConfig> {
      const body: any = await restHandler.delete(`od/config/${datasetConfigId}/computation`);

      return body as ODDatasetConfig;
    },

    // Get measure range for roads
    async getRoadsMeasureRange(request: RoadMeasureRangeRequest): Promise<MeasureRange> {
      const body: any = await restHandler.post("/roads/measure-range", request);

      return body.range;
    },

    // Get measure range for OD
    async getODMeasureRange(levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange> {
      const allBodies: any = await Promise.all(
        levels.map((level) => restHandler.post("/od/measure-range", { ...request, level })),
      );

      return levels.reduce((obj: { [key: string]: any }, level, i: number) => {
        obj[level] = allBodies[i].range;
        return obj;
      }, {});
    },

    // Get measure range for dataset
    async getDatasetMeasureRange(
      datasetId: string,
      levels: string[],
      request: MeasureRangeRequest,
    ): Promise<ODMeasureRange> {
      const allBodies: any = await Promise.all(
        levels.map((level) => restHandler.post(`/od/datasets/${datasetId}/measure-range`, { ...request, level })),
      );

      return levels.reduce((obj: { [key: string]: any }, level, i: number) => {
        obj[level] = allBodies[i].range;
        return obj;
      }, {});
    },

    // ----- EXPORT -------

    // Get a list of export jobs for the logged in user
    async getExportJobs(): Promise<ExportJobs> {
      const response = await restHandler.post("export/list-jobs", {}, undefined, true);
      return response as ExportJobs;
    },

    // Add new dataset export job to the queue
    async addDatasetExportJob(datasetId: string, request: ODDatasetExportRequest): Promise<string> {
      const response = await restHandler.post(`export/dataset/${datasetId}`, request);
      return response as string;
    },

    // Add new aoi export job to the queue
    async addAoiExportJob(request: AoiExportRequest): Promise<string> {
      const response = await restHandler.post("export/aoi", request);
      return response as string;
    },

    // Trigger recreation of export job for the given uuid
    async recreateExportJob(uuid: string): Promise<string> {
      const response = await restHandler.post(`export/recreate/${uuid}`, {});
      return response as string;
    },

    // Get number of zones for the area of interest within entitled area of the user
    async getNumZones(request: OdNumZonesRequest): Promise<NumZonesResponse> {
      const response = await restHandler.post("od/num-zones", request);
      return response as NumZonesResponse;
    },

    async getGeocoding(request: { searchText: string; token: string; proximity: string }): Promise<any> {
      const { searchText, token, proximity } = request;
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${token}&autocomplete=true&country=US&language=en&types=region,place,postcode,locality,neighborhood,address&proximity=${proximity}&limit=8`,
      );

      return response.json();
    },

    async getFocusAreasAndDatasets(): Promise<FocusAreaItem[]> {
      const urls = [
        "dashboard/licensed-area-item?includeGeometry=true&includeAreaUnits=true",
        "dashboard/dataset-item?includeGeometry=true&includeAreaUnits=true",
      ];
      const focusAreaItems = await Promise.all(urls.map(async (url) => await restHandler.get(url)));

      return mergeLicensedAreaAndDatasetItems(focusAreaItems) as FocusAreaItem[];
    },

    async getFocusAreas(customZoningId?: string | null, forDatasetCreation?: boolean): Promise<FocusAreaItem[]> {
      const focusAreaItems: any = await restHandler.get(
        `dashboard/licensed-area-item?includeAreaUnits=true` +
          (customZoningId ? `&customZoningId=${customZoningId}` : ``) +
          (forDatasetCreation ? `&forDatasetCreation=true` : ``),
      );
      return mergeLicensedAreaAndDatasetItems([focusAreaItems]) as FocusAreaItem[];
    },

    async uploadZoningShapefiles(shapefiles: Blob, formats: ShapesInputFormat): Promise<UploadZoningResponse> {
      const response = await restHandler.post(`zoning/staged?format=${formats}`, shapefiles, {
        headers: { "content-type": "application/octet-stream" },
      });

      return response as UploadZoningResponse;
    },

    async prepareZoning(config: PreparedZoningConfigRequest): Promise<UploadZoningResponse> {
      const response = await restHandler.post(`zoning/prepared`, config);

      return response as UploadZoningResponse;
    },

    async createZoning(zoningId: string, folderId: string, name: string, description: string): Promise<any> {
      const response = await restHandler.post(`/catalog/zoning`, {
        preparedZoningId: zoningId,
        zoningName: name,
        folderId,
        description,
      });

      return response;
    },

    async deleteZoning(zoningId: string): Promise<boolean> {
      const body: any = await restHandler.delete(`zoning/${zoningId}`);

      return body?.zoningId === zoningId;
    },

    async deleteCustomZoning(zoningId: string): Promise<boolean> {
      const body: any = await restHandler.delete(`catalog/zoning/${zoningId}`);

      return body?.zoningId === zoningId;
    },

    async editZoning(zoningItemId: string, name: string, description: string): Promise<ZoningItem> {
      const body: any = await restHandler.put(`catalog/zoning/${zoningItemId}`, {
        zoningName: name,
        description,
      });

      return body as ZoningItem;
    },

    async getZoning(zoningId: string): Promise<Zoning> {
      const body: any = await restHandler.get(`zoning/${zoningId}`);

      return body as Zoning;
    },

    async getCustomZoningSelectorList(): Promise<CustomZoningSelectorItemsResponse> {
      const body: CustomZoningSelectorItemsResponse = await restHandler.get(`od/config/custom-zoning`);

      return body;
    },

    // Return segment counts for select link analysis
    async getSelectLinkSegmentCounts(config: SelectLinkSegmentCountsRequest): Promise<RoadsVolumes> {
      const response = getVolumesProtobuf(
        restHandler,
        "select-link/segment-volumes",
        config,
        "SelectLinkSegmentVolume",
      );

      return response.then((res) => {
        memoryStore.setItem(MemoryStoreKeys.SELECT_LINK_SEGMENT_VOLUMES, res?.volumes);

        return {
          measure: config.measure,
          maxVolume: res?.maxVolume,
          minVolume: res?.minVolume,
          size: res?.volumes.size,
        } as RoadsVolumes;
      });
    },

    async listSelectLinkConfigs(): Promise<SelectLinkConfig[]> {
      const response = await restHandler.get("select-link/config", {});
      return response as SelectLinkConfig[];
    },

    async createSelectLinkConfig(request: SelectLinkConfigCreationRequest): Promise<SelectLinkConfig> {
      const response = await restHandler.post("select-link/config", request);
      return response as SelectLinkConfig;
    },

    async fetchSelectLinkConfig(configId: string): Promise<SelectLinkConfig> {
      const response = await restHandler.get(`select-link/config/${configId}`);
      return response as SelectLinkConfig;
    },

    async updateSelectLinkConfig(configId: string, request: SelectLinkConfigUpdateRequest): Promise<SelectLinkConfig> {
      const response = await restHandler.put(`select-link/config/${configId}`, request);
      return response as SelectLinkConfig;
    },

    async deleteSelectLinkConfig(configId: string): Promise<SelectLinkConfig> {
      const response = await restHandler.delete(`select-link/config/${configId}`);
      return response as SelectLinkConfig;
    },

    async getSelectLinkMetadata(config: RoadsMetadataArguments): Promise<SelectLinkMetadataResponse> {
      const body = await restHandler.post(`select-link/metadata`, config);
      return body as SelectLinkMetadataResponse;
    },

    // Corridor Discovery
    async fetchCorridorMetadata(config: any): Promise<CorridorMetadata> {
      const body = await restHandler.post(`corridor/metadata`, config);
      return body as CorridorMetadata;
    },

    async fetchCorridorEdgeIds(config: any): Promise<number[]> {
      const counts = await restHandler.postForBinary(`corridor/edge-ids`, config).then((res) => {
        return parseNumbers(res, "CorridorEdgeIds", "edgeId");
      });

      return counts as number[];
    },

    async fetchCorridorEdgeCounts(config: any): Promise<CorridorEdgeCounts> {
      const response = getVolumesProtobuf(restHandler, "corridor/edge-counts", config, "CorridorEdgeCount", "edgeId");

      return response.then((res) => ({
        volumes: res?.volumes,
        maxVolume: res?.maxVolume,
        minVolume: res?.minVolume,
      }));
    },

    async fetchCorridorEdgeAvailableRange(config: EdgesRangeRequest): Promise<MeasureRangeResponse> {
      const body: any = await restHandler.post(`corridor/edge-counts-range`, config);

      return body;
    },

    async fetchCorridorNodeIds(config: any): Promise<number[]> {
      const counts = await restHandler.postForBinary(`corridor/node-ids`, config).then((res) => {
        return parseNumbers(res, "CorridorNodeIds", "nodeId");
      });

      return counts as number[];
    },

    async fetchCorridorNodeCounts(config: any): Promise<CorridorNodeCounts> {
      const response = getVolumesProtobuf(restHandler, "corridor/node-counts", config, "CorridorNodeCount", "nodeId");

      return response.then((res) => ({
        volumes: res?.volumes,
        maxVolume: res?.maxVolume,
      }));
    },

    async fetchCorridorHeatmapConfiguration(
      config: CorridorHeatmapConfigurationRequestParams,
    ): Promise<CorridorHeatmapConfiguration> {
      const body = await restHandler.post(`corridor/heatmap-configuration`, config);

      return body as CorridorHeatmapConfiguration;
    },

    async fetchServiceOverlayLayers(): Promise<ServiceOverlay[]> {
      const body: any = await restHandler.get("overlays/service-layer");
      return body?.featureServiceLayers as ServiceOverlay[];
    },

    async fetchCorridorEdgeDetails(config: any): Promise<any> {
      const body = await restHandler.post(`corridor/edge-details`, config);
      return body as any;
    },

    // Road VMT Analysis
    async fetchRoadVmtMetadata(config: RoadVmtMetadataRequest): Promise<RoadVmtMetadata> {
      const body = await restHandler.post(`road-vmt/metadata`, config);
      return body as RoadVmtMetadata;
    },

    async fetchRoadVmtZoneCounts(level: string, config: RoadVmtZoneCountsRequest): Promise<RoadVmtZoneCounts> {
      const { zones, availableRange } = await restHandler.postForBinary(`road-vmt/zone-counts`, config).then((res) => {
        return parseCounts(res, level, "RoadVmtZoneCount");
      });

      return {
        zones,
        availableRange,
      } as RoadVmtZoneCounts;
    },
    async fetchRoadVmtZoneDetails(config: RoadVmtZoneDetailsRequest): Promise<RoadVmtZoneDetails> {
      const body = await restHandler.post(`road-vmt/zone-details`, config);
      return body as RoadVmtZoneDetails;
    },

    // Configuration documents

    /** Creates a configuration document */
    async createConfigDocument(config: ConfigDocumentCreationRequest): Promise<ConfigDocument> {
      const body = await restHandler.post(`catalog/config-document`, config);
      return body as ConfigDocument;
    },

    /**
     * Searches for configuration documents of a specific type, and returns the result as a list of folders with
     * matching configuration documents.
     *
     * @param type The type of configuration document to search for
     * @param filterByLicensedArea If true then configuration documents that were stored with a bounding box will only be returned
     *     if the bounding box intersects with the bounding box of the licensed area. Configuration documents without a stored
     *     bounding box are always returned.
     * @param excludeEmptyFolders Indicates whether to exclude folders that do not contain any matching configuration documents.
     *     Typically: true if the list is used to opening an existing document, false for saving a new configuration document.
     * @param folderId Optional folder ID to limit the search to
     * @param timePeriod Optional time period to search configuration documents for. If a configuration document is not stored
     *     for a specific time period, it will be always be returned.
     * @returns List of folders with matching configuration documents
     */
    async searchConfigDocuments(
      type: ConfigDocumentType,
      filterByLicensedArea: boolean = false,
      excludeEmptyFolders: boolean = false,
      folderId: string | null = null,
      timePeriod: string | null = null,
    ): Promise<SearchConfigDocumentsResponse> {
      const body = await restHandler.get(
        `catalog/config-document?type=${type}&filterByLicensedArea=${filterByLicensedArea}&excludeEmptyFolders=${excludeEmptyFolders}` +
          (folderId ? `&folderId=${folderId}` : ``) +
          (timePeriod ? `&timePeriod=${timePeriod}` : ``),
      );
      return body as SearchConfigDocumentsResponse;
    },

    async getConfigDocument(configDocumentId: string): Promise<ConfigDocument> {
      const body = await restHandler.get(`catalog/config-document/${configDocumentId}`);
      return body as ConfigDocument;
    },

    /** Gets the payload (actual configuration) stored in a given document */
    async getConfigDocumentPayload(configDocumentId: string): Promise<GetConfigDocumentPayloadResponse> {
      const body = await restHandler.get(`catalog/config-document/${configDocumentId}/payload`);
      return body as GetConfigDocumentPayloadResponse;
    },

    /** Updates name/description of a document */
    async updateConfigDocument(configDocumentId: string, config: ConfigDocumentUpdateRequest): Promise<ConfigDocument> {
      const body = await restHandler.put(`catalog/config-document/${configDocumentId}`, config);
      return body as ConfigDocument;
    },

    /** Updates the payload (actual configuration) in a document */
    async updateConfigDocumentPayload(
      configDocumentId: string,
      config: ConfigDocumentPayloadUpdateRequest,
    ): Promise<ConfigDocument> {
      const body = await restHandler.put(`catalog/config-document/${configDocumentId}/payload`, config);
      return body as ConfigDocument;
    },

    async deleteConfigDocument(configDocumentId: string): Promise<ConfigDocument> {
      const body = await restHandler.delete(`catalog/config-document/${configDocumentId}`);
      return body as ConfigDocument;
    },

    // Screenlines

    /**
     * Get candidate intersection points/segment geometries for a screenline geometry that is being created
     *
     * @note If this request fails due to an invalid geometry, the editor should show the error message and keep the invalid geometry,
     * so that it can be further modified to fix the reason for the error (e.g. move a vertex to remove a self-intersection)
     */
    async candidateScreenlineIntersections(
      config: CandidateScreenlineIntersectionsRequest,
    ): Promise<CandidateScreenlineIntersectionsResponse> {
      const body = await restHandler.post(`screenlines/candidate-intersections`, config);
      return body as CandidateScreenlineIntersectionsResponse;
    },

    // create a new screenline
    async createScreenline(config: CreateScreenlineRequest): Promise<CreateScreenlineResponse> {
      const body = await restHandler.post(`screenlines/create`, config);
      return body as CreateScreenlineResponse;
    },

    // validate a screenline
    async validateScreenline(config: ScreenlineValidationRequest): Promise<ScreenlineValidationResponse> {
      const body = await restHandler.post(`screenlines/validation`, config);
      return body as ScreenlineValidationResponse;
    },

    // validate a list of screenlines to get summary results
    async validateScreenlines(config: ScreenlineValidationSummaryRequest): Promise<ScreenlineValidationSummaryResponse> {
      const body = await restHandler.post(`screenlines/validation-summary`, config);
      return body as ScreenlineValidationSummaryResponse;
    },

    // applies an updated geometry to a screenline, returning the updated screenline
    async updateScreenlineGeometry(config: UpdateScreenlineGeometryRequest): Promise<UpdateScreenlineGeometryResponse> {
      const body = await restHandler.post(`screenlines/updated-geometry`, config);
      return body as UpdateScreenlineGeometryResponse;
    },

    /** Upload zipped shapefile with screenline features and return validated/prepared features, field candidates/default
     *  fields for id, name and description, and validation messages */
    async readScreenlineShapefile(zippedShapefile: Blob): Promise<ReadScreenlineShapefileResponse> {
      const body = await restHandler.post(`screenlines/import/shapefile`, zippedShapefile, {
        headers: { "content-type": "application/octet-stream" },
      });
      return body as ReadScreenlineShapefileResponse;
    },

    /** Convert validated/prepared screenline features to screenlines */
    async convertFeaturesToScreenlines(
      config: ConvertFeaturesToScreenlinesRequest,
    ): Promise<ConvertFeaturesToScreenlinesResponse> {
      const body = await restHandler.post(`screenlines/import/convert`, config);
      return body as ConvertFeaturesToScreenlinesResponse;
    },

    async fetchAOIScreenlineCounts(config: AOIScreenlineCountsRequest): Promise<ScreenlineCountsResponse> {
      const body = await restHandler.post(`screenlines/aoi/screenline-counts`, config);
      return body as ScreenlineCountsResponse;
    },

    async fetchAOIScreenlineDetails(config: AOIScreenlineDetailsRequest): Promise<ScreenlineDetailsResponse> {
      const body = await restHandler.post(`screenlines/aoi/screenline-details`, config);
      return body as ScreenlineDetailsResponse;
    },

    // Get road segment indexes for a list of segment ids
    async getSegmentIndexesForIds(config: SegmentIndexesForIdsRequest): Promise<Map<string, number>> {
      const body = await restHandler.postForBinary("roads/segment-indexes-for-ids", config).then((res) => {
        return parseSegmentIndexes(res);
      });
      return body as Map<string, number>;
    },

    // Data quality
    async fetchAreaAccuracyTableData(config: AreaAccuracyTableRequest): Promise<AreaAccuracyTableResponse> {
      const body = await restHandler.post(`data-quality/area-accuracy/by-aadt-group`, config);
      return body as AreaAccuracyTableResponse;
    },

    async fetchAreaAccuracyScatterPlotData(
      config: AreaAccuracyScatterPlotRequest,
    ): Promise<AreaAccuracyScatterPlotItem[]> {
      return await restHandler.postForBinary(`data-quality/area-accuracy/aadt-vs-ref-counts`, config).then((res) => {
        const aadtVsRefCounts: AreaAccuracyScatterPlotItem[] = [];
        const buffer = new Uint8Array(res);
        const reader = protobuf.Reader.create(buffer);

        return Promise.resolve(
          protobuf
            .load("/AadtVsRefCount.proto")
            .then((root: any) => {
              const AadtVsRefCount = root.lookupType("analytics.clickhouse.AadtVsRefCount");

              while (reader.pos < reader.len) {
                const msg = AadtVsRefCount.decodeDelimited(reader);
                aadtVsRefCounts.push({
                  aadt: msg.aadt,
                  refCount: msg.refCount,
                  roadClassGroup: msg.roadClassGroup,
                });
              }

              return aadtVsRefCounts;
            })
            .catch((err: any) => {
              throw new Error(err);
            }),
        );
      });
    },
  };
}
