import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters } from "features/filters/utils";
import { getAvailableZoomLevels } from "features/map/utils";

import { roadVmtActions } from "store/sections/roadVmt";

import { FiltersType, FocusAreaItem, MeasureType, ODTileLayer, SelectedZone } from "types";

import { ROAD_VMT_LAYER_PREFIX, getVmtFillOpacityExpression } from "./map-data/layers";

export const useFetchRoadVmtMetadata = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null) => {
      if (timePeriod) {
        dispatch(roadVmtActions.fetchRoadVmtMetadata({ timePeriod }));
      }
    },
    [dispatch],
  );
};

export const useFetchZoneIds = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null, layers: ODTileLayer[], selectedFocusArea: FocusAreaItem) => {
      if (timePeriod) {
        dispatch(
          roadVmtActions.fetchZoneIds(getAvailableZoomLevels(layers), {
            timePeriod,
            areaOfInterest: selectedFocusArea?.areas || null,
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchRoadVmtZoneCounts = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null, level: string, filters: FiltersType) => {
      if (timePeriod) {
        dispatch(
          roadVmtActions.fetchRoadVmtZoneCounts(level, {
            timePeriod,
            measure: MeasureType.AADT_VMT,
            filter: buildFilters(filters),
            areaOfInterest: null,
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchRoadMileageZoneCounts = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null, level: string, filters: FiltersType) => {
      if (timePeriod) {
        dispatch(
          roadVmtActions.fetchRoadMileageZoneCounts(level, {
            timePeriod,
            measure: MeasureType.MILEAGE,
            filter: buildFilters(filters),
            areaOfInterest: null,
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchRoadVmtZoneDetails = () => {
  const dispatch = useDispatch();

  return useCallback(
    (zoneId: string, timePeriod: string | null, filters: FiltersType, dimensions: string[]) => {
      if (timePeriod) {
        const breakdowns = dimensions.map((d: string) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));

        dispatch(
          roadVmtActions.fetchRoadVmtZoneDetails({
            zoneId,
            timePeriod,
            measure: MeasureType.AADT_VMT,
            summary: {
              breakdowns,
              filteredTotal: true,
              unfilteredTotal: false,
            },
            filter: buildFilters(filters),
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchMileageZoneDetails = () => {
  const dispatch = useDispatch();

  return useCallback(
    (zoneId: string, timePeriod: string | null, filters: FiltersType, dimensions: string[]) => {
      if (timePeriod) {
        const breakdowns = dimensions.map((d: string) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));

        dispatch(
          roadVmtActions.fetchMileageZoneDetails({
            zoneId,
            timePeriod,
            measure: MeasureType.MILEAGE,
            summary: {
              breakdowns,
              filteredTotal: true,
              unfilteredTotal: false,
            },
            filter: buildFilters(filters),
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useSetSelectedZone = () => {
  const dispatch = useDispatch();

  return useCallback(
    (selectedZone: SelectedZone | null) => {
      dispatch(roadVmtActions.setSelectedZone(selectedZone));
    },
    [dispatch],
  );
};

export const useChangeFillOpacity = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapboxLayerManager: LayerManager | null,
) => {
  return useCallback(
    (opacityFactor: number, availableLayers: ODTileLayer[]) => {
      availableLayers.forEach((layer) => {
        if (map.current!.getLayer(`${ROAD_VMT_LAYER_PREFIX}_${layer.name}`)) {
          mapboxLayerManager?.updateLayerPaint(
            `${ROAD_VMT_LAYER_PREFIX}_${layer.name}`,
            "fill-opacity",
            getVmtFillOpacityExpression(opacityFactor),
          );
        }
      });
    },
    [map, mapboxLayerManager],
  );
};
