import { Layer } from "mapbox-gl";

import { GATES_SOURCE_ID } from "./sources";

export const GATES_LAYER_ID = "GATES_LAYER_ID";

export const getCircleColorExpression = (range: [number, number] = [0, Infinity]) => [
  "case",
  [
    "!",
    ["all", [">=", ["coalesce", ["get", "count"], 0], range[0]], ["<=", ["coalesce", ["get", "count"], 0], range[1]]],
  ],
  "#bdbdbd",
  ["case", ["boolean", ["feature-state", "hover"], false], "#1E40AF", "#139EEC"],
];

export const getGatesLayers = () =>
  [
    {
      id: GATES_LAYER_ID,
      type: "circle",
      source: GATES_SOURCE_ID,
      paint: {
        "circle-color": getCircleColorExpression(),
        "circle-radius": 8,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    },
  ] as Layer[];
