import { Switch, SwitchProps } from "components_new";
import * as React from "react";

export interface EditorToggleControlProps extends SwitchProps {
  open: boolean;
}

export const EditorToggleControl: React.FC<EditorToggleControlProps> = ({ open, ...props }) => (
  <Switch
    sx={(theme) => ({
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
        marginLeft: theme.spacing(0.5),
      },
      "& .MuiSwitch-root": {
        marginLeft: theme.spacing(0.5),
      },
    })}
    label="Editor"
    checked={open}
    labelPlacement="start"
    {...props}
  />
);
